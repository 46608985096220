import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { Modal } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { currency } from "../../../../dynamicController";
import { saveTransactionType } from "../../../../redux/actions/paymentActions";

import ticket from "../../../../assets/images/icons/icn-voucher.png";
import creditBear from "../../../../assets/images/icons/icn-creditGrey.png";
import cleanproCreditIcon from "../../../../assets/images/icons/icn-credit.png";
import insufficientToken from "../../../../assets/images/icons/icn-creditInsufficient.png";

const mySwal = withReactContent(Swal);
const STORETYPE = process.env.REACT_APP_STORETYPE;

const Payment = ({
    setPage,
    cart,
    setCart,
    machine,
    notificationType,
    setNotificationType,
    email,
    setEmail,
    number,
    setNumber,
    user,
    voucherId,
    setVoucherId,
    discountCode,
    setDiscountCode,
    discountAmount,
    setDiscountAmount,
    theme,
    point,
    setPoint,
    token,
    saveTransactionType,
}) => {
    const [list, setList] = useState([]);
    const [discountCodeModal, setDiscountCodeModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectVoucherId, setSelectVoucherId] = useState(null);
    const [discountCodeInput, setDiscountCodeInput] = useState("");
    const [isOpen, setOpen] = useState(false);
    const [razerBody, setRazerBody] = useState(null);
    const [ghlBody, setGhlBody] = useState(null);
    const [paymentMethodModal, setPaymentMethodModal] = useState(false)
    const [voucherReminderModal, setVoucherReminderModal] = useState(false)
    const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false)
    const [paymentConfirmationText, setPaymentConfirmationText] = useState("")
    const [insufficientTokenModal, setInsufficientTokenModal] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState("")

    const razerButtonClick = useRef();
    const ghlButtonClick = useRef();

    const history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        let itemList = []
        for (let i = 0; i < cart.length; i++) {
            let pack = itemList.filter(item => item.sequence === cart[i].sequence)[0]
            if (pack) {
                if (cart[i].name === "Wash") {
                    pack.wash = true
                    pack.washCapacity = cart[i].capacity
                    pack.washMode = cart[i].mode
                    pack.washPrice = cart[i].price
                } else if (cart[i].name === "Dry") {
                    pack.dry = true
                    pack.dryCapacity = cart[i].capacity
                    pack.dryDuration = cart[i].machine_duration
                    pack.dryPrice = cart[i].price
                } else if (cart[i].name === "Fold") {
                    pack.fold = true
                    pack.foldPrice = cart[i].price
                }
            } else {
                let tempObject = {
                    sequence: cart[i].sequence,
                    size: cart[i].size
                }
                if (cart[i].name === "Wash") {
                    tempObject.wash = true
                    tempObject.washCapacity = cart[i].capacity
                    tempObject.washMode = cart[i].mode
                    tempObject.washPrice = cart[i].price
                } else if (cart[i].name === "Dry") {
                    tempObject.dry = true
                    tempObject.dryCapacity = cart[i].capacity
                    tempObject.dryDuration = cart[i].machine_duration
                    tempObject.dryPrice = cart[i].price
                } else if (cart[i].name === "Fold") {
                    tempObject.fold = true
                    tempObject.foldPrice = cart[i].price
                }
                itemList.push(tempObject)
            }
        }
        setList(itemList)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart])

    useEffect(() => {
        if (razerBody) {
            razerButtonClick.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [razerBody]);

    useEffect(() => {
        if (ghlBody) {
            ghlButtonClick.current.click();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ghlBody]);

    const totalAmount = (type) => {
        let total = cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0)
        let payAmount = total
        let pointAmount = 0

        // promotion / voucher / discount code
        if (discountAmount) {
            if (discountAmount.type === "Flat") {
                payAmount -= discountAmount.amount
            } else {
                const rate = (payAmount * discountAmount.amount) / 100
                payAmount = parseFloat(payAmount - rate).toFixed(2)
            }
        }

        if (payAmount <= 0) {
            payAmount = 0
        }

        // point
        if (point) {
            if (parseFloat(user.point) >= (parseFloat(payAmount) * parseFloat(user.point_currency))) {
                // if user have sufficient point to pay (all amount pay using point)
                pointAmount = parseFloat(payAmount) * parseFloat(user.point_currency)
                payAmount = 0
            } else {
                // use up all the current point and user still need to pay for the remaining amount
                pointAmount = parseFloat(user.point)
                payAmount = parseFloat(payAmount) - (parseFloat(user.point) / parseFloat(user.point_currency))
            }
        }

        if (type === "amount") {
            return /\./g.test(total.toString()) ? parseFloat(total).toFixed(2) : total
        } else if (type === "paymentAmount") {

            if (notificationType === "SMS" && !(user && machine.member_free_sms)) {
                payAmount = parseFloat(payAmount) + parseFloat(machine.sms_notification_amount)
            }
            if (payAmount <= 0) {
                return 0
            } else {
                return /\./g.test(payAmount.toString()) ? parseFloat(payAmount).toFixed(2) : payAmount
            }
        } else if (type === "paymentAmountWithoutSMS") {

            if (payAmount <= 0) {
                return 0
            } else {
                return /\./g.test(payAmount.toString()) ? parseFloat(payAmount).toFixed(2) : payAmount
            }
        } else if (type === "pointAmount") {
            // point amount use for discount
            return pointAmount
        } else if (type === "pointValue") {
            // point amount use for discount in RM
            return /\./g.test((parseFloat(pointAmount) / parseFloat(user.point_currency)).toString()) ? (parseFloat(pointAmount) / parseFloat(user.point_currency)).toFixed(2) : (parseFloat(pointAmount) / parseFloat(user.point_currency))
        } else if (type === "sms") {
            if (user && machine.member_free_sms) {
                return 0;
            } else {
                return /\./g.test(machine.sms_notification_amount.toString()) ?
                    parseFloat(machine.sms_notification_amount).toFixed(2) :
                    machine.sms_notification_amount;
            }
        }
    }

    const discountText = () => {
        if (discountAmount) {
            if (discountAmount.type === "Flat") {
                if (STORETYPE === "cleanpro" && user && user.tokenSetting) {
                    return `${discountAmount.amount} ${t("credit")}`
                } else {
                    return `${currency(machine.country)} ${discountAmount.amount}`
                }
            } else {
                return `${discountAmount.amount} %`
            }
        }
    }

    const removeDiscount = () => {
        setVoucherId(null)
        setDiscountCode("")
        setDiscountCodeInput("");
        setDiscountAmount(null)
        setOpen(false)
        setDiscountCodeModal(false)
    }

    const checkDiscountCode = (e) => {
        e.preventDefault();

        setLoading(true);
        axios
            .post("/api/user/checkDiscountCode", {
                discountCode: discountCodeInput,
                outletId: machine.outletId,
                machineType: "Package",
                userId: user.id,
            })
            .then((res) => {
                setDiscountCode(discountCodeInput);
                setDiscountAmount({
                    type: res.data.discountType,
                    amount: res.data.discountAmount
                })
                setVoucherId(null)
                setDiscountCodeModal(false);
                setLoading(false);
            })
            .catch((err) => {
                setDiscountCodeInput("");
                setLoading(false);
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    };

    const deleteItem = (sequence) => {
        mySwal.fire({
            title: t("Confirmation"),
            text: t("Are you sure to delete the item?"),
            icon: "question",
            showCancelButton: true,
            cancelButtonText: t("Cancel"),
            confirmButtonText: t("Delete"),
            reverseButtons: STORETYPE === "cleanpro" ? true : false,
            confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
            customClass: {
                confirmButton: "cleanpro-swal-btn",
                cancelButton: "cleanpro-swal-secondary-btn"
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let deletedCart = cart.filter(c => c.sequence !== sequence)
                    setCart(deletedCart)
                }
            })
    }

    const pay = () => {
        if (cart.length <= 0) {
            mySwal.fire(t("No Item"), t("Please add item"), "info");
        } else {
            let voucherReminder = false;

            if (user) {
                if (user.vouchers && user.vouchers.length) {
                    let userVoucher = user.vouchers.filter((voucher) => voucher.available && voucher.package_use);
                    if (userVoucher.length !== 0 && !discountAmount) {
                        voucherReminder = true;
                    }
                }
            }

            const emailRegex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
            const numberRegex = /^\d+$/;

            if (notificationType === "Email" && !email) {
                mySwal.fire("", t("Please fill in your email address"), "info")
            } else if (notificationType === "Email" && !emailRegex.test(email)) {
                mySwal.fire(t("Error"), t("Invalid Email Address"), "error");
            } else if (notificationType === "SMS" && !number) {
                mySwal.fire("", t("Please fill in your phone number"), "info")
            } else if (notificationType === "SMS" && machine.country === "Taiwan" && (!numberRegex.test(number) || number.length !== 10 || number.substring(0, 2) !== "09")) {
                mySwal.fire(t("Error"), t("Invalid Phone Number"), "error");
            } else if (notificationType === "SMS" && machine.country === "Malaysia" && (!numberRegex.test(number) || number.length <= 9 || number.length >= 12 || number.substring(0, 2) !== "01")) {
                mySwal.fire(t("Error"), t("Invalid Phone Number"), "error");
            } else {
                if (voucherReminder) {
                    setVoucherReminderModal(true)
                } else {
                    setPaymentMethodModal(true)
                }
            }
        }
    }

    const handlePaymentMethod = (method) => {
        if (method === "eToken" && (parseFloat(user.token) < parseFloat(totalAmount("paymentAmount")))) {
            setInsufficientTokenModal(true)
            setPaymentMethodModal(false)
        } else {
            setPaymentMethod(method)
            if (method === "eToken") {
                setPaymentConfirmationText(<p className="text-center">{t("You have")} <strong>{user.token}</strong> {STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />{t("Pay")} <strong>{totalAmount("paymentAmount")}</strong> {STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} {("to")} <strong>{machine.outlet_name}</strong>?</p>)
            } else if (method === "duitnow") {
                setPaymentConfirmationText(<p className="text-center"><Trans i18nKey="The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?">The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency: currency(machine.country) }}{{ totalPaymentAmount: totalAmount("paymentAmount") }} to {{ machineName: machine.outlet_name }}?</Trans></p>)
            } else {
                if (voucherId && discountAmount && discountAmount.type === "Flat" && (discountAmount.amount > cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0))) {
                    setPaymentConfirmationText(<p className="text-center">{t("You may under utilized the voucher.")} {t("Proceed to pay")} {currency(machine.country)} {totalAmount("paymentAmount")} {t("to")} {machine.outlet_name}?</p>)
                } else {
                    setPaymentConfirmationText(<p className="text-center">{t("Pay")} {currency(machine.country)} {totalAmount("paymentAmount")} {t("to")} {machine.outlet_name}?</p>)
                }
            }
            setPaymentMethodModal(false)
            setPaymentConfirmationModal(true)
        }
    }

    const confirmPayment = () => {
        let sendThis = {
            amount: cart.map(c => parseFloat(c.price)).reduce((a, b) => a + b, 0),
            paymentAmount: totalAmount("paymentAmountWithoutSMS"),
            outletId: machine.outletId,
            transactionItem: cart,
            notificationType,
            email,
            sms_amount: totalAmount("sms"),
            number,
        };

        let endPoint = "/api/nonuser/dng/payment";

        let headers = {
            "Content-Type": "application/json"
        }

        if (user) {
            sendThis.voucherId = voucherId;
            sendThis.memberId = user.memberId;
            sendThis.discountCode = discountCode;
            sendThis.point_amount = totalAmount("pointAmount");
            sendThis.point_currency = user.point_currency;
            endPoint = "/api/user/dng/payment";
            headers = {
                "Content-Type": "application/json",
                "auth-token": token
            }
        }

        if (paymentMethod === "eToken") {
            sendThis.token = true
        } else if (paymentMethod === "duitnow") {
            if (machine.duitnow) {
                sendThis.duitnow = true
            } else if (machine.rhbduitnow) {
                sendThis.rhbduitnow = true
            }
        } else if (paymentMethod === "promptpay") {
            sendThis.bangkokbank = true
        }

        if (paymentMethod === "eWallet" && machine.outletType === "pagbank" && parseFloat(totalAmount("paymentAmount")) > 0) {
            let data = {
                country: machine.country,
                description: machine.outlet_name,
                paymentAmount: totalAmount("paymentAmount"),
                user,
                endPoint,
                sendThis,
            }
            history.push("/payment/pagbank", data)
        } else {
            setLoading(true)
            axios
                .post(endPoint, sendThis, { headers })
                .then(res => {
                    saveTransactionType("Transaction")
                    setPaymentConfirmationModal(false)
                    setLoading(false)
                    if (res.data.status === "rm") {
                        window.location.href = res.data.data;
                    } else if (res.data.status === "laundro") {
                        history.push("/thankyou");
                    } else if (res.data.status === "razer") {
                        setRazerBody(res.data.data)
                    } else if (res.data.status === "duitnow") {
                        history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
                    } else if (res.data.status === "ghl") {
                        setGhlBody(res.data.data)
                    } else if (res.data.status === "rhbduitnow") {
                        history.push("/guide/duitnow", { image: `data:image/png;base64,${res.data.data.data}`, transactionId: res.data.transactionId })
                    } else if (res.data.status === "bangkokbank") {
                        history.push("/guide/promptpay", { image: res.data.data, transactionId: res.data.transactionId })
                    }
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                })
        }
    }

    const useVoucher = () => {
        if (user) {
            let id = selectVoucherId
            if (id) {
                let foundVoucher = user.vouchers.filter((v) => v.id === id)[0];
                setVoucherId(foundVoucher.id)
                setDiscountAmount({
                    type: foundVoucher.type,
                    amount: foundVoucher.amount
                })
                setOpen(false);
            }
        }
    };

    const chooseVoucher = (voucherId) => {
        if (user) {
            let id = voucherId
            if (id) {
                let foundVoucher = user.vouchers.filter((v) => v.id === id)[0];
                setVoucherId(foundVoucher.id)
                setDiscountAmount({
                    type: foundVoucher.type,
                    amount: foundVoucher.amount
                })
                setOpen(false);
            }
        }
    }

    const renderVouchers = () => {
        if (user) {
            if (user.vouchers && user.vouchers.length) {
                return user.vouchers
                    .filter((voucher) => voucher.available && voucher.package_use)
                    .map((voucher) => {
                        let currentVoucherAmount = voucher.amount
                        return (
                            <div key={voucher.id} className="payment-voucher">
                                {STORETYPE === "cleanpro" ? (
                                    <div
                                        style={{ borderRadius: "0" }}
                                        className="voucher my-3"
                                        key={voucher.id}
                                    >
                                        <div
                                            className="voucher-content center-content"
                                            style={{
                                                color: "black",
                                                position: "relative",
                                                zIndex: "99",
                                                marginTop: "1em",
                                            }}
                                        >
                                            <div
                                                className="d-flex"
                                                style={{
                                                    width: "50%",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignContent: "center",
                                                    marginLeft: "1em",
                                                    textAlign: "left",
                                                }}
                                            >
                                                <h2 className="font-weight-bold">
                                                    {voucher.type === "Flat" ? <Trans i18nKey="voucherCreditsOff" count={currentVoucherAmount}>{{ currentVoucherAmount }} Credits OFF</Trans> : <Trans i18nKey="voucherPercentageOff" count={currentVoucherAmount}>{{ currentVoucherAmount }}% OFF</Trans>}
                                                </h2>

                                                <small>{voucher.description}</small>

                                                {voucher.expired_date ? (
                                                    <h6 style={{ color: "grey" }} className="m-0 p-0">
                                                        <small>{t("Valid Till")}: {new Date(voucher.expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                                                    </h6>
                                                ) : null}

                                            </div>
                                            <div
                                                style={{ width: "30%" }}
                                                className="center-content"
                                            >
                                                <input
                                                    onChange={() => setSelectVoucherId(voucher.id)}
                                                    style={{
                                                        border: "1px solid grey",
                                                        cursor: "pointer",
                                                        opacity: "1",
                                                        pointerEvents: "fill",
                                                        position: "relative",
                                                    }}
                                                    type="radio"
                                                    name="paymentVoucher"
                                                    id={voucher.id}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        key={voucher.id}
                                        onClick={() => chooseVoucher(voucher.id)}
                                        className="list-group-item"
                                    >
                                        <p>{voucher.title}</p>
                                        <h6>
                                            {t("Discount")} :- {voucher.type === "Flat" ? currency(machine.country) : null}
                                            {voucher.amount}
                                            {voucher.type === "Rate" ? "%" : null}
                                        </h6>
                                    </div>
                                )}
                            </div>
                        );
                    });
            } else {
                return (
                    <div className="mt-4">
                        <p style={{ color: "red" }}>{t("No Vouchers Available")}</p>
                    </div>
                );
            }
        } else {
            return null;
        }
    };

    return (
        <div>
            {
                list.map((pack, index) => {
                    return (
                        <div key={index} className="payment-section">
                            <div className="d-flex justify-content-between">
                                <p><b>{t("Pack")} {index + 1}</b></p>
                                <i className="bi bi-trash3-fill" style={{ color: "red", fontSize: "20px" }} onClick={() => deleteItem(pack.sequence)}></i>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p><b>{t("Size")}</b></p>
                                <p>{pack.size}</p>
                            </div>
                            {
                                pack.wash ? (
                                    <div>
                                        <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                                        <p><b>{t("Wash")}</b></p>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Mode")}</p>
                                            <p>{t(pack.washMode)}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Capacity")}</p>
                                            <p>{pack.washCapacity}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Price")}</p>
                                            <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${pack.washPrice} ${t("credit")}` : `${currency(machine.country)} ${pack.washPrice}`}</b></p>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                pack.dry ? (
                                    <div>
                                        <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                                        <p><b>{t("Dry")}</b></p>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Duration")}</p>
                                            <p>{pack.dryDuration} {t("min")}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Capacity")}</p>
                                            <p>{pack.dryCapacity}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Price")}</p>
                                            <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${pack.dryPrice} ${t("credit")}` : `${currency(machine.country)} ${pack.dryPrice}`}</b></p>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                pack.fold ? (
                                    <div>
                                        <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                                        <p><b>{t("Fold")}</b></p>
                                        <div className="d-flex justify-content-between">
                                            <p>{t("Price")}</p>
                                            <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${pack.foldPrice} ${t("credit")}` : `${currency(machine.country)} ${pack.foldPrice}`}</b></p>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                    )
                })
            }

            <div>
                <div className="payment-section payment-notification-container">
                    <p><b>{t("Select how you want to receive a notification when your laundry is finished")}:</b></p>
                    <div className="d-flex justify-content-around form-group pt-1">
                        <label htmlFor="radio2">
                            <input type="radio" name="notificationType" id="radio2" value="Email" className="with-gap" checked={notificationType === "Email"} onChange={e => setNotificationType(e.target.value)} />
                            <span style={{ color: "black" }}>{t("Email")}</span>
                        </label>

                        <label htmlFor="radio3">
                            <input type="radio" name="notificationType" id="radio3" value="SMS" className="with-gap" checked={notificationType === "SMS"} onChange={e => setNotificationType(e.target.value)} />
                            <span style={{ color: "black" }}>SMS ({STORETYPE === "cleanpro" && user && user.tokenSetting ? `${totalAmount("sms")} ${t("credit")}` : `${currency(machine.country)} ${totalAmount("sms")}`}/SMS)</span>
                        </label>
                    </div>

                    {
                        notificationType === "Email" ? (
                            <div className="form-group">
                                <input type="email" id="email" className="form-control browser-default" value={email} onChange={e => setEmail(e.target.value)} required placeholder={t("Email Address")} />
                            </div>
                        ) : null
                    }

                    {
                        notificationType === "SMS" ? (
                            <div className="form-group">
                                <input type="tel" id="number" className="form-control browser-default" value={number} onChange={e => setNumber(e.target.value)} required placeholder={t("Phone Number")} />
                            </div>
                        ) : null
                    }
                </div>
            </div>

            <div className="payment-section">
                {
                    user ? (
                        <div>
                            {
                                discountCode || !voucherId ? (
                                    <div>
                                        {
                                            STORETYPE === "cleanpro" ? (
                                                <div
                                                    id="cleanpro-input-discountcode"
                                                    onClick={(e) => setDiscountCodeModal(true)}
                                                    className="checkout-payment py-2"
                                                >
                                                    <i className="bi bi-percent text-center" style={{ width: "32px" }}></i>
                                                    <p className="ml-1">
                                                        {discountCode ? discountCode : t("Discount Code")}
                                                    </p>
                                                    <p>
                                                        <i className="arrow right"></i>
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="list-group payment-list-group">
                                                    <div className="list-group-item payment-list-group-item">
                                                        <div>
                                                            <p className="text-secondary">{t("DISCOUNT CODE")}</p>
                                                        </div>

                                                        <div>
                                                            <button
                                                                id="select-voucher"
                                                                type="button"
                                                                onClick={(e) => setDiscountCodeModal(true)}
                                                                className="text-secondary"
                                                                style={{
                                                                    border: "none",
                                                                    backgroundColor: "white",
                                                                    outline: "none",
                                                                    fontWeight: "bold",
                                                                    paddingRight: "0px",
                                                                    fontSize: "large",
                                                                }}
                                                            >
                                                                {discountCode ? discountCode : t("Enter code >")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : null
                            }
                            {
                                voucherId || !discountCode ? (
                                    <div>
                                        {
                                            STORETYPE === "cleanpro" ? (
                                                <div
                                                    id="cleanpro-select-voucher"
                                                    onClick={(e) => setOpen(true)}
                                                    className="checkout-payment py-2"
                                                >
                                                    <img
                                                        className="fit-contain"
                                                        style={{ width: "32px" }}
                                                        src={ticket}
                                                        alt=""
                                                    />
                                                    <p className="ml-1">
                                                        {voucherId ? t("1 voucher selected") : t("No voucher selected")}
                                                    </p>
                                                    <p>
                                                        <i className="arrow right"></i>
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="list-group payment-list-group">
                                                    <div className="list-group-item payment-list-group-item">
                                                        <div>
                                                            <p className="text-secondary">{t("VOUCHER")}</p>
                                                        </div>

                                                        <div>
                                                            <button
                                                                id="select-voucher"
                                                                type="button"
                                                                onClick={(e) => setOpen(true)}
                                                                className="text-secondary"
                                                                style={{
                                                                    border: "none",
                                                                    backgroundColor: "white",
                                                                    outline: "none",
                                                                    fontWeight: "bold",
                                                                    paddingRight: "0px",
                                                                    fontSize: "large",
                                                                }}
                                                            >
                                                                {voucherId ? discountText() : t("Select Voucher >")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : null
                            }
                            {
                                STORETYPE === "cleanpro" && user && user.tokenSetting ? (
                                    <div
                                        id="cleanpro-reload-token"
                                        // onClick={() => history.push("/user/reload")}
                                        className="checkout-payment py-2"
                                    >
                                        <img style={{ width: "32px" }} src={creditBear} alt="" />
                                        <p className="ml-1">
                                            {user.token} {t("credits")}
                                        </p>
                                        {/* <p>
                                            <i className="arrow right"></i>
                                        </p> */}
                                    </div>
                                ) : null
                            }
                            {
                                STORETYPE === "cleanpro" && user && user.loyalty_point ? (
                                    <div id="cleanpro-point" className="d-flex justify-content-between py-2">
                                        <div className="d-flex align-items-center">
                                            <img style={{ width: "32px" }} src={cleanproCreditIcon} alt="" />
                                            <p className="ml-1">
                                                {user.point} {user.loyalty_point_name}
                                            </p>
                                        </div>
                                        <div className="toggle-switch">
                                            <input type="checkbox" className="toggle-switch-checkbox" name="point" id="point" checked={point} onChange={e => setPoint(!point)} />
                                            <label className="toggle-switch-label" htmlFor="point">
                                                <span className="toggle-switch-inner" />
                                                <span className="toggle-switch-switch" />
                                            </label>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                STORETYPE !== "cleanpro" && user && user.loyalty_point ? (
                                    <div className="list-group payment-list-group">
                                        <div className="list-group-item payment-list-group-item">
                                            <div>
                                                <p className="text-secondary">{user.loyalty_point_name} ({user.point})</p>
                                            </div>

                                            <div className="toggle-switch">
                                                <input type="checkbox" className="toggle-switch-checkbox" name="point" id="point" checked={point} onChange={e => setPoint(!point)} />
                                                <label className="toggle-switch-label" htmlFor="point">
                                                    <span className="toggle-switch-inner" />
                                                    <span className="toggle-switch-switch" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }
                            <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div>
                            <div className="d-flex justify-content-between py-2">
                                <p style={{ fontWeight: "500" }}>{t("Total")}</p>
                                <p>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${totalAmount("amount")} ${t("credit")}` : `${currency(machine.country)} ${totalAmount("amount")}`}</p>
                            </div>
                            {
                                voucherId || discountCode ? (
                                    <div className="d-flex justify-content-between py-2">
                                        <p style={{ fontWeight: "500" }}>{t("Offers")}</p>
                                        <p>-{discountText()}</p>
                                    </div>
                                ) : null
                            }
                            {
                                point ? (
                                    <div className="d-flex justify-content-between py-2">
                                        <p style={{ fontWeight: "500" }}>{user.loyalty_point_name}</p>
                                        <p>-{totalAmount("pointAmount")} {user.loyalty_point_name} ({STORETYPE === "cleanpro" && user.tokenSetting ? "" : currency(machine.country)} {totalAmount("pointValue")} {STORETYPE === "cleanpro" && user.tokenSetting ? t("credit") : ""})</p>
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : null
                }
                {
                    notificationType === "SMS" ? (
                        <div className="d-flex justify-content-between py-2">
                            <p style={{ fontWeight: "500" }}>{t("SMS")}</p>
                            <p>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${totalAmount("sms")} ${t("credit")}` : `${currency(machine.country)} ${totalAmount("sms")}`}</p>
                        </div>
                    ) : null
                }
                <div className="d-flex justify-content-between py-2">
                    <p style={{ fontWeight: "500" }}>{t("Amount to Pay")}</p>
                    <p className="blue-t"><b>{STORETYPE === "cleanpro" && user && user.tokenSetting ? `${totalAmount("paymentAmount")} ${t("credit")}` : `${currency(machine.country)} ${totalAmount("paymentAmount")}`}</b></p>
                </div>
            </div>

            <div className="px-3 pb-3">
                <button
                    id="pay-dng"
                    className="btn form-control big-button"
                    onClick={(e) => pay(e)}
                >
                    {t("Pay now")}
                </button>
            </div>

            <div className="px-3 pb-3">
                <button
                    id="add-more-dng"
                    onClick={(e) => setPage("Size")}
                    className="btn form-control secondary-button"
                    style={{ height: "50px" }}
                >
                    {t("Add more")}
                </button>
            </div>

            {/* CUSTOM VOUCHER MODAL */}
            {user ? (
                <div>
                    <div
                        onClick={(e) => setOpen(false)}
                        className={isOpen ? "custom-overlay isOpen" : "custom-overlay"}
                    ></div>
                    <div className={isOpen ? "custom-modal isOpen" : "custom-modal"}>
                        <div className="custom-modal-header">
                            {voucherId ? (
                                <button
                                    id="remove-voucher"
                                    onClick={(e) => removeDiscount()}
                                    type="button"
                                    className="custom-modal-title m-0 p-0"
                                    style={{
                                        color: "red",
                                        border: "none",
                                        backgroundColor: "white",
                                        margin: 0,
                                        padding: 0,
                                    }}
                                >
                                    <i className="bi bi-slash-circle"></i> {t("Remove Voucher")}
                                </button>
                            ) : (
                                <h6 className="custom-modal-title">
                                    {STORETYPE === "cleanpro" ? (
                                        t("Select voucher to use")
                                    ) : (
                                        <>
                                            <i className="bi bi-tags-fill"></i> {t("Select Your Voucher")}
                                        </>
                                    )}
                                </h6>
                            )}
                        </div>

                        <div className="custom-modal-body">
                            <ul className="list-group list-group-flush px-4">
                                {renderVouchers()}
                            </ul>
                        </div>
                        {
                            STORETYPE === "cleanpro" ? (
                                <button id="use-voucher" onClick={useVoucher} className="btn big-button" style={{ width: "90%" }}>
                                    {t("Use voucher")}
                                </button>
                            ) : null
                        }
                    </div>
                </div>
            ) : null}

            {
                razerBody ? (
                    <div>
                        <form action={razerBody.paymentDomain} method="post">
                            <input type="hidden" name="amount" value={razerBody.amount} />
                            <input type="hidden" name="merchant_id" value={razerBody.merchant_id} />
                            <input type="hidden" name="orderid" value={razerBody.orderid} />
                            {razerBody.bill_name ? <input type="hidden" name="bill_name" value={razerBody.bill_name} /> : null}
                            {razerBody.bill_email ? <input type="hidden" name="bill_email" value={razerBody.bill_email} /> : null}
                            {razerBody.bill_mobile ? <input type="hidden" name="bill_mobile" value={razerBody.bill_mobile} /> : null}
                            <input type="hidden" name="bill_desc" value={razerBody.bill_desc} />
                            <input type="hidden" name="country" value={razerBody.country} />
                            <input type="hidden" name="vcode" value={razerBody.vcode} />
                            <input type="hidden" name="currency" value={razerBody.currency} />
                            <input type="hidden" name="returnurl" value={razerBody.returnurl} />
                            <input type="hidden" name="callbackurl" value={razerBody.callbackurl} />
                            <input type="hidden" name="cancelurl" value={razerBody.cancelurl} />

                            <input type="submit" name="submit" value="Pay with razer" hidden ref={razerButtonClick} />
                        </form>
                    </div>
                ) : null
            }

            {
                ghlBody ? (
                    <div>
                        <form name="frmPayment" method="post" action={ghlBody.frmPayment}>
                            <input type="hidden" name="TransactionType" value={ghlBody.TransactionType} />
                            <input type="hidden" name="PymtMethod" value={ghlBody.PymtMethod} />
                            <input type="hidden" name="ServiceID" value={ghlBody.ServiceID} />
                            <input type="hidden" name="PaymentID" value={ghlBody.PaymentID} />
                            <input type="hidden" name="OrderNumber" value={ghlBody.OrderNumber} />
                            <input type="hidden" name="PaymentDesc" value={ghlBody.PaymentDesc} />
                            <input type="hidden" name="MerchantReturnURL" value={ghlBody.MerchantReturnURL} />
                            {ghlBody.MerchantCallBackURL ? <input type="hidden" name="MerchantCallBackURL" value={ghlBody.MerchantCallBackURL} /> : null}
                            <input type="hidden" name="Amount" value={ghlBody.Amount} />
                            <input type="hidden" name="CurrencyCode" value={ghlBody.CurrencyCode} />
                            <input type="hidden" name="CustIP" value={ghlBody.CustIP} />
                            <input type="hidden" name="CustName" value={ghlBody.CustName} />
                            <input type="hidden" name="CustEmail" value={ghlBody.CustEmail} />
                            <input type="hidden" name="CustPhone" value={ghlBody.CustPhone} />
                            <input type="hidden" name="HashValue" value={ghlBody.HashValue} />
                            <input type="hidden" name="PageTimeout" value={ghlBody.PageTimeout} />

                            <input type="submit" name="submit" value="Pay with GHL" hidden ref={ghlButtonClick} />
                        </form>

                    </div>
                ) : null
            }

            <Modal
                open={discountCodeModal}
                onCancel={(e) => setDiscountCodeModal(false)}
                footer={null}
                centered
                title={t("Enter Discount Code")}
            >
                <div>
                    <form onSubmit={checkDiscountCode}>
                        {discountCode ? (
                            <div className="form-group text-left">
                                <label htmlFor="code">{t("Discount Code")}</label>
                                <input
                                    type="text"
                                    id="code"
                                    name="code"
                                    value={discountCode}
                                    className="form-control browser-default"
                                    readOnly
                                />
                            </div>
                        ) : (
                            <div className="form-group text-left">
                                <label htmlFor="code">{t("Discount Code")}</label>
                                <input
                                    type="text"
                                    id="code"
                                    name="code"
                                    value={discountCodeInput}
                                    className="form-control browser-default"
                                    onChange={(e) => setDiscountCodeInput(e.target.value.toUpperCase())}
                                    required
                                />
                            </div>
                        )}

                        <div className="text-center pt-3">
                            {loading ? (
                                <div className="text-center">
                                    <div
                                        className={`spinner-border text-${STORETYPE === "cuci" ? "warning" : "primary"}`}
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : discountCode ? (
                                <input
                                    id="remove-discount-code"
                                    type="button"
                                    value={t("Remove Code")}
                                    onClick={() => removeDiscount()}
                                    className="btn btn-primary mx-2"
                                />
                            ) : (
                                <input
                                    id="submit-discount-code"
                                    type="submit"
                                    value={t("Submit")}
                                    className="btn btn-primary mx-2"
                                />
                            )}
                            <button
                                id="close-discount-code"
                                type="button"
                                className="btn btn-secondary"
                                onClick={(e) => setDiscountCodeModal(false)}
                            >
                                {t("Close")}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                open={insufficientTokenModal}
                onCancel={(e) => setInsufficientTokenModal(false)}
                footer={null}
                closable={false}
                centered
            >
                <div className={theme}>
                    {
                        STORETYPE === "cleanpro" ? (
                            <img
                                className="d-block pb-3"
                                src={insufficientToken}
                                style={{ width: "64px", margin: "0 auto" }}
                                alt=""
                            />
                        ) : null
                    }
                    <h2 className="text-center pb-2">{STORETYPE === "cleanpro" ? t("Insufficient balance") : t("Insufficient e-Tokens")}</h2>
                    {STORETYPE === "cleanpro" ? <p className="text-center" style={{ fontSize: "16px", fontWeight: 300 }}>{t("Oops. You don't have enough balance to make the payment")}</p> : <p className="text-center">{t("Reload your e-token now?")}</p>}
                    <div className={`d-flex pt-3 justify-content-center ${STORETYPE === "cleanpro" ? "flex-row-reverse" : ""}`}>
                        <button id="cleanpro-topup-now" onClick={() => history.push("/user/reload")} className="btn btn-primary cleanpro-swal-btn washup-swal-btn mx-1 reload-token" style={{ backgroundColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4" }}>
                            {STORETYPE === "cleanpro" ? t("Topup now") : t("Reload now!")}
                        </button>
                        <button id="cleanpro-cancel-topup" onClick={() => setInsufficientTokenModal(false)} className="btn btn-secondary cleanpro-swal-secondary-btn washup-swal-btn mx-1 cancel-reload-token">
                            {STORETYPE === "cleanpro" ? t("Back") : t("Cancel")}
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={paymentMethodModal}
                onCancel={(e) => setPaymentMethodModal(false)}
                footer={null}
                closable={false}
                centered
            >
                <div className={theme}>
                    <h2 className="text-center pb-2">{STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method")}</h2>
                    <p className="text-center">{STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?")}</p>
                    <div className="pt-3 d-flex flex-wrap justify-content-center">
                        {
                            user && user.tokenSetting ? (
                                <button
                                    type="button"
                                    className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn e-wallet mx-1 my-1"
                                    style={{ backgroundColor: STORETYPE === "washup" ? "#CFA82C" : "#2778c4" }}
                                    onClick={(e) => handlePaymentMethod("eToken")}
                                >
                                    {STORETYPE === "cleanpro" ? t("Cleanpro Plus credits") : t("e-Token")}
                                </button>
                            ) : null
                        }
                        {
                            machine.epayment ? (
                                <button
                                    type="button"
                                    className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn e-token mx-1 my-1"
                                    style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#67b668" }}
                                    onClick={(e) => handlePaymentMethod("eWallet")}
                                >
                                    {STORETYPE === "cleanpro" ? `${t("E-wallet or credit/debit cards")} >` : t("E-Wallet")}
                                </button>
                            ) : null
                        }
                        {
                            machine.duitnow || machine.rhbduitnow ? (
                                <button
                                    type="button"
                                    className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn duitnow mx-1 my-1"
                                    style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#67b668" }}
                                    onClick={(e) => handlePaymentMethod("duitnow")}
                                >
                                    {t("Duitnow QR code")}
                                </button>
                            ) : null
                        }
                        {
                            machine.bangkokbank ? (
                                <button
                                    type="button"
                                    className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn promptpay mx-1 my-1"
                                    style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#2778c4" }}
                                    onClick={(e) => handlePaymentMethod("promptpay")}
                                >
                                    {t("PromptPay QR Code")}
                                </button>
                            ) : null
                        }
                        <button
                            type="button"
                            className="btn btn-secondary cleanpro-swal-big-secondary-btn washup-swal-btn cancel-payment mx-1 my-1"
                            style={{ backgroundColor: STORETYPE === "cleanpro" ? "#004FC1" : "#6C757D" }}
                            onClick={(e) => setPaymentMethodModal(false)}
                        >
                            {t("Cancel")}
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={voucherReminderModal}
                onCancel={(e) => setVoucherReminderModal(false)}
                footer={null}
                closable={false}
                centered
            >
                <div className={theme}>
                    <h2 className="text-center pb-2">{t("You haven't applied your voucher")}</h2>
                    <p className="text-center">{t("Would you like to continue your payment without using your voucher?")}</p>
                    <div className="d-flex pt-3 justify-content-center">
                        <button
                            type="button"
                            className="btn btn-primary pay-without-voucher cleanpro-swal-secondary-btn washup-swal-btn mx-1"
                            style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#2778c4" }}
                            onClick={(e) => { setVoucherReminderModal(false); setPaymentMethodModal(true) }}
                        >
                            {t("Continue")}
                        </button>

                        <button
                            type="button"
                            className="btn btn-secondary apply-voucher cleanpro-swal-btn washup-swal-btn mx-1"
                            onClick={(e) => { setVoucherReminderModal(false); setOpen(true) }}
                        >
                            {t("Apply voucher")}
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={paymentConfirmationModal}
                onCancel={(e) => setPaymentConfirmationModal(false)}
                footer={null}
                closable={false}
                centered
                maskClosable={false}
            >
                <div className={theme}>
                    <div className="text-center">
                        <i className="bi bi-question-circle" style={{ color: "#87adbd", fontSize: "70px" }}></i>
                    </div>
                    <h2 className="text-center pb-2">{t("Confirmation")}</h2>
                    {paymentConfirmationText ? <div>{paymentConfirmationText}</div> : null}
                    <div className={`d-flex pt-3 justify-content-center ${STORETYPE === "cleanpro" ? "flex-row-reverse" : ""}`}>
                        {
                            loading ? (
                                <div className="text-center">
                                    <div
                                        className={`spinner-border text-${STORETYPE === "cuci" ? "warning" : "primary"}`}
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        className="btn btn-primary cleanpro-swal-btn washup-swal-btn mx-1"
                                        style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#2778c4" }}
                                        onClick={(e) => confirmPayment()}
                                    >
                                        {STORETYPE === "cleanpro" ? t("Pay now") : t("OK")}
                                    </button>

                                    <button
                                        type="button"
                                        className="btn btn-secondary cleanpro-swal-secondary-btn washup-swal-btn mx-1"
                                        onClick={(e) => setPaymentConfirmationModal(false)}
                                    >
                                        {STORETYPE === "cleanpro" ? t("Cancel") : t("No")}
                                    </button>
                                </>
                            )
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveTransactionType: (data) => dispatch(saveTransactionType(data)),
  };
};

export default connect(null, mapDispatchToProps)(Payment);