import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from 'mdbreact';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import { DatePicker, Select } from "antd";
import { currency, dateFormat, timeFormat } from "../../../../dynamicController";
import Loading from "../Loading";

const { RangePicker } = DatePicker;

const mySwal = withReactContent(Swal);

const UserUsageReportCard = ({ role, token, country, operators, distributors, outlets }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [userCountry, setUserCountry] = useState("")
    const [outletIds, setOutletIds] = useState([])
    const [operatorId, setOperatorId] = useState(null)
    const [distributorId, setDistributorId] = useState(null)
    const [outletOption, setOutletOption] = useState([])
    const [operatorOption, setOperatorOpion] = useState([])
    const [distributorOption, setDistributorOption] = useState([])

    useEffect(() => {
        if (outlets) {
            let outletList = []
            let filterOutlet = outlets
            if (distributorId) {
                filterOutlet = outlets.filter(outlet => outlet.distributorId === distributorId)
            }
            if (operatorId) {
                filterOutlet = outlets.filter(outlet => outlet.adminId === operatorId)
            }
            for (let i = 0; i < filterOutlet.length; i++) {
                let outletObject = {
                    value: filterOutlet[i].id,
                    label: `${filterOutlet[i].outlet_fullname} (${filterOutlet[i].outlet_name})`
                }
                outletList.push(outletObject)
            }
            setOutletOption(outletList)
        }
    }, [outlets, operatorId, distributorId])

    useEffect(() => {
        if (role === "client") {
            if (operators) {
                let operatorList = []
                for (let i = 0; i < operators.length; i++) {
                    let operatorObject = {
                        value: operators[i].id,
                        label: operators[i].username
                    }
                    operatorList.push(operatorObject)
                }
                setOperatorOpion(operatorList)
            }
            if (distributors) {
                let distributorList = []
                for (let i = 0; i < distributors.length; i++) {
                    let distributorObject = {
                        value: distributors[i].id,
                        label: distributors[i].name
                    }
                    distributorList.push(distributorObject)
                }
                setDistributorOption(distributorList)
            }
        } else {
            setLoading(true)
            axios
                .post("/api/admin/report/userUsageReport", { startDate, endDate, outletIds }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDate = (dates, dateStrings) => {
        if (dates) {
            setStartDate(dateStrings[0])
            setEndDate(dateStrings[1])
        } else {
            setStartDate("")
            setEndDate("")
        }
    };

    const handleReset = () => {
        setStartDate("")
        setEndDate("")
        setOperatorId(null)
        setDistributorId(null)
        setOutletIds([])
        setData([])
        setUserCountry("")
        if (role !== "client") {
            setLoading(true)
            axios
                .post("/api/admin/report/userUsageReport", { outletIds: [] }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleFilter = () => {
        if (role === "client" && !distributorId && !operatorId && outletIds.length <= 0) {
            mySwal.fire(t("Error"), t("Please select distributor, operator or outlet"), "error")
        } else {
            setLoading(true)
            axios
                .post("/api/admin/report/userUsageReport", { startDate, endDate, operatorId, distributorId, outletIds }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleChangeDistributor = (value) => {
        setDistributorId(value)
        setOperatorId(null)
        if (value) {
            setUserCountry(distributors.filter(distributor => distributor.id === value)[0].country)
        } else {
            setUserCountry("")
        }
    }

    const handleChangeOperator = (value) => {
        setOperatorId(value)
        setDistributorId(null)
        if (value) {
            let selectedOperator = operators.filter(operator => operator.id === value)[0]
            let selectedCountry = selectedOperator.country
            if (selectedOperator.distributorId) {
                let selectedDistributor = distributors.filter(distributor => distributor.id === selectedOperator.distributorId)[0]
                if (selectedDistributor) {
                    selectedCountry = selectedDistributor.country
                }
            }
            setUserCountry(selectedCountry)
        } else {
            setUserCountry("")
        }
    }

    const handleChangeOutlet = (value) => {
        setOutletIds(value)
        if (!distributorId && !operatorId && role === "client") {
            let selectedOutlet = outlets.filter(outlet => outlet.id === value[0])[0]
            if (selectedOutlet) {
                if (selectedOutlet.distributorId) {
                    handleChangeDistributor(selectedOutlet.distributorId)
                } else {
                    handleChangeOperator(selectedOutlet.adminId)
                }
            }
        }
    }

    const tableData = {
        columns: [
            { label: t("Outlet Name"), field: "outlet_name", sort: "asc" },
            { label: t("Customer Name"), field: "name", sort: "asc" },
            { label: t("Member ID"), field: "member_id", sort: "asc" },
            { label: t("Phone Number"), field: "number", sort: "asc" },
            { label: t("Email Address"), field: "email", sort: "asc" },
            { label: t("Number of Washer Transactions"), field: "washer_transactions", sort: "asc" },
            { label: t("Number of Dryer Transactions"), field: "dryer_transactions", sort: "asc" },
            { label: t("Vouchers Redeemed"), field: "vouchers_redeemed", sort: "asc" },
            { label: t("Discount Code Redemption"), field: "discounts_redeemed", sort: "asc" },
            { label: `${t("Total Spend")} ${country || userCountry ? `(${currency(country || userCountry)})` : ""}`, field: "total_spend", sort: "asc" }
        ],
        rows: data
    };

    const csvData = {
        headers: [
            { label: t("Outlet Name"), key: "outlet_name" },
            { label: t("Customer Name"), key: "name" },
            { label: t("Member ID"), key: "member_id" },
            { label: t("Phone Number"), key: "number" },
            { label: t("Email Address"), key: "email" },
            { label: t("Vouchers Redeemed"), key: "vouchers_redeemed" },
            { label: t("Discount Code Redemption"), key: "discounts_redeemed" },
            { label: t("Number of Washer Transactions"), key: "washer_transactions" },
            { label: t("Number of Dryer Transactions"), key: "dryer_transactions" },
            { label: t("Total Spend"), key: "total_spend" }
        ],
        data: data.map(item => ({
            ...item,
            number: `=""${item.number}""`
        }))
    };

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">{t("User Usage Report")}</h5>
            </div>

            <div className="card-body">
                <div className="mb-5">
                    <h6>{t("Filter")}</h6>
                    {
                        role === "client" ? (
                            <div className="mb-3">
                                <label htmlFor="distributorId">{t("Select Distributor")}: </label>
                                <Select
                                    id="distributorId"
                                    name="distributorId"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Distributor")}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleChangeDistributor(value)}
                                    options={distributorOption}
                                    value={distributorId}
                                />
                            </div>
                        ) : null
                    }

                    {
                        role === "client" ? (
                            <div className="mb-3">
                                <label htmlFor="operatorId">{t("Select Operator")}: </label>
                                <Select
                                    id="operatorId"
                                    name="operatorId"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Operator")}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleChangeOperator(value)}
                                    options={operatorOption}
                                    value={operatorId}
                                />
                            </div>
                        ) : null
                    }

                    <div className="mb-3">
                        <label htmlFor="outletId">{t("Select Outlet")}: </label>
                        <Select
                            id="outletId"
                            name="outletId"
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder={t("Select Outlet")}
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) => handleChangeOutlet(value)}
                            options={outletOption}
                            value={outletIds}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="date">{t("Select Transaction Date")}: </label>
                        <RangePicker
                            id="date"
                            name="date"
                            placeholder={[t("Start date"), t("End date")]}
                            onChange={handleDate}
                            style={{ width: '100%' }}
                            value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []}
                            presets={[
                                { label: t("Today"), value: [dayjs(), dayjs()] },
                                { label: t("Yesterday"), value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')] },
                                { label: t("Last 7 Days"), value: [dayjs().subtract(6, 'day'), dayjs()] },
                                { label: t("Last 30 Days"), value: [dayjs().subtract(29, 'day'), dayjs()] },
                                { label: t("This Month"), value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                                { label: t("Last Month"), value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] }
                            ]}
                        />
                    </div>

                    <div className="d-flex">
                        <button onClick={() => handleFilter()} className="btn btn-primary btn-inline form-control me-3">
                            {t("Filter")}
                        </button>
                        <button onClick={() => handleReset()} className="btn btn-secondary btn-inline form-control ms-3">
                            {t("Reset")}
                        </button>
                    </div>
                </div>

                <div className="text-center mt-5">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`user_usage_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download Report")}
                                    </CSVLink>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default UserUsageReportCard;