// import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { /*Trans,*/ useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { changeLanguage } from "../../../../redux/actions/userActions";
import { logoController } from '../../../../dynamicController';

const StartScreen = ({ setPage, language, changeLanguage, data, themeLogo }) => {
    const { t } = useTranslation();

    return (
        <div
            id="start-screens"
            onClick={() => setPage('Option')}
            className="vh-100 bg-startscreen text-white d-flex flex-column overflow-auto"
        >
            <div className="flex-grow-0 d-flex flex-column align-items-center pt-3">
                <div style={{ maxWidth: '200px', maxHeight: '200px' }}>
                    <img
                        src={logoController(themeLogo)}
                        alt="main logo"
                        className="img-fluid"
                    />
                </div>
            </div>

            <div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                <div className="h1 fw-bold mb-2 text-color">
                    {t('Touch to Start')}
                </div>
            </div>

            <div className="flex-grow-0 d-flex flex-column align-items-center pb-3">
                <div className="text-center mb-3">
                    <p className="h5 fw-bold mb-1 text-color">{t("Reminder")}:</p>
                    <p className="h6 text-color">{t("Load your clothes into the washing machine & note the machine number")}</p>
                </div>

                <div className="d-flex justify-content-center gap-2 mb-2">
                    <Button
                        active={language === "en"}
                        className="rounded-pill px-3 py-1 text-color kiosk-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            changeLanguage('en');
                        }}
                    >
                        EN
                    </Button>
                    <Button
                        active={language === "ms"}
                        className="rounded-pill px-3 py-1 text-color kiosk-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            changeLanguage('ms');
                        }}
                    >
                        BM
                    </Button>
                    <Button
                        active={language === "zh"}
                        className="rounded-pill px-3 py-1 text-color kiosk-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            changeLanguage('zh');
                        }}
                    >
                        中文
                    </Button>
                </div>

                <div className="text-center text-color">
                    <small className="fw-normal">{t('T&C Apply')}</small>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
        language: state.user.language,
        themeLogo: state.theme.logo
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeLanguage: (language) => dispatch(changeLanguage(language)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartScreen);