import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import axios from "axios";

// cleanpro plus ui
import cpPlusLogo from "../../../assets/images/logo/Logo_Cleanproplus-white.png";
import locationIcon from "../../../assets/images/icons/location.png";
import cpPlusBlueLogo from "../../../assets/images/logo/Logo_Cleanproplus-blue.png";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { changeNav, changePage, checkToken } from "../../../redux/actions/userActions";
import { scannedCode } from "../../../redux/actions/paymentActions";
import { decrypt } from "../../../dynamicController";

import SizeSelection from "./dng/sizeSelection";
import DetailsSetting from "./dng/detailsSetting";
import Payment from "./dng/payment";

import { Modal } from "react-bootstrap";

const mySwal = withReactContent(Swal);

const STORETYPE = process.env.REACT_APP_STORETYPE;

const DngPayment = ({
    payment,
    user,
    history,
    changePage,
    scanned,
    operatorId,
    changeNav,
    theme,
    token,
    checkToken
}) => {
    const { t } = useTranslation();

    const [cleanproNonUserModal, setCleanproNonUserModal] = useState(false);
    const [registerVoucherSetting, setRegisterVoucherSetting] = useState(null);
    const [birthdayVoucherSetting, setBirthdayVoucherSetting] = useState(null);
    const [page, setPage] = useState("Size");
    const [cart, setCart] = useState([]);
    const [sizeSelection, setSizeSelection] = useState([]);
    const [size, setSize] = useState("");
    const [modeSelection, setModeSelection] = useState([]);
    const [mode, setMode] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [pack, setPack] = useState(1);
    const [notificationType, setNotificationType] = useState("Email");
    const [email, setEmail] = useState(user && user.email ? user.email : "");
    const [number, setNumber] = useState(user && user.number ? user.number : "");
    const [voucherId, setVoucherId] = useState(null);
    const [discountCode, setDiscountCode] = useState("");
    const [discountAmount, setDiscountAmount] = useState(null);
    const [point, setPoint] = useState(false);

    useEffect(() => {
        if (!payment.machine) {
            if (payment.outlet.outletCode && payment.outlet.operatorCode) {
                axios
                    .post("/api/user/getDngPricing", {
                        outletCode: payment.outlet.outletCode,
                        opId: payment.outlet.operatorCode,
                        operatorId,
                    })
                    .then((res) => {
                        const response = decrypt(res.data.data)
                        scanned(response);
                        const sortedSizePriceData = response.priceData.sort((a, b) => {
                            // Extract the numeric value from the "weight" property
                            let weightA = parseInt(a.weight) || 999 // fold weight is null
                            let weightB = parseInt(b.weight) || 999

                            // Compare the weights
                            if (weightA < weightB) {
                                return -1; // a should come before b
                            }
                            if (weightA > weightB) {
                                return 1; // a should come after b
                            }
                            return 0; // weights are equal
                        })

                        let sizes = []
                        for (let i = 0; i < sortedSizePriceData.length; i++) {
                            if (sizes.filter(s => s.size === sortedSizePriceData[i].size).length <= 0) {
                                sizes.push({ size: sortedSizePriceData[i].size, weight: sortedSizePriceData[i].weight })
                            }
                        }

                        setSizeSelection(sizes)
                        setRegisterVoucherSetting(response.voucherSettings.filter(setting => setting.type === "Register")[0])
                        setBirthdayVoucherSetting(response.voucherSettings.filter(setting => setting.type === "Birthday")[0])
                    })
                    .catch((err) => {
                        mySwal.fire(t("Error"), t(err.response.data.error), "error");
                    });
            } else {
                history.push("/");
            }
        } else {
            const sortedSizePriceData = payment.machine.priceData.sort((a, b) => {
                // Extract the numeric value from the "weight" property
                let weightA = parseInt(a.weight) || 999
                let weightB = parseInt(b.weight) || 999

                // Compare the weights
                if (weightA < weightB) {
                    return -1; // a should come before b
                }
                if (weightA > weightB) {
                    return 1; // a should come after b
                }
                return 0; // weights are equal
            })

            let sizes = []
            for (let i = 0; i < sortedSizePriceData.length; i++) {
                if (sizes.filter(s => s.size === sortedSizePriceData[i].size).length <= 0) {
                    sizes.push({ size: sortedSizePriceData[i].size, weight: sortedSizePriceData[i].weight })
                }
            }

            setSizeSelection(sizes)
            setRegisterVoucherSetting(payment.machine.voucherSettings.filter(setting => setting.type === "Register")[0])
            setBirthdayVoucherSetting(payment.machine.voucherSettings.filter(setting => setting.type === "Birthday")[0])
        }

        if (!user) {
            if (token) {
                checkToken()
            } else if (payment.machine?.member_payment) {
                if (STORETYPE === "cleanpro") {
                    setCleanproNonUserModal(true)
                } else {
                    mySwal
                        .fire({
                            title: t("Join as a member now!"),
                            html: t("Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>"),
                            icon: "info",
                            showCancelButton: true,
                            cancelButtonText: t("Maybe Next Time"),
                            confirmButtonText: t("Yes, Register Now"),
                            cancelButtonColor: "#d33",
                            customClass: {
                                confirmButton: "register-now",
                                cancelButton: "register-next-time",
                            },
                        })
                        .then((click) => {
                            if (click.isConfirmed && click.value) {
                                changePage("register");
                                history.goBack();
                            }
                        });

                    return function cleanup() {
                        mySwal.close();
                    };
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="payment-page" className="dng-payment-page">
            <div id="user-header" className="dng-user-header">
                {STORETYPE === "cleanpro" ? (
                    <div>
                        <div className="d-flex justify-content-between pt-2">
                            <img className="plus-logo" src={cpPlusLogo} alt="" />
                            {
                                user ? (
                                    <h5 className="mt-2">
                                        <i
                                            onClick={(e) => {
                                                changeNav("camera");
                                                history.push("/")
                                            }}
                                            className="bi bi-chevron-left me-3"
                                        ></i>{" "}
                                        {t("Scan QR")}
                                    </h5>
                                ) : null
                            }
                        </div>
                        <p>{t("Drop n Go Service")}</p>
                        <div className="d-flex pb-3 pt-2">
                            <div>
                                <img src={locationIcon} alt="" />
                            </div>
                            <p className="ml-1" style={{ fontSize: "11px", marginTop: "auto" }}>{payment.machine && payment.machine.outlet_fullname ? payment.machine.outlet_fullname : ""}</p>
                        </div>
                    </div>
                ) : (
                    <div>
                        <h5>
                            {
                                user ? (
                                    <i
                                        onClick={(e) => history.push("/")}
                                        className="bi bi-chevron-left me-3"
                                    ></i>
                                ) : null
                            }
                            <strong>
                                {t("Drop n Go Service")}
                            </strong>
                        </h5>
                        {payment.machine && payment.machine.outlet_fullname ? <p className="m-0 pt-3">Outlet: {payment.machine.outlet_fullname}</p> : null}
                    </div>
                )}
            </div>

            <div>
                {payment.machine ? (
                    <div>
                        {
                            page === "Size" ? (
                                <SizeSelection
                                    setSize={setSize}
                                    sizeSelection={sizeSelection}
                                    setPage={setPage}
                                    cart={cart}
                                />
                            ) : page === "Details" ? (
                                <DetailsSetting
                                    setPage={setPage}
                                    size={size}
                                    machine={payment.machine}
                                    setCart={setCart}
                                    cart={cart}
                                    setModeSelection={setModeSelection}
                                    modeSelection={modeSelection}
                                    setMode={setMode}
                                    mode={mode}
                                    setQuantity={setQuantity}
                                    quantity={quantity}
                                    setPack={setPack}
                                    pack={pack}
                                    user={user}
                                />
                            ) : page === "Payment" ? (
                                <Payment
                                    setPage={setPage}
                                    cart={cart}
                                    setCart={setCart}
                                    machine={payment.machine}
                                    notificationType={notificationType}
                                    setNotificationType={setNotificationType}
                                    email={email}
                                    setEmail={setEmail}
                                    number={number}
                                    setNumber={setNumber}
                                    user={user}
                                    voucherId={voucherId}
                                    setVoucherId={setVoucherId}
                                    discountCode={discountCode}
                                    setDiscountCode={setDiscountCode}
                                    discountAmount={discountAmount}
                                    setDiscountAmount={setDiscountAmount}
                                    theme={theme}
                                    point={point}
                                    setPoint={setPoint}
                                    token={token}
                                />
                            ) : null
                        }
                    </div>
                ) : null}
            </div>
            <Modal
                show={cleanproNonUserModal}
                onHide={(e) => setCleanproNonUserModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <img src={cpPlusBlueLogo} alt="logo" style={{ width: "80%" }} />
                </Modal.Header>
                <Modal.Body>
                    <div className={theme}>
                        <p style={{ fontSize: "22px", fontWeight: 500, lineHeight: "120%" }}>{t("Register as a member to enjoy more benefits!")}</p>
                        <ul style={{ listStyleType: "square", listStylePosition: "inside", fontSize: "16px", fontWeight: 300 }} className="pt-3">
                            {
                                registerVoucherSetting && registerVoucherSetting.voucher_eligible && registerVoucherSetting.voucher_type === "Flat"
                                    ? <li style={{ listStyleType: "square" }}><Trans i18nKey="Get FREE {{ voucherAmount }} credits per new user" count={registerVoucherSetting.voucher_amount}>Get FREE {{ voucherAmount: registerVoucherSetting.voucher_amount }} credits per new user</Trans></li>
                                    : null
                            }
                            {
                                registerVoucherSetting && registerVoucherSetting.voucher_eligible && registerVoucherSetting.voucher_type === "Rate"
                                    ? <li style={{ listStyleType: "square" }}><Trans i18nKey="Get FREE {{ voucherAmount }}% per new user">Get FREE {{ voucherAmount: registerVoucherSetting.voucher_amount }}% per new user</Trans></li>
                                    : null
                            }
                            <li style={{ listStyleType: "square" }}>{t("Enjoy exclusive promotions")}</li>
                            {
                                birthdayVoucherSetting && birthdayVoucherSetting.voucher_eligible
                                    ? <li style={{ listStyleType: "square" }}>{t("FREE voucher on your birthday")}</li>
                                    : null
                            }
                            <li style={{ listStyleType: "square" }}>{t("and more")}</li>
                        </ul>
                        <div className="d-flex justify-content-around">
                            <button id="cleanpro-sign-up" onClick={() => { changePage("register"); history.goBack(); }} style={{ fontSize: "16px", fontWeight: 500, width: "35%" }} className="btn big-button">
                                {t("Sign up")}
                            </button>
                            <button id="cleanpro-continue-to-pay" onClick={() => setCleanproNonUserModal(false)} style={{ height: "50px", width: "60%" }} className="btn secondary-button">
                                {t("Continue to pay")}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        payment: state.payment,
        user: state.user.user,
        operatorId: state.user.operatorId,
        theme: state.theme.theme,
        token: state.user.token
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changePage: (data) => dispatch(changePage(data)),
        scanned: (data) => dispatch(scannedCode(data)),
        changeNav: (nav) => dispatch(changeNav(nav)),
        checkToken: () => dispatch(checkToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DngPayment);
