import { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import io from 'socket.io-client';

import Loading from "../components/Loading";
import { currency, dateFormat } from '../../../dynamicController';

import { Trans, useTranslation } from "react-i18next";

const mySwal = withReactContent(Swal);

const UniqueDiscountCode = ({ token, id, role, country }) => {

    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [number_of_code, setNumberOfCode] = useState("");
    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [discount_amount, setDiscountAmount] = useState("");
    const [discount_type, setDiscountType] = useState("");
    const [washer_use, setWasherUse] = useState(false);
    const [dryer_use, setDryerUse] = useState(false);
    const [package_use, setPackageUse] = useState(false);
    const [redeem_per_user, setRedeemPerUser] = useState("");
    const [pay_and_use, setPayAndUse] = useState(false);
    const [pay_and_use_amount, setPayAndUseAmount] = useState(1);
    const [description, setDescription] = useState("");

    useEffect(() => {
        if (id) {
            setLoading(true)
            axios
                .post("/api/admin/setting/getCodeGenerationHistory", { id, role, type: "Discount Code" }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data);
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    mySwal.fire(t("Error"), t(err.response.data.error), "error");
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        if (data.length > 0) {
            const pendingCode = data.filter((d) => d.status === "Pending")
            if (pendingCode.length > 0) {
                const socket = io({ auth: { transactionId: pendingCode[0].id } })
                socket.on("generatedVoucherCode", (voucherCodeHistory) => {
                    const newData = data.map(d => {
                        if (d.id === pendingCode[0].id) {
                            d.status = voucherCodeHistory.status
                            return d
                        } else {
                            return d
                        }
                    })
                    setData(newData)
                })

                return () => {
                    socket.disconnect();
                };
            }
        }
    }, [data])

    const handleDate = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const hideModal = () => {
        setNumberOfCode("");
        setStartDate("");
        setEndDate("");
        setDiscountAmount("");
        setDiscountType("");
        setWasherUse(false);
        setDryerUse(false);
        setPackageUse(false);
        setRedeemPerUser("");
        setPayAndUse(false);
        setPayAndUseAmount(1);
        setDescription("");
        setShowModal(false);
    }

    const openModal = () => {
        setNumberOfCode("");
        setStartDate("");
        setEndDate("");
        setDiscountAmount("");
        setDiscountType("");
        setWasherUse(false);
        setDryerUse(false);
        setPackageUse(false);
        setRedeemPerUser("");
        setPayAndUse(false);
        setPayAndUseAmount(1);
        setDescription("");
        setShowModal(true);
    }

    const generateUniqueCode = (e) => {
        e.preventDefault();

        if (!washer_use && !dryer_use && !package_use) {
            mySwal.fire(t("Missing Field"), t("Please select can be used for washer, dryer or package"), "info");
        } else if (!start_date && !end_date) {
            mySwal.fire(t("Missing Field"), t("Please fill in the start date and end date"), "info");
        } else if (parseFloat(discount_amount) <= 0) {
            mySwal.fire(t("Missing Field"), t("Discount amount should be more than 0"), "info");
        } else if (parseInt(redeem_per_user) <= 0) {
            mySwal.fire(t("Missing Field"), t("Redeem per user should be more than 0"), "info");
        } else if (number_of_code <= 0) {
            mySwal.fire(t("Missing Field"), t("Number of discount code should be more than 0"), "info");
        } else if (number_of_code > 50000) {
            mySwal.fire(t("Missing Field"), t("Number of discount code should be less than 50000"), "info");
        } else {
            setLoading(true);

            const sendThis = {
                id,
                role,
                number_of_code,
                start_date: dateFormat(start_date),
                end_date: dateFormat(end_date),
                discount_amount,
                discount_type,
                washer_use,
                dryer_use,
                package_use,
                redeem_per_user,
                pay_and_use,
                pay_and_use_amount,
                description
            }

            axios
                .post("/api/admin/setting/generateDiscountCode", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {

                    axios
                        .post("/api/admin/setting/getCodeGenerationHistory", { id, role, type: "Discount Code" }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {
                            setLoading(false);
                            setData(res.data);
                            setShowModal(false)
                            mySwal.fire(t("Success"), t("Your discount code is generating"), "success")
                        })
                        .catch(err => {
                            setLoading(false);
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                })
                .catch(err => {
                    setLoading(false);
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const downloadCode = (id) => {
        setLoading(true)

        axios
            .post("/api/admin/setting/downloadUniqueVoucherCode", { id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
            .then(res => {
                window.location = res.data.url;
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    }


    let tableData = {
        columns: [
            {
                label: "ID",
                field: "id",
                sort: "asc"
            },
            {
                label: t("Created Date"),
                field: "createdAt",
                sort: "asc"
            },
            {
                label: t("Name"),
                field: "description",
                sort: "asc"
            },
            {
                label: t("Number Of Discount Code"),
                field: "number_of_code",
                sort: "asc"
            },
            {
                label: t("Start Date"),
                field: "start_date",
                sort: "asc"
            },
            {
                label: t("End Date"),
                field: "end_date",
                sort: "asc"
            },
            {
                label: t("Redeem Per User"),
                field: "redeem_per_user",
                sort: "asc"
            },
            {
                label: t("Discount Type"),
                field: "discount_type",
                sort: "asc"
            },
            {
                label: t("Discount Amount"),
                field: "discount_amount",
                sort: "asc"
            },
            {
                label: t("Washer Use"),
                field: "washer_use",
                sort: "asc"
            },
            {
                label: t("Dryer Use"),
                field: "dryer_use",
                sort: "asc"
            },
            {
                label: t("Package Use"),
                field: "package_use",
                sort: "asc"
            },
            {
                label: t("Pay And Use Code"),
                field: "pay_and_use",
                sort: "asc"
            },
            {
                label: `${t("Minimum Transaction Amount To Use Code")} (${currency(country)})`,
                field: "pay_and_use_amount",
                sort: "asc"
            },
            {
                label: t("Status"),
                field: "status",
                sort: "asc"
            },
            {
                label: t("Action"),
                field: "action"
            }
        ],
        rows: []
    };

    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            const dataObject = {
                id: data[i].id,
                createdAt: data[i].createdAt,
                description: data[i].description,
                number_of_code: data[i].number_of_code,
                start_date: data[i].start_date,
                end_date: data[i].expired_date,
                redeem_per_user: data[i].redeem_per_user,
                discount_type: data[i].discount_type === "Flat" ? `${data[i].discount_type} (${currency(country)})` : `${data[i].discount_type} (%)`,
                discount_amount: data[i].discount_amount,
                washer_use: data[i].washer_use,
                dryer_use: data[i].dryer_use,
                package_use: data[i].package_use,
                pay_and_use: data[i].pay_and_use,
                pay_and_use_amount: data[i].pay_and_use_amount,
                status: t(data[i].status),
                action:
                    data[i].status === "Pending"
                        ? <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        : data[i].status === "Completed"
                            ? <button type="button" className="btn btn-primary" onClick={() => downloadCode(data[i].id)}>
                                {t("Download")}
                            </button> : null
            }
            tableData.rows.push(dataObject);
        }
    }

    return (
        <div>
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title">{t("Unique Discount Code")}</h5>

                    <button className="btn btn-primary" type="button" onClick={e => openModal()}>
                        {t("Generate Discount Code")}
                    </button>
                </div>

                <div className="card-body">
                    <div>
                        {
                            loading
                                ? <Loading />
                                : <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                        }
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={e => hideModal()}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Generate Discount Code")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={generateUniqueCode}>
                            <div className="form-group">
                                <label htmlFor="description">{t("Name")}</label>
                                <input type="text" id="description" name="description" value={description} onChange={e => setDescription(e.target.value)} className="form-control browser-default" required />
                            </div>

                            <div className="form-group text-left">
                                <label htmlFor="number_of_code">{t("Number Of Discount Code")}</label>
                                <input type="number" id="number_of_code" name="number_of_code" value={number_of_code} onChange={e => setNumberOfCode(e.target.value)} className="form-control browser-default" required step="1" min="1" max="50000" />
                            </div>

                            <div className="form-group text-left">
                                <label htmlFor="date">{t("Select Period")}: </label>
                                <DatePicker value={`${start_date ? `${new Date(start_date).getDate()}/${new Date(start_date).getMonth() + 1}/${new Date(start_date).getFullYear()}` : ""} - ${end_date ? `${new Date(end_date).getDate()}/${new Date(end_date).getMonth() + 1}/${new Date(end_date).getFullYear()}` : ""}`} onChange={handleDate} startDate={start_date} endDate={end_date} selectsRange shouldCloseOnSelect={false} required />
                            </div>

                            <div className="form-group text-left">
                                <label htmlFor="redeem_per_user">{t("Redeem per user")} <small>({t("Max redemption per user in this batch discount code")})</small> : </label>
                                <input type="number" className="form-control browser-default" id="redeem_per_user" name="redeem_per_user" value={redeem_per_user} onChange={e => setRedeemPerUser(e.target.value)} min="1" step="1" required />
                            </div>

                            <div className="form-group text-left">
                                <label htmlFor="discount_type">{t("Discount Type")} : </label>
                                <select className="browser-default form-select" id="discount_type" name="discount_type" value={discount_type} onChange={e => setDiscountType(e.target.value)} required>
                                    <option value="" disabled>{t("Select a discount type")}</option>
                                    <option value="Flat">{t("Flat")} (- {currency(country)})</option>
                                    <option value="Rate">{t("Rate")} (- %)</option>
                                </select>
                            </div>

                            <div className="form-group text-left">
                                <label htmlFor="discount_amount">{t("Discount Amount")} : <small>{discount_type === "Flat" ? `(${currency(country)})` : discount_type === "Rate" ? "(%)" : ""}</small></label>
                                <input type="number" className="form-control browser-default" id="discount_amount" name="discount_amount" value={discount_amount} onChange={e => setDiscountAmount(e.target.value)} min="0" step="0.01" required />
                            </div>

                            <div className="text-center">
                                <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                    <div className="form-group">
                                        <label>
                                            <input type="checkbox" id="pay_and_use" name="pay_and_use" checked={pay_and_use} onChange={e => setPayAndUse(!pay_and_use)} />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {t("If ticked, the user only can use the discount code after make a transaction")}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>{t("Pay And Use Code")}</span>
                                            </OverlayTrigger>
                                        </label>
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            <input type="checkbox" id="package_use" name="package_use" checked={package_use} onChange={e => setPackageUse(!package_use)} />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {t("If ticked, the discount code can apply to Drop n Go transaction")}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>{t("Package Use")}</span>
                                            </OverlayTrigger>
                                        </label>
                                    </div>
                                </div>

                                <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                    <div className="form-group">
                                        <label>
                                            <input type="checkbox" id="washer_use" name="washer_use" checked={washer_use} onChange={e => setWasherUse(!washer_use)} />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {t("If ticked, the discount code can apply to washer machine transaction")}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>{t("Washer Use")}</span>
                                            </OverlayTrigger>
                                        </label>
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            <input type="checkbox" id="dryer_use" name="dryer_use" checked={dryer_use} onChange={e => setDryerUse(!dryer_use)} />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {t("If ticked, the discount code can apply to dryer machine transaction")}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>{t("Dryer Use")}</span>
                                            </OverlayTrigger>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            {
                                pay_and_use ? (
                                    <div className="form-group text-left">
                                        <label htmlFor="pay_and_use_amount">{t("Minimum Payment Amount To Use Discount Code")} : <small>({currency(country)})</small></label>
                                        <input type="number" className="form-control browser-default" id="pay_and_use_amount" name="pay_and_use_amount" value={pay_and_use_amount} onChange={e => setPayAndUseAmount(e.target.value)} min="0" step="0.01" required />
                                    </div>
                                ) : null
                            }

                            <p className="p-0 m-0"><small>* {t("The user enter the discount code")} {t("in the payment page will get")} {discount_type === "Flat" ? currency(country) : ""}{discount_amount}{discount_type === "Rate" ? "%" : ""} {t("discount")}</small></p>
                            {
                                start_date && end_date
                                    ? <p className="p-0 m-0">
                                        <small>*
                                            <Trans i18nKey="The discount code only can be used start from {{ start_date }} until {{ end_date }}">
                                                The discount code only can be used start from {{ start_date: new Date(start_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) }} until {{ end_date: new Date(end_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) }}
                                            </Trans>
                                        </small>
                                    </p>
                                    : ""
                            }
                            {
                                redeem_per_user
                                    ? <p className="p-0 m-0">
                                        <small>*
                                            <Trans i18nKey="Every user only can use {{ redeem_per_user }} discount code from this batch of code">
                                                Every user only can use {{ redeem_per_user }} discount code from this batch of code
                                            </Trans>
                                        </small>
                                    </p>
                                    : ""
                            }
                            {washer_use && !dryer_use && !package_use ? <p className="p-0 m-0"><small>* {t("The discount code only can be used for washer machine")}</small></p> : ""}
                            {!washer_use && dryer_use && !package_use ? <p className="p-0 m-0"><small>* {t("The discount code only can be used for dryer machine")}</small></p> : ""}
                            {!washer_use && !dryer_use && package_use ? <p className="p-0 m-0"><small>* {t("The discount code only can be used for Drop n Go transaction")}</small></p> : ""}
                            {washer_use && dryer_use && !package_use ? <p className="p-0 m-0"><small>* {t("The discount code can be used for washer or dryer machine")}</small></p> : ""}
                            {washer_use && !dryer_use && package_use ? <p className="p-0 m-0"><small>* {t("The discount code can be used for washer machine or Drop n Go transaction")}</small></p> : ""}
                            {!washer_use && dryer_use && package_use ? <p className="p-0 m-0"><small>* {t("The discount code can be used for dryer machine or Drop n Go transaction")}</small></p> : ""}
                            {washer_use && dryer_use && package_use ? <p className="p-0 m-0"><small>* {t("The discount code can be used for washer machine, dryer machine or Drop n Go transaction")}</small></p> : ""}
                            {
                                pay_and_use
                                    ? <p className="p-0 m-0">
                                        <small>*
                                            <Trans i18nKey="The discount code only can be used after user spending {{ currency }} {{ pay_and_use_amount }}">
                                                The discount code only can be used after user spending {{ currency: currency(country) }} {{ pay_and_use_amount }}
                                            </Trans>
                                        </small>
                                    </p>
                                    : null
                            }

                            <div className="text-center pt-3">
                                {loading ? (
                                    <div className="text-center">
                                        <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : <input type="submit" value={t("Generate Discount Code")} className="btn btn-primary mx-2" />}
                                <button type="button" className="btn btn-secondary" onClick={e => hideModal()}>
                                    {t("Close")}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default UniqueDiscountCode;