import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Select, Modal } from "antd";
import ReactQuill from 'react-quill';

import Loading from "../Loading";

const mySwal = withReactContent(Swal);

const TermCard = ({ id, role, token }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [type, setType] = useState("TERMS AND CONDITIONS")
    const [text, setText] = useState("")
    const [selectedId, setSelectedId] = useState("")
    const [modal, setModal] = useState(false)
    const [typeOption, setTypeOption] = useState([])

    useEffect(() => {
        if (id && role) {
            setLoading(true)
            axios
                .post("/api/admin/setting/fetchTerm", { id, role }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, role])

    useEffect(() => {
        let allTypeOption = [
            {
                value: "TERMS AND CONDITIONS",
                label: t("Terms & Conditions")
            },
            {
                value: "PRIVACY POLICY",
                label: t("Privacy Policy")
            },
            {
                value: "ABOUT US",
                label: t("About Us")
            },
        ]

        let option = []
        for (let i = 0; i < allTypeOption.length; i++) {
            let foundData = data.filter(data => data.type === allTypeOption[i].value)[0]
            if (!foundData) {
                option.push(allTypeOption[i])
            }
        }

        setTypeOption(option)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const editTermModal = (id) => {
        const selectedData = data.filter(data => data.id === id)[0]
        if (selectedData) {
            setText(selectedData.text)
            setType(selectedData.type)
            setSelectedId(selectedData.id)
            setModal(true)
        }
    }

    const createTermModal = () => {
        setText("")
        setType(typeOption[0].value)
        setSelectedId("")
        setModal(true)
    }

    const submit = (e) => {
        e.preventDefault();

        if (!type) {
            mySwal.fire(t("Missing Field"), t("Please select the type"), "info");
        } else if (!text) {
            mySwal.fire(t("Missing Field"), t("Please select the text"), "info");
        } else {
            setLoading(true)

            axios
                .post("/api/admin/setting/updateTerm", { id, role, type, text }, { headers: { "auth-token": token } })
                .then(res => {

                    axios
                        .post("/api/admin/setting/fetchTerm", { id, role }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {
                            setData(res.data.data)
                            setLoading(false)
                            setModal(false)
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    let tableData = {
        columns: [
            {
                label: t("Type"),
                field: "type",
                sort: "asc"
            },
            {
                label: t("Action"),
                field: "action",
                sort: "asc"
            },
        ],
        rows: []
    }

    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            const dataObject = {
                type: data[i].type,
                action: (
                    <div>
                        <i className="bi bi-pencil-square" onClick={() => editTermModal(data[i].id)}></i>
                    </div>
                )
            }
            tableData.rows.push(dataObject)
        }
    }

    return (
        <div>
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title">{t("Terms & Conditions")}</h5>

                    {
                        typeOption.length > 0 ? (
                            <button className="btn btn-primary" type="button" onClick={() => createTermModal()}>
                                {t("Add Terms")}
                            </button>
                        ) : null
                    }
                </div>

                <div className="card-body">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                            </div>
                        )
                    }
                </div>
            </div>

            <Modal
                open={modal}
                footer={null}
                onCancel={() => setModal(!modal)}
            >
                <form onSubmit={submit}>
                    <div className="form-group text-left">
                        <label htmlFor="type">{t("Type")}</label>
                        <Select
                            className="select-input"
                            style={{ width: "100%" }}
                            placeholder={t("Select type")}
                            options={typeOption}
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) => setType(value)}
                            value={type}
                            disabled={selectedId ? true : false}
                        />
                    </div>

                    <div className="form-group text-left">
                        <label htmlFor="text">{t("Text")}</label>
                        <ReactQuill id="text" name="text" theme="snow" required value={text} onChange={value => setText(value)} />
                    </div>

                    <div className="text-center pt-3">
                        {
                            loading
                                ? (
                                    <div className="text-center">
                                        <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )
                                : <input type="submit" value={t("Submit")} className="btn btn-primary mx-2" />
                        }
                        <button type="button" className="btn btn-secondary" onClick={e => setModal(!modal)}>
                            {t("Close")}
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default TermCard;