import { useHistory } from "react-router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { Trans } from "react-i18next";

import cleanproStampBanner from "../../../assets/images/news/cleanproStampBanner.jpg";
import cpThaiStampBanner from "../../../assets/images/news/cpThaiStampBanner.jpg";
import washer from "../../../assets/images/machines/icn-washer.png";
import dryer from "../../../assets/images/machines/icn-dryer.png";
import Navigator from "./Navigation";
import { currency } from "../../../dynamicController";

const StampDetails = ({ user }) => {
  const history = useHistory();
  const [stampTransactionAmount, setStampTransactionAmount] = useState(0);
  const [stampVoucherSetting, setStampVoucherSetting] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (!user) {
      history.push('/')
    } else {
      setStampTransactionAmount(user.stampTransactionAmount)
      if (user.combinedSetting) {
        let voucherSetting = user.voucher_setting.filter(setting => setting.type === "Combined Stamp")[0]
        if (voucherSetting && voucherSetting.voucher_eligible) {
          setStampVoucherSetting(voucherSetting)
        }
      } else if (user.specificStamp === "washerOnly") {
        let voucherSetting = user.voucher_setting.filter(setting => setting.type === "Washer Stamp")[0]
        if (voucherSetting && voucherSetting.voucher_eligible) {
          setStampVoucherSetting(voucherSetting)
        }
      } else if (user.specificStamp === "dryerOnly") {
        let voucherSetting = user.voucher_setting.filter(setting => setting.type === "Dryer Stamp")[0]
        if (voucherSetting && voucherSetting.voucher_eligible) {
          setStampVoucherSetting(voucherSetting)
        }
      } else {
        let washerStampVoucherSetting = user.voucher_setting.filter(setting => setting.type === "Washer Stamp")[0]
        let dryerStampVoucherSetting = user.voucher_setting.filter(setting => setting.type === "Dryer Stamp")[0]
        if (washerStampVoucherSetting && washerStampVoucherSetting.voucher_eligible) {
          setStampVoucherSetting(washerStampVoucherSetting)
        } else if (dryerStampVoucherSetting && dryerStampVoucherSetting.voucher_eligible) {
          setStampVoucherSetting(dryerStampVoucherSetting)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="stamp-details-page">
      <div id="user-header">
        <h5>
          <strong onClick={() => history.push("/")}>
            <i className="bi bi-chevron-left me-3"></i> {t("Back")}
          </strong>
        </h5>
      </div>
      <div className="pb-5">
        <div className="text-center">
          <img src={user.country === "Thailand" ? cpThaiStampBanner : cleanproStampBanner} className="img-fluid" alt="" />
        </div>
        <div className="pt-3 px-3">
          {
            stampVoucherSetting
              ? stampVoucherSetting.voucher_type === "Flat"
                ?
                <p style={{ fontSize: "16px" }}>
                  <Trans
                    i18nKey="With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }} credits voucher (worth {{ currency }}{{ voucherAmount }}). Make your laundry routine not just hassle-free, but also rewarding."
                    count={stampVoucherSetting.voucher_amount}
                  >
                    With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount: stampVoucherSetting.voucher_amount }} credits voucher (worth {{ currency: currency(user.country) }}{{ voucherAmount: stampVoucherSetting.voucher_amount }}). Make your laundry routine not just hassle-free, but also rewarding.
                  </Trans>
                </p>
                :
                <p style={{ fontSize: "16px" }}>
                  <Trans i18nKey="With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount }}% credits voucher (worth {{ voucherAmount }}%). Make your laundry routine not just hassle-free, but also rewarding.">
                    With every 10 e-stamps that you collected, you will be rewarded with a FREE {{ voucherAmount: stampVoucherSetting.voucher_amount }}% credits voucher (worth {{ voucherAmount: stampVoucherSetting.voucher_amount }}%). Make your laundry routine not just hassle-free, but also rewarding.
                  </Trans>
                </p>
              : null
          }
          {
            user && !user.combinedSetting && user.specificStamp === "washerOnly"
              ? <p className="pt-2" style={{ fontSize: "16px" }}>{t("A voucher will be instantly added to your Rewards page when you reach 10 washes. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.")}</p>
              : user && !user.combinedSetting && user.specificStamp === "dryerOnly"
                ? <p className="pt-2" style={{ fontSize: "16px" }}>{t("A voucher will be instantly added to your Rewards page when you reach 10 drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.")}</p>
                : <p className="pt-2" style={{ fontSize: "16px" }}>{t("A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.")}</p>
          }
          {
            user && user.combinedSetting ? (
              <div className="box-shadow-card mt-2 py-3">
                <div className="d-flex">
                  <img src={washer} style={{ width: "40px", padding: "3px" }} alt="" />
                  <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")} / {t("Dry")}</p>
                  <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user ? user.combinedStamps : 0}/10</p>
                </div>
                <progress id="progress-bar" value={user ? user.combinedStamps : 0} max="10"></progress>
              </div>
            ) : user && user.specificStamp === "both" ? (
              <div>
                <div className="box-shadow-card mt-2 py-3">
                  <div className="d-flex">
                    <img src={washer} style={{ width: "40px", padding: "3px" }} alt="" />
                    <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")}</p>
                    <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user ? user.washerStamp : 0}/10</p>
                  </div>
                  <progress id="progress-bar" value={user ? user.washerStamp : 0} max="10"></progress>
                </div>
                <div className="box-shadow-card mt-3 py-3">
                  <div className="d-flex">
                    <img src={dryer} style={{ width: "40px" }} alt="" />
                    <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Dry")}</p>
                    <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user ? user.dryerStamp : 0}/10</p>
                  </div>
                  <progress id="progress-bar" value={user ? user.dryerStamp : 0} max="10"></progress>
                </div>
              </div>
            ) : user && user.specificStamp === "washerOnly" ? (
              <div className="box-shadow-card mt-2 py-3">
                <div className="d-flex">
                  <img src={washer} style={{ width: "40px", padding: "3px" }} alt="" />
                  <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")}</p>
                  <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user ? user.washerStamp : 0}/10</p>
                </div>
                <progress id="progress-bar" value={user ? user.washerStamp : 0} max="10"></progress>
              </div>
            ) : user && user.specificStamp === "dryerOnly" ? (
              <div className="box-shadow-card mt-3 py-3">
                <div className="d-flex">
                  <img src={dryer} style={{ width: "40px" }} alt="" />
                  <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Dry")}</p>
                  <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user ? user.dryerStamp : 0}/10</p>
                </div>
                <progress id="progress-bar" value={user ? user.dryerStamp : 0} max="10"></progress>
              </div>
            ) : null
          }
        </div>
        <hr className="my-5" />
        <div className="px-3 pb-5">
          <h6>{t("Terms")} & {t("Conditions")}:</h6>
          <ol>
            <li>
              <Trans i18nKey="To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credits ({{ currency }}{{ stampTransactionAmount }}) per session." count={stampTransactionAmount}>
                To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credits ({{ currency: currency(user?.country) }}{{ stampTransactionAmount }}) per session.
              </Trans>
            </li>
            {
              user
                ? user.combinedSetting
                  ? <li>{t("You will be automatically issued a voucher if you have collected 10 stamps.")}</li>
                  : user.specificStamp === "washerOnly"
                    ? <li>{t("You will be automatically issued a voucher if you have collected 10 washer stamps.")}</li>
                    : user.specificStamp === "dryerOnly"
                      ? <li>{t("You will be automatically issued a voucher if you have collected 10 dryer stamps.")}</li>
                      : user.specificStamp === "both"
                        ? <li>{t("You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.")}</li> : null
                : null
            }
            {
              stampVoucherSetting
                ? stampVoucherSetting.voucher_expiry
                  ? <li>
                    <Trans i18nKey="The voucher is valid for {{ duration }} days from the day voucher be given." count={stampVoucherSetting.voucher_duration}>
                      The voucher is valid for {{ duration: stampVoucherSetting.voucher_duration }} days from the day voucher be given.
                    </Trans>
                  </li>
                  : <li>{t("There is no expiration date for this voucher.")}</li>
                : null
            }
            {
              stampVoucherSetting && stampVoucherSetting.voucher_washer_use && !stampVoucherSetting.voucher_dryer_use && !stampVoucherSetting.voucher_package_use
                ? <li>{t("The voucher can be used for washer machine only.")}</li>
                : null
            }
            {
              stampVoucherSetting && !stampVoucherSetting.voucher_washer_use && stampVoucherSetting.voucher_dryer_use && !stampVoucherSetting.voucher_package_use
                ? <li>{t("The voucher can be used for dryer machine only.")}</li>
                : null
            }
            {
              stampVoucherSetting && !stampVoucherSetting.voucher_washer_use && !stampVoucherSetting.voucher_dryer_use && stampVoucherSetting.voucher_package_use
                ? <li>{t("The voucher can be used for Drop n Go transaction only.")}</li>
                : null
            }
            {
              stampVoucherSetting && stampVoucherSetting.voucher_washer_use && stampVoucherSetting.voucher_dryer_use && !stampVoucherSetting.voucher_package_use
                ? <li>{t("The voucher can be used for dryer or washer machine.")}</li>
                : null
            }
            {
              stampVoucherSetting && stampVoucherSetting.voucher_washer_use && !stampVoucherSetting.voucher_dryer_use && stampVoucherSetting.voucher_package_use
                ? <li>{t("The voucher can be used for washer machine or Drop n Go transaction.")}</li>
                : null
            }
            {
              stampVoucherSetting && !stampVoucherSetting.voucher_washer_use && stampVoucherSetting.voucher_dryer_use && stampVoucherSetting.voucher_package_use
                ? <li>{t("The voucher can be used for dryer machine or Drop n Go transaction.")}</li>
                : null
            }
            {
              stampVoucherSetting && stampVoucherSetting.voucher_washer_use && stampVoucherSetting.voucher_dryer_use && stampVoucherSetting.voucher_package_use
                ? <li>{t("The voucher can be used for washer machine, dryer machine or Drop n Go transaction.")}</li>
                : null
            }
            {
              stampVoucherSetting
                ? stampVoucherSetting.voucher_type === "Flat"
                  ? <li>
                    <Trans i18nKey="When you applied the voucher during payment, it will give you {{ voucherAmount }} credits ({{ currency }}{{ voucherAmount }}) discount." count={stampVoucherSetting.voucher_amount}>
                      When you applied the voucher during payment, it will give you {{ voucherAmount: stampVoucherSetting.voucher_amount }} credits ({{ currency: currency(user.country) }}{{ voucherAmount: stampVoucherSetting.voucher_amount }}) discount.
                    </Trans>
                  </li>
                  : <li>
                    <Trans i18nKey="When you applied the voucher during payment, it will give you {{ voucherAmount }}% discount.">
                      When you applied the voucher during payment, it will give you {{ voucherAmount: stampVoucherSetting.voucher_amount }}% discount.
                    </Trans>
                  </li>
                : null
            }
            <li>{t("The voucher is non-refundable and cannot be exchanged for cash; in part or in full.")}</li>
            <li>{t("This voucher is valid for single transaction only.")}</li>
            <li>{t("All vouchers is not valid with other ongoing promotions, discounts and offers.")}</li>
            <li>{t("The voucher is for customer use only and not for sale.")}</li>
            <li>{t("Cleanpro reserves the right to change the terms and conditions without prior notice.")}</li>
          </ol>
        </div>
      </div>
      <Navigator />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(StampDetails);
