import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import UserUsageReportCard from "../../admin/components/report/UserUsageReportCard";

const UserUsageReport = ({ token, distributor }) => {

    const history = useHistory();

    const [toggled, setToggled] = useState(false);

    useEffect(() => {
        if (!distributor || !distributor.user_usage_report) {
            history.push('/distributor')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = () => {
        setToggled(!toggled);
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />
            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />
                <div className="admin-content">

                    <UserUsageReportCard
                        token={token}
                        country={distributor.country}
                        role="distributor"
                        outlets={distributor.outlet}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(UserUsageReport);
