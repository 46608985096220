import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { connect } from "react-redux";
import { logoutUser } from "../../../redux/actions/userActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Trans, useTranslation } from "react-i18next";

import { Link, useHistory } from "react-router-dom";

import { logoController } from "../../../dynamicController";

import profile from "../../../assets/images/icons/icn-profile.png";
import faq from "../../../assets/images/icons/icn-FAQ.png";
import feedback from "../../../assets/images/icons/icn-feedback.png";
import washerIcon from "../../../assets/images/machines/icn-washer.png";
import voucherIcon from "../../../assets/images/navIcons/icn-voucher.png";
import cpPlusLogoBlue from "../../../assets/images/logo/Logo_Cleanproplus-blue.png";

const mySwal = withReactContent(Swal);

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Sidebar = ({
  toggled,
  setToggled,
  confirmLogout,
  themeLogo,
  operatorId,
  user,
}) => {

  const history = useHistory();
  const { t } = useTranslation();

  const logout = () => {
    mySwal
      .fire({
        title: t("Confirmation"),
        text: t("Logout Session?"),
        icon: "question",
        showCancelButton: true,
        cancelButtonText: t("NO"),
        confirmButtonText: t("OK"),
        confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
        customClass: {
          confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
          cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn" : STORETYPE === "washup" ? "washup-swal-btn" : "",
        }
      })
      .then((result) => {
        if (result.isConfirmed) {
          confirmLogout();
          history.push("/")
        }
      });
  };

  const share = () => {
    navigator
      .share({
        title: "Referral Code Sharing",
        text: `Hi, you are invited to register an account with my referral code(${user.memberId}).`,
        url: `https://${window.location.hostname}/welcome?operatorCode=${operatorId}&referralCode=${user.memberId}`,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  };

  return (
    <ProSidebar
      breakPoint="xl"
      toggled={toggled}
      onToggle={(val) => setToggled(val)}
      id="react-pro-sidebar"
    >
      <SidebarHeader id="react-pro-sidebar-header">
        {STORETYPE === "cleanpro" ? (
          <img
            style={{ width: "80%", float: "left", paddingLeft: " 20px" }}
            src={cpPlusLogoBlue}
            alt="main-logo"
            className="fit-contain img-fluid"
          />
        ) : (
          <img
            src={logoController(themeLogo)}
            alt="main-logo"
            className="img-fluid"
          />
        )}
      </SidebarHeader>

      <SidebarContent id="react-pro-sidebar-content">
        <Menu id="react-pro-sidebar-menu" iconShape="circle" popperArrow={true}>
          <MenuItem
            id="account"
            className="react-pro-sidebar-menu-item"
            icon={
              STORETYPE !== "cleanpro" ? (
                <i className="bi bi-person-fill"></i>
              ) : (
                <img style={{ width: "32px" }} src={profile} alt="" />
              )
            }
          >
            {t("Account")} <Link to="/user/setting" />
          </MenuItem>

          <MenuItem
            id="dropngoPayment"
            className="react-pro-sidebar-menu-item"
            icon={
              STORETYPE !== "cleanpro" ? (
                <i className="bi bi-credit-card-2-front-fill"></i>
              ) : (
                <img style={{ width: "32px" }} src={washerIcon} alt="" />
              )
            }
          >
            {t("Drop n Go Service")} <Link to="/user/dng/outlet" />
          </MenuItem>

          {
            user?.country === "Malaysia" || STORETYPE !== "cleanpro" ? (
              <MenuItem
                id="laundryPass"
                className="react-pro-sidebar-menu-item"
                icon={
                  STORETYPE !== "cleanpro" ? (
                    <i className="bi bi-ticket-perforated-fill"></i>
                  ) : (
                    <img style={{ width: "32px" }} src={voucherIcon} alt="" />
                  )
                }
              >
                {t("Laundry Pass")} <Link to="/user/subscription" />
              </MenuItem>
            ) : <></>
          }

          <MenuItem
            id="faq"
            className="react-pro-sidebar-menu-item"
            icon={
              STORETYPE !== "cleanpro" ? (
                <i className="fas bi-question-circle-fill"></i>
              ) : (
                <img style={{ width: "32px" }} src={faq} alt="" />
              )
            }
          >
            {STORETYPE === "cleanpro" ? t("FAQs") : t("Need help?")} <Link to="/faq" />
          </MenuItem>

          <MenuItem
            id="feedback"
            className="react-pro-sidebar-menu-item"
            icon={
              STORETYPE !== "cleanpro" ? (
                <i className="bi bi-chat-left-text"></i>
              ) : (
                <img style={{ width: "32px" }} src={feedback} alt="" />
              )
            }
          >
            {t("Feedback")} <Link to="/user/feedback" />
          </MenuItem>

          {user?.referral ? (
            <MenuItem
              id="referFriends"
              className="react-pro-sidebar-menu-item"
              onClick={share}
              icon={<i className="bi bi-people-fill"></i>}
            >
              {t("Refer Friends")}
            </MenuItem>
          ) : (
            <></>
          )}

          {
            STORETYPE === "cleanpro" || user?.terms.filter(term => term.type === "PRIVACY POLICY").length > 0 ? (
              <MenuItem
                id="privacyPolicy"
                className="react-pro-sidebar-menu-item pt-3"
                style={{ borderTop: STORETYPE === "cleanpro" ? "1px solid black" : "1px solid white" }}
              >
                {t("Privacy Policy")} <Link to="/privacyPolicy" />
              </MenuItem>
            ) : <></>
          }

          {
             STORETYPE === "cleanpro" || user?.terms.filter(term => term.type === "TERMS AND CONDITIONS").length > 0 ? (
              <MenuItem
                id="termsAndCondition"
                className="react-pro-sidebar-menu-item"
              >
                {t("Terms")} & {t("Conditions")} <Link to="/termsConditions" />
              </MenuItem>
            ) : <></>
          }

          {
            user?.terms.filter(term => term.type === "ABOUT US").length > 0 ? (
              <MenuItem
                id="aboutus"
                className="react-pro-sidebar-menu-item"
              >
                {t("About Us")}<Link to="/aboutus" />
              </MenuItem>
            ) : <></>
          }
        </Menu>
      </SidebarContent>

      <SidebarFooter id="react-pro-sidebar-footer">
        <button id="logout" onClick={logout} className="btn big-button form-control">
          {STORETYPE === "cleanpro" ? t("Logout") : t("LOGOUT")}
        </button>
        {STORETYPE === "cleanpro" && (
          <p className="mt-3 t-light-g" style={{ fontSize: "10px" }}>
            <Trans i18nKey="Copyright © {{ year }} Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.">
              Copyright © {{ year: new Date().getFullYear() }} Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.
            </Trans>
          </p>
        )}
      </SidebarFooter>
    </ProSidebar>
  );
};

const mapStateToProps = (state) => {
  return {
    themeLogo: state.theme.logo,
    operatorId: state.user.operatorId,
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirmLogout: () => dispatch(logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
