/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import CreditCard from '../../../../assets/images/logo/CreditCardCleanpro.png';
import CardTap from '../../../../assets/images/logo/CardTap.png';
import DuitNowQR from '../../../../assets/images/logo/DuitNowQR.png';

import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import HeaderLogoCleanpro from '../../../../assets/images/logo/HeaderLogoCleanpro.png';
import BackLogo from '../../../../assets/images/logo/BackLogo.png';
import DownArrow from '../../../../assets/images/logo/DownArrow.png';
// import './Responsive.css';
import Faq from './Faq.js';

const mySwal = withReactContent(Swal);

const Payment = ({ sendThis, setPage, outletId, token, data, machine, endpoint }) => {
    const { t } = useTranslation();

    const [selectedPayment, setSelectedPayment] = useState("");
    const [loading, setLoading] = useState(false);
    const [cancelloading, setCancelLoading] = useState(false);
    const [showFaq, setShowFaq] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [qrCode, setQrCode] = useState("");
    const [orderId, setOrderId] = useState("");
    const [countdown, setCountdown] = useState(60);

    useEffect(() => {
        if (selectedPayment) return;

        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 25000) {
                setPage('Start');
            } else {
                timeoutId = setTimeout(checkInactivity, 25000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 25000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [setPage, selectedPayment]);

    useEffect(() => {
        let timer;
        if (startTimer && selectedPayment === 'CreditCard') {
            timer = setTimeout(() => {
                handleCancelPayment();
            }, 50000);
        } else if (startTimer && selectedPayment === 'AmpersandPay') {
            timer = setTimeout(() => {
                setStartTimer(false);
                setPage("Start");
            }, 70000);
        }
        return () => clearTimeout(timer);
    }, [startTimer, selectedPayment]);

    useEffect(() => {
        if (selectedPayment === 'AmpersandPay' && qrCode) {
            setCountdown(60);

            const countdownInterval = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        clearInterval(countdownInterval);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(countdownInterval);
        }
    }, [selectedPayment, qrCode]);

    const handleCancel = () => {
        setSelectedPayment("");
        setPage('Machines');
    };

    const handleCreditCardSelectpayment = () => {
        setLoading(true);
        setSelectedPayment('CreditCard');
        setStartTimer(true);
        let sendToBackend = sendThis;
        sendToBackend.paywave = true;
        sendToBackend.ampersandpay = false
        axios
            .post(endpoint, sendToBackend)
            .then((res) => {
                setLoading(false);
                setStartTimer(false);
                setPage('ThankYou');
            })
            .catch((err) => {
                setLoading(false);
                setStartTimer(false);
                setSelectedPayment("");
                if (err.response.data.error !== "Unable to make payment") {
                    mySwal.fire(t('Error'), t(err.response.data.error), 'error');
                }
            });
    };

    const handleAmpersandSelectPayment = () => {
        setLoading(true);
        setSelectedPayment('AmpersandPay');
        let sendToBackend = sendThis;
        sendToBackend.ampersandpay = true;
        sendToBackend.paywave = false
        axios
            .post(endpoint, sendToBackend)
            .then((res) => {
                setLoading(false);
                setStartTimer(true);
                setQrCode(res.data.data);
                setOrderId(res.data.transactionId);
            })
            .catch((err) => {
                setLoading(false);
                setSelectedPayment("");
                mySwal.fire(t('Error'), t(err.response.data.error), 'error');
            });
    };

    useEffect(() => {
        let pollingInterval;
        let timer;

        if (selectedPayment === 'AmpersandPay' && qrCode) {
            pollingInterval = setInterval(() => {
                axios.post("/api/kiosk/payment/query", { orderId, token, outletId })
                    .then((response) => {
                        const { status } = response.data;
                        if (status === "success") {
                            clearInterval(pollingInterval);
                            clearTimeout(timer);
                            setPage('ThankYou');
                        } else if (status === "failed") {
                            clearInterval(pollingInterval);
                            clearTimeout(timer);
                            if (response.data.message) {
                                mySwal.fire(t('Error'), t(response.data.message), 'error');
                            }
                            setSelectedPayment("")
                            setQrCode("")
                            setOrderId("")
                        }
                    })
                    .catch((err) => {
                        mySwal.fire(t('Error'), t(err.response.data.error), 'error');
                    });
            }, 5000);

            timer = setTimeout(() => {
                clearInterval(pollingInterval);
            }, 65000);
        }

        return () => {
            clearInterval(pollingInterval);
            clearTimeout(timer);
        };
    }, [selectedPayment, qrCode, orderId]);

    const handleCancelPayment = () => {
        setCancelLoading(true);
        axios
            .post("/api/kiosk/paywave/cancelTransaction", {
                token,
                outletId,
            })
            .then((res) => {
                setLoading(false);
                setCancelLoading(false);
                setSelectedPayment("");
                setStartTimer(false);
                if (res.data.status === "activate machine") {
                    setPage('ThankYou');
                } else {
                    setPage("Start")
                }
            })
            .catch((err) => {
                setCancelLoading(false);
                setPage("Start")
                mySwal.fire(t('Error'), t(err.response.data.error), 'error');
            });
    };

    if (selectedPayment === 'CreditCard' && loading) {
        return (
            <div id="credit-card-screen" className="d-flex flex-column align-items-center" style={{ height: '1920px', width: '1080px', backgroundColor: '#F5F5F5', padding: '40px', position: 'relative' }}>
                <div className="header d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
                    <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: '120px' }} />
                    <div className="d-flex justify-content-between align-items-center" style={{ gap: '20px' }}>
                        <img
                            src={HomeCleanpro}
                            alt='Home Icon'
                            style={{ height: '90px', cursor: 'pointer' }}
                            onClick={() => setPage('Start')}
                        />
                        <img
                            src={FaqCleanpro}
                            alt='FAQ Icon'
                            style={{ height: '90px', cursor: 'pointer' }}
                            onClick={() => setShowFaq(!showFaq)}
                        />
                        <img
                            src={BackLogo}
                            alt='Back Icon'
                            style={{ height: '90px', cursor: 'pointer' }}
                            onClick={() => setPage('Machines')}
                        />
                        {showFaq && (
                            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                                <Faq setPage={setPage} data={data} setShowFaq={setShowFaq} />
                            </div>
                        )}
                    </div>
                </div>

                <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                    {data && (
                        <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                            {data.outlet_fullname}
                        </h2>
                    )}
                </div>

                <h2 style={{ color: '#000', fontSize: '50px', fontWeight: '600', marginTop: "15%" }}>{t('Please tap your card on the reader')}</h2>

                <img
                    src={CardTap}
                    alt='Tap Card'
                    style={{ width: '50%', maxWidth: '500px', height: 'auto', marginBottom: '40px', marginTop: "5%" }}
                />

                {!cancelloading ? (
                    <Button
                        variant="danger"
                        style={{
                            width: '400px',
                            height: '80px',
                            fontSize: '36px',
                            fontWeight: '600',
                            borderRadius: '50px',
                            marginTop: '20px',
                        }}
                        onClick={handleCancelPayment}
                    >
                        {t('Cancel')}
                    </Button>
                ) : (
                    <div className="text-center">
                        <div
                            className="spinner-border"
                            style={{ color: "#004FC1" }}
                            role="status"
                        >
                            <span className="sr-only">Loading ...</span>
                        </div>
                    </div>
                )}
                <img
                    src={DownArrow}
                    alt='Down Arrow'
                    style={{ width: '100px', height: 'auto', marginTop: '70px' }}
                />
            </div>
        );
    }

    if (selectedPayment === 'AmpersandPay' && qrCode) {
        return (
            <div id="ampersand-qr-screen" className="d-flex flex-column align-items-center" style={{ height: '1920px', width: '1080px', backgroundColor: '#F5F5F5', padding: '40px', position: 'relative' }}>
                <div className="header d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
                    <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: '120px' }} />
                    <div className="d-flex justify-content-between align-items-center" style={{ gap: '20px' }}>
                        <img
                            src={HomeCleanpro}
                            alt='Home Icon'
                            style={{ height: '90px', cursor: 'pointer' }}
                            onClick={() => setPage('Start')}
                        />
                        <img
                            src={FaqCleanpro}
                            alt='FAQ Icon'
                            style={{ height: '90px', cursor: 'pointer' }}
                            onClick={() => setShowFaq(!showFaq)}
                        />
                        <img
                            src={BackLogo}
                            alt='Back Icon'
                            style={{ height: '90px', cursor: 'pointer' }}
                            onClick={() => setPage('Machines')}
                        />
                        {showFaq && (
                            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                                <Faq setPage={setPage} data={data} setShowFaq={setShowFaq} />
                            </div>
                        )}
                    </div>
                </div>

                <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                    {data && (
                        <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                            {data.outlet_fullname}
                        </h2>
                    )}
                </div>

                <h2 style={{ color: '#000', fontSize: '50px', fontWeight: '600', marginTop: "15%" }}>{t('Scan the QR code to pay')}</h2>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '50%', maxWidth: '500px', marginBottom: '40px', marginTop: "5%" }}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <div style={{
                            position: 'relative',
                            backgroundColor: '#ED2E67',
                            padding: '16px',
                            borderRadius: '8px',
                            width: '100%'
                        }}>
                            <div style={{
                                backgroundColor: 'white',
                                padding: '32px'
                            }}>
                                <img
                                    src={qrCode}
                                    alt='DuitNow QR Code'
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </div>
                            <div style={{
                                backgroundColor: '#ED2E67',
                                height: '48px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '16px'
                            }}>
                                <p style={{
                                    color: 'white',
                                    fontSize: '26px',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                    margin: 0,
                                    fontFamily: 'Calibri Bold, sans-serif',
                                    height: '16px',
                                    lineHeight: '16px',
                                    textAlign: 'center'
                                }}>
                                    Malaysia National QR
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <h3 style={{ color: '#FF0000', fontSize: '40px', fontWeight: '600' }}>
                    {t("QR Code expires in {{ countdown }} seconds", { countdown })}
                </h3>
            </div>
        );
    }

    return (
        <div id="payment-selection-screen" className="d-flex flex-column align-items-center" style={{ height: '1920px', width: '1080px', backgroundColor: '#E3F2FF', padding: '40px', position: 'relative' }}>
            <div className="header d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
                <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: '120px' }} />
                <div className="d-flex justify-content-between align-items-center" style={{ gap: '20px' }}>
                    <img
                        src={HomeCleanpro}
                        alt='Home Icon'
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Start')}
                    />
                    <img
                        src={FaqCleanpro}
                        alt='FAQ Icon'
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setShowFaq(!showFaq)}
                    />
                    <img
                        src={BackLogo}
                        alt='Back Icon'
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Machines')}
                    />
                    {showFaq && (
                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000 }}>
                            <Faq setPage={setPage} data={data} setShowFaq={setShowFaq} />
                        </div>
                    )}
                </div>
            </div>

            <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                {data && (
                    <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                        {data.outlet_fullname}
                    </h2>
                )}
            </div>

            <h2 style={{ color: '#000', fontSize: '60px', fontWeight: '700', marginTop: "10%" }}>{t('Payment Option')}:</h2>
            {
                loading ? (
                    <div className="text-center">
                        <div
                            className="spinner-border"
                            style={{ color: "#004FC1" }}
                            role="status"
                        >
                            <span className="sr-only">Loading ...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        {machine.paywave && (
                            <div
                                className="payment-option d-flex justify-content-center align-items-center"
                                style={{
                                    width: '800px',
                                    height: '350px',
                                    marginTop: '5%',
                                    backgroundColor: '#FFF',
                                    borderRadius: '20px',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer',
                                    padding: '20px'
                                }}
                                onClick={handleCreditCardSelectpayment}
                            >
                                <div style={{ textAlign: 'center' }}>
                                    <img
                                        src={CreditCard}
                                        alt='Credit Card'
                                        style={{ width: '100%', maxWidth: '600px', height: 'auto', marginBottom: '20px' }}
                                    />
                                    {/* <p style={{ fontSize: '40px', fontWeight: '600', color: '#000', margin: 0 }}>{t('Credit Card/Debit/Visa')}</p> */}
                                </div>
                            </div>
                        )}

                        {machine.ampersandpay && (
                            <div
                                className="payment-option d-flex justify-content-center align-items-center"
                                style={{
                                    width: '800px',
                                    height: '350px',
                                    marginTop: '5%',
                                    backgroundColor: '#FFF',
                                    borderRadius: '20px',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer',
                                    padding: '20px'
                                }}
                                onClick={handleAmpersandSelectPayment}
                            >
                                <div style={{ textAlign: 'center' }}>
                                    <img
                                        src={DuitNowQR}
                                        alt='Ampersand DuitNow QR'
                                        style={{ width: '100%', maxWidth: '600px', height: 'auto', marginBottom: '20px' }}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )
            }

            <Button
                variant="danger"
                style={{
                    width: '400px',
                    height: '100px',
                    fontSize: '36px',
                    fontWeight: '600',
                    borderRadius: '50px',
                    marginTop: '80px',
                }}
                onClick={handleCancel}
            >
                {t('Cancel')}
            </Button>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
        token: state.kiosk.token,
        outletId: state.kiosk.outletId
    };
};

export default connect(mapStateToProps, null)(Payment);
