import React, { Component } from "react";

import { connect } from "react-redux";
import { changeCounter, checkToken, grantVoucher } from "../../../redux/actions/userActions";

import { Redirect } from "react-router-dom";

import queryString from "query-string";

import Navigator from "../utilities/Navigation";
import Dashboard from "./Dashboard";
import Coupon from "./Coupon";
import Transaction from "./Transaction";
import News from "./News";
import Camera from "./Camera";
import Reload from "../utilities/Reload";
import Transfer from "../utilities/Transfer";

import giftGif from "../../../assets/gif/giftGif.gif";
import promotionNews from "../../../assets/images/news/cleanproHariRaya.jpeg";
import sliderNews1 from "../../../assets/images/news/washupRayaModal1.jpg";
import sliderNews2 from "../../../assets/images/news/washupRayaModal2.jpg";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "i18next";

// import { Modal } from "react-bootstrap";
import { Modal, Carousel } from "antd";

import { currency } from "../../../dynamicController";

import { subscribeUser } from "../../../subscription";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const mySwal = withReactContent(Swal);

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			promotionModal: false,
			sliderModal: false,
		};
	}

	_isMounted = false;

	componentDidMount = () => {
		this._isMounted = true;

		if (this._isMounted) {
			this.props.checkToken();

			if (this.props.isAuthenticated) {
				subscribeUser(this.props.user.id);
				const { combinedSetting, combinedStamps, dryerStamp, washerStamp, country } = this.props.user;
				const id = this.props.user.id;

				let sendThis = { id };

				if (STORETYPE === "cleanpro" && country === "Malaysia") {
					this.setState({
						...this.state,
						promotionModal: true
					});
				} else if (STORETYPE === "washup") {
					this.setState({
						...this.state,
						sliderModal: true
					});
				} else if (this.props.user.referral && STORETYPE !== "cleanpro") {
					if (this.props.modalCounter < 3) {
						let counter = this.props.modalCounter;
						counter++;
						this.props.changeCounter(counter);

						const { voucher_setting, token_eligible, token_type, token_amount, stamp_eligible, stamp_combined, stamp_washer, stamp_dryer, point_eligible, point_type, point_amount } = this.props.user.referralSetting
						let message = i18n.t("Inviting friends to get more benefits!")
						let rewards = []

						if (voucher_setting.voucher_eligible) {
							rewards.push(`${voucher_setting.voucher_type === "Flat" ? currency(country) : ""}${voucher_setting.voucher_amount}${voucher_setting.voucher_type === "Rate" ? "%" : ""} ${i18n.t("voucher")}`)
						}

						if (token_eligible && this.props.user.tokenSetting) {
							rewards.push(`${token_amount}${token_type === "Rate" ? "%" : ""} ${i18n.t("token")} ${token_type === "Rate" ? i18n.t("based on transaction payment amount") : ""}`)
						}

						if (stamp_eligible && this.props.user.stamp) {
							if (this.props.user.combinedSetting) {
								rewards.push(`${stamp_combined} ${i18n.t("stamp")}`)
							} else {
								if (this.props.user.specificStamp === "both") {
									rewards.push(`${stamp_washer} ${i18n.t("washer stamp")}, ${stamp_dryer} ${i18n.t("dryer stamp")}`)
								} else if (this.props.user.specificStamp === "washerOnly") {
									rewards.push(`${stamp_washer} ${i18n.t("washer stamp")}`)
								} else if (this.props.user.specificStamp === "dryerOnly") {
									rewards.push(`${stamp_dryer} ${i18n.t("dryer stamp")}`)
								}
							}
						}

						if (point_eligible && this.props.user.loyalty_point) {
							rewards.push(`${point_amount}${point_type === "Rate" ? "%" : ""} ${this.props.user.loyalty_point_name} ${point_type === "Rate" ? i18n.t("based on transaction payment amount") : ""}`)
						}

						if (rewards.length > 0) {
							message = `${i18n.t("Refer a friend and get")} ${rewards.join(", ")}!`
						}

						mySwal
							.fire({
								title: message,
								text: i18n.t("Share the love to your friends & family?"),
								icon: "question",
								showCancelButton: true,
								cancelButtonText: i18n.t("No"),
								confirmButtonText: i18n.t("Yes"),
								confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4",
								customClass: {
									confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-btn share-referral-yes" : STORETYPE === "washup" ? "washup-swal-btn share-referral-yes" : "share-referral-yes",
									cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-secondary-btn share-referral-no" : STORETYPE === "washup" ? "washup-swal-btn share-referral-no" : "share-referral-no",
								}
							})
							.then(click => {
								if (click.isConfirmed && click.value) {
									navigator
										.share({
											title: "Referral Code Sharing",
											text: `Hi, you are invited to register an account with my referral code(${this.props.user.memberId}).`,
											url: `https://${window.location.hostname}/welcome?operatorCode=${this.props.operatorId}&referralCode=${this.props.user.memberId}`
										})
										.then(() => console.log("Successful share"))
										.catch(error => console.log("Error sharing", error));
								}
							});
					}
				}

				if (combinedSetting && combinedStamps >= 10) {
					// check combinedStamps
					sendThis.target = "combined";
					mySwal.fire({
						title: i18n.t("Congratulations!"),
						html: i18n.t("You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed"),
						imageUrl: giftGif,
						imageAlt: "Gift Gif",
						background: "#fbfbfb"
					});
					this.props.grantVoucher(sendThis);
				} else {
					// check washerStamp and dryerStamp
					if (washerStamp >= 10) {
						// give washer voucher
						sendThis.target = "washer";
						mySwal.fire({
							title: i18n.t("Congratulations!"),
							html: i18n.t("You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed"),
							imageUrl: giftGif,
							imageAlt: "Gift Gif",
							background: "#fbfbfb"
						});
						this.props.grantVoucher(sendThis);
					} else if (dryerStamp >= 10) {
						// give dryer voucher
						sendThis.target = "dryer";
						mySwal.fire({
							title: i18n.t("Congratulations!"),
							html: i18n.t("You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed"),
							imageUrl: giftGif,
							imageAlt: "Gift Gif",
							background: "#fbfbfb"
						});
						this.props.grantVoucher(sendThis);
					}
				}
			}
		}
	};

	componentWillUnmount = () => (this._isMounted = false);

	render() {
		const renderPage = () => {
			if (this.props.nav === "stamp") {
				return <Dashboard history={this.props.history} />;
			} else if (this.props.nav === "coupon") {
				return <Coupon />;
			} else if (this.props.nav === "transaction") {
				return <Transaction />;
			} else if (this.props.nav === "news") {
				return <News />;
			} else if (this.props.nav === "camera") {
				return <Camera history={this.props.history} />;
			} else if (this.props.nav === "point") {
				return <Transaction filter="Point" />;
			} else if (this.props.nav === "laundrypass") {
				return <Transaction filter="Subscription" />;
			} else if (this.props.nav === "reload") {
				return <Reload />
			} else if (this.props.nav === "transfer") {
				return <Transfer />
			} else {
				return (
					<div className="text-center">
						<h1>{i18n.t("Something went wrong")} :/</h1>
					</div>
				);
			}
		};

		const { payment } = queryString.parse(this.props.location.search);

		if (this.props.page === "Kiosk") {
			return <Redirect to="/kiosk" />;
		} else if (this.props.page === "Kioskv2") {
			return <Redirect to="/kioskv2thankyou" />;
		} else if (!this.props.isAuthenticated) {
			return <Redirect to={`/welcome${payment && payment === "success" ? this.props.location.search : ""}`} />;
		} else {
			return (
				<div>
					{renderPage()}
					<Navigator />
					{/* 
					<Modal
						show={this.state.promotionModal}
						onHide={(e) => this.setState({ ...this.state, promotionModal: false })}
						centered
						contentClassName="transparent-background"
					>
						<Modal.Body>
							<div className="text-center">
								<img src={promotionNews} alt="" className="img-fluid" />
							</div>
						</Modal.Body>
					</Modal>
					 */}
					<Modal
						open={this.state.promotionModal}
						onCancel={(e) => this.setState({ ...this.state, promotionModal: false })}
						footer={null}
						centered
					>
						<div className="text-center">
							<img src={promotionNews} alt="" className="img-fluid" />
						</div>
					</Modal>
					<Modal
						open={this.state.sliderModal}
						onCancel={(e) => this.setState({ ...this.state, sliderModal: false })}
						footer={null}
						centered
						className="transparent-background"
					>
						<div className="text-center">
							<Carousel autoplay>
								<div>
									<img src={sliderNews1} alt="" className="img-fluid" />
								</div>
								<div>
									<img src={sliderNews2} alt="" className="img-fluid" />
								</div>
							</Carousel>
						</div>
					</Modal>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.user.isAuthenticated,
		user: state.user.user,
		operatorId: state.user.operatorId,
		nav: state.user.nav,
		modalCounter: state.user.modalCounter,
		page: state.kiosk.page,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		checkToken: () => dispatch(checkToken()),
		grantVoucher: data => dispatch(grantVoucher(data)),
		changeCounter: data => dispatch(changeCounter(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
