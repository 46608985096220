import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import axios from "axios";

import paymentWasher from "../../../assets/images/machines/paymentWasher.png";
import paymentDryer from "../../../assets/images/machines/paymentDryer.png";
import vendingMachine from "../../../assets/images/machines/vendingMachine.png";

// cleanpro plus ui
import cpPlusLogo from "../../../assets/images/logo/Logo_Cleanproplus-white.png";
import cpPlusWasher from "../../../assets/images/machines/icn-washer.png";
import cpPlusDryer from "../../../assets/images/machines/icn-dryer.png";
import locationIcon from "../../../assets/images/icons/location.png";
import cpPlusBlueLogo from "../../../assets/images/logo/Logo_Cleanproplus-blue.png";

// washup
import washupLogo from "../../../assets/images/logo/washupLogo1.png";
import washupWasher from "../../../assets/images/machines/washup-washer.png";
import washupDryer from "../../../assets/images/machines/washup-dryer.png";
import menuIcon from "../../../assets/images/icons/icn-hamburger.png";

import promotionImageCh from "../../../assets/images/news/cpLaundryPassLandingCh.jpg";
import promotionImageEn from "../../../assets/images/news/cpLaundryPassLandingEn.jpg";
import promotionImageBm from "../../../assets/images/news/cpLaundryPassLandingBm.jpg";

import WasherPayment from "./payment/WasherPayment";
import DryerPayment from "./payment/DryerPayment";
import VendingMachine from "./payment/VendingMachine";
import Sidebar from "./Sidebar";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { changeNav, changePage, checkToken } from "../../../redux/actions/userActions";
import { scannedCode, saveTransactionId, saveTransactionType } from "../../../redux/actions/paymentActions";
import { decrypt } from "../../../dynamicController";

import { Modal } from "react-bootstrap";

const mySwal = withReactContent(Swal);

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Payment = ({
  payment,
  user,
  history,
  changePage,
  scanned,
  operatorId,
  changeNav,
  saveTransactionId,
  saveTransactionType,
  theme,
  token,
  checkToken,
  language,
}) => {
  const { t } = useTranslation();

  const [toggled, setToggled] = useState(false);
  const [duration, setDuration] = useState(0);
  const [cleanproNonUserModal, setCleanproNonUserModal] = useState(false);
  const [registerVoucherSetting, setRegisterVoucherSetting] = useState(null);
  const [birthdayVoucherSetting, setBirthdayVoucherSetting] = useState(null);
  const [promotionModal, setPromotionModal] = useState(false);

  useEffect(() => {
    // if (!payment.machine) {
    if (payment.outlet.machineNo && operatorId) {
      axios
        .post("/api/user/getMachine", {
          machineNo: payment.outlet.machineNo,
          operatorId,
        })
        .then((res) => {
          const response = decrypt(res.data.data)
          if (response.outletStatus !== "online") {
            mySwal.fire(t("Outlet is currently offline, please try again later"), "", "info");
          }
          if (response.block) {
            mySwal.fire(t("Machine is Out of Service"), "", "info");
          }
          scanned(response);
          setRegisterVoucherSetting(response.voucherSettings.filter(setting => setting.type === "Register")[0])
          setBirthdayVoucherSetting(response.voucherSettings.filter(setting => setting.type === "Birthday")[0])
        })
        .catch((err) => {
          mySwal.fire(t("Error"), t(err.response.data.error), "error");
        });
    } else {
      history.push("/");
    }
    // } else {
    //   setRegisterVoucherSetting(payment.machine.voucherSettings.filter(setting => setting.type === "Register")[0])
    //   setBirthdayVoucherSetting(payment.machine.voucherSettings.filter(setting => setting.type === "Birthday")[0])
    // }

    if (!user) {
      if (token) {
        checkToken()
      } else if (payment.machine?.member_payment) {
        if (STORETYPE === "cleanpro") {
          setCleanproNonUserModal(true)
        }
        //  else if (STORETYPE === "cuci") {
        //   mySwal
        //     .fire({
        //       html: <img src={cuciRaya} alt="" className="img-fluid" />,
        //       showCancelButton: true,
        //       cancelButtonText: t("Maybe Next Time"),
        //       confirmButtonText: t("Yes, Register Now"),
        //       cancelButtonColor: "#d33",
        //       customClass: {
        //         confirmButton: "register-now",
        //         cancelButton: "register-next-time",
        //       },
        //     })
        //     .then((click) => {
        //       if (click.isConfirmed && click.value) {
        //         changePage("register");
        //         history.goBack();
        //       }
        //     });

        //   return function cleanup() {
        //     mySwal.close();
        //   };
        // }
        else {
          mySwal
            .fire({
              title: t("Join as a member now!"),
              html: t("Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>"),
              icon: "info",
              showCancelButton: true,
              cancelButtonText: t("Maybe Next Time"),
              confirmButtonText: t("Yes, Register Now"),
              cancelButtonColor: "#d33",
              customClass: {
                confirmButton: "register-now",
                cancelButton: "register-next-time",
              },
            })
            .then((click) => {
              if (click.isConfirmed && click.value) {
                changePage("register");
                history.goBack();
              }
            });

          return function cleanup() {
            mySwal.close();
          };
        }
      }
    } else if (STORETYPE === "cleanpro" && payment.machine?.country === "Malaysia") {
      setPromotionModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="payment-page">
      <Sidebar toggled={toggled} setToggled={setToggled} />
      <div id="user-header">
        {STORETYPE === "cleanpro" ? (
          <div>
            <div className="d-flex justify-content-between pt-2">
              <img className="plus-logo" src={cpPlusLogo} alt="" />
              {
                user ? (
                  <h5 className="mt-2">
                    <i
                      onClick={(e) => {
                        changeNav("camera");
                        history.push("/")
                      }}
                      className="bi bi-chevron-left me-3"
                    ></i>{" "}
                    {t("Scan QR")}
                  </h5>
                ) : null
              }
            </div>
            <div className="d-flex pb-3">
              <div>
                <img src={locationIcon} alt="" />
              </div>
              <p className="ml-1" style={{ fontSize: "11px", marginTop: "auto" }}>{payment.machine && payment.machine.outlet_fullname ? payment.machine.outlet_fullname : ""}</p>
            </div>
          </div>
        ) : STORETYPE === "washup" ? (
          <div>
            <div className="d-flex justify-content-between">
              <img src={washupLogo} alt="" style={{ width: "200px", objectFit: "contain" }} />
              {
                user ? (
                  <div>
                    <img
                      style={{ width: "28px", objectFit: "contain" }}
                      onClick={(e) => setToggled(true)}
                      src={menuIcon}
                      alt=""
                    />
                  </div>
                ) : null
              }
            </div>
            <div className="d-flex pt-3">
              <h5>
                <i
                  onClick={(e) => history.push("/")}
                  className="bi bi-chevron-left me-3"
                ></i>
                {payment.machine ? t(payment.machine.type + " Payment") : ""}
              </h5>
            </div>
          </div>
        ) : user ? (
          <h5>
            <i
              onClick={(e) => history.push("/")}
              className="bi bi-chevron-left me-3"
            ></i>{" "}
            <strong>
              {payment.machine ? t(payment.machine.type + " Payment") : ""}
            </strong>
          </h5>
        ) : null}

        <div id="machine-info-container">
          <div id="machine-image-container">
            {STORETYPE === "cleanpro" && (
              <>
                {payment.machine ? (
                  <h3>
                    {payment.machine.type === "Washer" ? t("Wash")
                      : payment.machine.type === "Dryer" ? t("Dry")
                        : payment.machine.type === "Vending Machine" ? t("Vending")
                          : payment.machine.type}
                  </h3>
                ) : null}
              </>
            )}
            {STORETYPE === "washup" && (
              <>
                {payment.machine ? (
                  <h5>
                    {payment.machine.type === "Vending Machine" ? t("Vending") : t(payment.machine.type)}
                  </h5>
                ) : null}
              </>
            )}
            {payment.machine ? (
              payment.machine.type === "Washer" ? (
                <img src={STORETYPE === "cleanpro" ? cpPlusWasher : STORETYPE === "washup" ? washupWasher : paymentWasher} alt="washer" className="img-fluid" />
              ) : payment.machine.type === "Dryer" ? (
                <img src={STORETYPE === "cleanpro" ? cpPlusDryer : STORETYPE === "washup" ? washupDryer : paymentDryer} alt="dryer" className="img-fluid" />
              ) : (
                <img src={vendingMachine} alt="dryer" className="img-fluid" />
              )
            ) : null}
          </div>

          <div id="machine-info">
            {payment.machine ? (
              payment.machine.type !== "Washer" && payment.machine.type !== "Dryer" ? (
                <>
                  <h5 className="m-0 p-0">{payment.machine.name}</h5>
                  <h6 className="m-0 p-0">{t(payment.machine.type)}</h6>
                  <div id="badge-container" className="mt-2">
                    <div
                      className="status-badge"
                      style={
                        payment.machine.online && payment.machine.outletStatus === "online"
                          ? { backgroundColor: STORETYPE === "cuci" ? "#ffd109" : STORETYPE === "washup" ? "#CCB300" : "#8be78b" }
                          : { backgroundColor: "#d33" }
                      }
                    >
                      <strong>{payment.machine.online && payment.machine.outletStatus ? t("Online") : t("Offline")}</strong>
                    </div>

                    <div
                      className="status-badge"
                      style={payment.machine.running ? { backgroundColor: "#d33" } : null}
                    >
                      <strong>
                        {payment.machine.running ? t("Running") : t("Vacant")}
                      </strong>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h5 className="m-0 p-0">
                    {STORETYPE !== "cleanpro" && STORETYPE !== "washup" && `${t(payment.machine.type)} -`} {payment.machine.name}
                  </h5>
                  <h6 className="m-0 p-0">
                    {STORETYPE !== "cleanpro" && STORETYPE !== "washup" && payment.machine.capacity}
                  </h6>
                  {
                    STORETYPE === "cleanpro" || STORETYPE === "washup" ? (
                      <div className="mt-2" id="badge-container">
                        <div>
                          <p className="m-0" style={{ fontSize: "12px", color: "#525252" }}>{t("Capacity")}</p>
                          <strong style={{ fontSize: "16px" }}>{payment.machine.capacity}</strong>
                        </div>
                        <div>
                          <p className="m-0" style={{ fontSize: "12px", color: "#525252" }}>{t("Duration")}</p>
                          <strong style={{ fontSize: "16px" }}>
                            {duration} {t("min")}
                          </strong>
                        </div>
                      </div>
                    ) : null
                  }
                  {STORETYPE !== "cleanpro" ? (
                    <div id="badge-container" className="mt-2">
                      <div
                        className="status-badge"
                        style={
                          payment.machine.online &&
                            payment.machine.outletStatus === "online"
                            ? {
                              backgroundColor:
                                STORETYPE === "cuci"
                                  ? "#ffd109"
                                  : STORETYPE === "washup"
                                    ? "#CCB300"
                                    : "#8be78b",
                            }
                            : { backgroundColor: "#d33" }
                        }
                      >
                        <strong>
                          {payment.machine.online &&
                            payment.machine.outletStatus === "online"
                            ? t("Online")
                            : t("Offline")}
                        </strong>
                      </div>

                      <div
                        className="status-badge"
                        style={
                          payment.machine.running
                            ? { backgroundColor: "#d33" }
                            : null
                        }
                      >
                        <strong>
                          {payment.machine.running ? t("Running") : t("Vacant")}
                        </strong>
                      </div>
                    </div>
                  ) : null}
                </>
              )
            ) : null}
          </div>
        </div>
      </div>

      <div id="user-body">
        {payment.machine ? (
          payment.machine.type === "Washer" || payment.machine.type === "Detergent Vending" ? (
            <WasherPayment
              setDuration={setDuration}
              machine={payment.machine}
              outlet={payment.outlet}
              user={user}
              history={history}
              saveTransactionId={saveTransactionId}
              saveTransactionType={saveTransactionType}
              theme={theme}
              token={token}
            />
          ) : payment.machine.type === "Dryer" ? (
            <DryerPayment
              setDuration={setDuration}
              machine={payment.machine}
              outlet={payment.outlet}
              user={user}
              history={history}
              saveTransactionId={saveTransactionId}
              saveTransactionType={saveTransactionType}
              theme={theme}
              token={token}
            />
          ) : (
            <VendingMachine
              machine={payment.machine}
              outlet={payment.outlet}
              user={user}
              history={history}
              saveTransactionId={saveTransactionId}
              saveTransactionType={saveTransactionType}
              theme={theme}
              token={token}
            />
          )
        ) : null}
      </div>

      {
        !payment.machine?.member_payment ? (
          <div><p className="text-center d-flex justify-content-around"><span onClick={() => history.push("/aboutus")}>About Us</span> | <span onClick={() => history.push("/termsConditions")}>Terms & Conditions</span> | <span onClick={() => history.push("/privacyPolicy")}>Privacy Policy</span></p></div>
        ) : null
      }

      <Modal
        show={cleanproNonUserModal}
        onHide={(e) => setCleanproNonUserModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <img src={cpPlusBlueLogo} alt="logo" style={{ width: "80%" }} />
        </Modal.Header>
        <Modal.Body>
          <div className={theme}>
            <p style={{ fontSize: "22px", fontWeight: 500, lineHeight: "120%" }}>{t("Register as a member to enjoy more benefits!")}</p>
            <ul style={{ listStyleType: "square", listStylePosition: "inside", fontSize: "16px", fontWeight: 300 }} className="pt-3">
              {
                registerVoucherSetting && registerVoucherSetting.voucher_eligible && registerVoucherSetting.voucher_type === "Flat"
                  ? <li style={{ listStyleType: "square" }}><Trans i18nKey="Get FREE {{ voucherAmount }} credits per new user" count={registerVoucherSetting.voucher_amount}>Get FREE {{ voucherAmount: registerVoucherSetting.voucher_amount }} credits per new user</Trans></li>
                  : null
              }
              {
                registerVoucherSetting && registerVoucherSetting.voucher_eligible && registerVoucherSetting.voucher_type === "Rate"
                  ? <li style={{ listStyleType: "square" }}><Trans i18nKey="Get FREE {{ voucherAmount }}% per new user">Get FREE {{ voucherAmount: registerVoucherSetting.voucher_amount }}% per new user</Trans></li>
                  : null
              }
              <li style={{ listStyleType: "square" }}>{t("Enjoy exclusive promotions")}</li>
              {
                birthdayVoucherSetting && birthdayVoucherSetting.voucher_eligible
                  ? <li style={{ listStyleType: "square" }}>{t("FREE voucher on your birthday")}</li>
                  : null
              }
              <li style={{ listStyleType: "square" }}>{t("and more")}</li>
            </ul>
            <div className="d-flex justify-content-around">
              <button id="cleanpro-sign-up" onClick={() => { changePage("register"); history.goBack(); }} style={{ fontSize: "16px", fontWeight: 500, width: "35%" }} className="btn big-button">
                {t("Sign up")}
              </button>
              <button id="cleanpro-continue-to-pay" onClick={() => setCleanproNonUserModal(false)} style={{ height: "50px", width: "60%" }} className="btn secondary-button">
                {t("Continue to pay")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={promotionModal}
        onHide={(e) => setPromotionModal(false)}
        centered
      // contentClassName="transparent-background"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className={theme}>
            <div className="text-center">
              <img src={language === "zh" || language === "zhtw" ? promotionImageCh : language === "ms" ? promotionImageBm : promotionImageEn} alt="" className="img-fluid" onClick={() => history.push("/user/news/cleanproLaundryPass")} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    payment: state.payment,
    user: state.user.user,
    operatorId: state.user.operatorId,
    theme: state.theme.theme,
    token: state.user.token,
    language: state.user.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePage: (data) => dispatch(changePage(data)),
    scanned: (data) => dispatch(scannedCode(data)),
    changeNav: (nav) => dispatch(changeNav(nav)),
    saveTransactionId: (data) => dispatch(saveTransactionId(data)),
    saveTransactionType: (data) => dispatch(saveTransactionType(data)),
    checkToken: () => dispatch(checkToken()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
