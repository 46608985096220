import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { dateFormat, timeFormat } from "../../../../../dynamicController";

import io from 'socket.io-client';

import step1 from "../../../../../assets/images/faq/promptPay1.png";
import step2 from "../../../../../assets/images/faq/promptPay2.png";
import step3 from "../../../../../assets/images/faq/promptPay3.png";
import step4 from "../../../../../assets/images/faq/promptPay4.png";
import step5 from "../../../../../assets/images/faq/promptPay5.png";

const PromptPayGuide = () => {

    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const [qrSrc, setQrSrc] = useState("");
    const [isIos/*, setIos*/] = useState(/iPhone|iPad|iPod/i.test(navigator.userAgent))

    useEffect(() => {
        // Bangkokbank Prompt Pay
        if (location.state && location.state.image) {
            setQrSrc(location.state.image)
        }

        if (location.state && location.state.transactionId) {
            const socket = io({ auth: { transactionId: location.state.transactionId } })
            socket.on("duitnowPayment", (transactionId) => {
                history.push("/thankyou")
            })

            return () => {
                socket.disconnect();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="setting-page" className="user-faq">
            <div id="setting-main">
                <div id="user-header">
                    <h5><i onClick={e => history.push("/")} className="bi bi-chevron-left me-3"></i> <strong>{t("PromptPay QR code")}</strong></h5>
                </div>

                <div id="user-body">
                    {
                        qrSrc ? (
                            <div className="pt-3 pb-3">
                                <h4 className="ps-3">{t("Your PromptPay QR code")}:</h4>
                                <div className="text-center">
                                    <img src={qrSrc} alt="promptpay-qr" />
                                </div>
                                <div className="text-center">
                                    <a className="btn btn-primary" href={qrSrc} download={`promptpay_${dateFormat(new Date())}_${timeFormat(new Date())}.png`}>{t("Download")}</a>
                                </div>
                                {isIos ? <p className="text-center px-2 mt-2">{t('For iOS users, press and hold the image, then choose "Add to Photos" or "Save in Photos" from the menu.')}</p> : null}
                            </div>
                        ) : null
                    }

                    <h4 className="pt-3 ps-3">{t("How to pay using PromptPay QR code?")}</h4>

                    <div className="px-3 py-3 d-flex">
                        <div style={{ maxWidth: "30%" }}>
                            <img src={step1} alt="duitnowguide-step1" className="img-fluid" />
                        </div>
                        <div className="align-content-center ps-2">
                            <h5>01 {t("Download")}</h5>
                            <p>{t("Download or takes a screenshot of the QR code.")}</p>
                        </div>
                    </div>

                    <div className="px-3 py-3 d-flex">
                        <div style={{ maxWidth: "30%" }}>
                            <img src={step2} alt="guide-step2" className="img-fluid" />
                        </div>
                        <div className="align-content-center ps-2">
                            <h5>02 {t("Launch")}</h5>
                            <p>{t("Launch and login banking app.")}</p>
                        </div>
                    </div>

                    <div className="px-3 py-3 d-flex">
                        <div style={{ maxWidth: "30%" }}>
                            <img src={step3} alt="guide-step3" className="img-fluid" />
                        </div>
                        <div className="align-content-center ps-2">
                            <h5>03 {t("Scan QR code from Gallery")}</h5>
                            <p>{t("Choose QR code from your gallery.")}</p>
                        </div>
                    </div>

                    <div className="px-3 py-3 d-flex">
                        <div style={{ maxWidth: "30%" }}>
                            <img src={step4} alt="guide-step4" className="img-fluid" />
                        </div>
                        <div className="align-content-center ps-2">
                            <h5>04 {t("Pay")}</h5>
                            <p>{t("Confirm payment details and pay.")}</p>
                        </div>
                    </div>

                    <div className="px-3 py-3 d-flex">
                        <div style={{ maxWidth: "30%" }}>
                            <img src={step5} alt="guide-step4" className="img-fluid" />
                        </div>
                        <div className="align-content-center ps-2">
                            <h5>05 {t("Confirmation")}</h5>
                            <p>{t("Receive payment confirmation.")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromptPayGuide;