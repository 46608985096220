import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import WasherLogo from '../../../../assets/images/logo/WasherLogo.png';
import VendingLogo from '../../../../assets/images/logo/VendingLogo.png';
import DryerLogo from '../../../../assets/images/logo/DryerLogo.png';
import { logo1Controller } from '../../../../dynamicController';

const SelectService = ({ onMachineTypeSelect, setPage, data, user, themeLogo }) => {
    const { t } = useTranslation();

    const [hasWasher, setHasWasher] = useState(false);
    const [hasDryer, setHasDryer] = useState(false);
    const [hasVending, setHasVending] = useState(false);

    useEffect(() => {
        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 25000) {
                setPage('Start');
            } else {
                timeoutId = setTimeout(checkInactivity, 25000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 25000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [setPage]);

    useEffect(() => {
        if (data) {
            setHasWasher(data.machineData.some(machine => machine.type === 'Washer'));
            setHasDryer(data.machineData.some(machine => machine.type === 'Dryer'));
            setHasVending(data.machineData.some(machine => machine.type === 'Vending'));
        }
    }, [data]);

    return (
        <div id="select-service-screens" className="d-flex flex-column justify-content-between align-items-center secondscreen vh-100">
            <header className="d-flex flex-column w-100 p-3">
                <div className="d-flex justify-content-between align-items-start w-100">
                    <div className="d-flex flex-column align-items-start">
                        <div className="d-flex align-items-center">
                            <img
                                src={logo1Controller(themeLogo)}
                                alt="main logo"
                                className="img-fluid"
                                style={{ maxWidth: '200px', maxHeight: '70px' }}
                            />
                        </div>
                        {data && (
                            <h2 className="fs-4 fw-bold text-color mt-2 text-start w-100">
                                {data.outlet_fullname}
                            </h2>
                        )}
                    </div>
                    <div className="d-flex" style={{ marginTop: '0' }}>
                        <i
                            className="bi bi-house-fill me-3 text-color"
                            style={{ height: '50px', fontSize: '50px', cursor: 'pointer' }}
                            onClick={() => setPage('Start')}
                        ></i>
                        <i
                            className="bi bi-arrow-left-circle-fill me-3 text-color"
                            style={{ fontSize: '50px', cursor: 'pointer' }}
                            onClick={() => setPage('Option')}
                        ></i>
                    </div>
                </div>
            </header>

            <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1 w-100">
                <h2 className="text-color fw-bold text-center mb-4">
                    {t("Welcome")}{user?.name ? `, ${user.name}` : ""}
                </h2>
                <h3 className="text-dark text-center fw-bold mb-4 text-color">
                    {t("Please choose your service")}
                </h3>

                {hasWasher && (
                    <button
                        className="btn btn-primary d-flex justify-content-between align-items-center rounded-pill px-5 mb-3"
                        style={{ width: '50%', height: '100px', backgroundColor: '#007bff', outline: 'none', border: 'none' }}
                        onClick={() => onMachineTypeSelect('Washer')}
                    >
                        <span className="fw-bold fs-4">{t("WASHER")}</span>
                        <img src={WasherLogo} alt="Washer Logo" className="img-fluid" style={{ height: '50px' }} />
                    </button>
                )}

                {hasDryer && (
                    <button
                        className="btn btn-primary d-flex justify-content-between align-items-center rounded-pill px-5 mb-3"
                        style={{ width: '50%', height: '100px', backgroundColor: '#ff69b4', outline: 'none', border: 'none' }}
                        onClick={() => onMachineTypeSelect('Dryer')}
                    >
                        <span className="fw-bold fs-4">{t("DRYER")}</span>
                        <img src={DryerLogo} alt="Dryer Logo" className="img-fluid" style={{ height: '50px' }} />
                    </button>
                )}

                {hasVending && (
                    <button
                        className="btn btn-primary d-flex justify-content-between align-items-center rounded-pill px-5"
                        style={{ width: '50%', height: '100px', backgroundColor: '#d3d3d3', outline: 'none', border: 'none' }}
                        onClick={() => onMachineTypeSelect('Vending Machine')}
                    >
                        <span className="fw-bold fs-4">{t("VENDING MACHINE")}</span>
                        <img src={VendingLogo} alt="Vending Logo" className="img-fluid" style={{ height: '50px' }} />
                    </button>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
        themeLogo: state.theme.logo
    };
};

export default connect(mapStateToProps, null)(SelectService);