import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProgressBar } from 'react-bootstrap';
import { changeNav } from "../../../../redux/actions/userActions";

// cleanpro icon
import dryerIcon from "../../../../assets/images/machines/icn-dryer.png";
import washerIcon from "../../../../assets/images/machines/icn-washer.png";
import cpLaundryPassCh from "../../../../assets/images/news/cpLaundryPassLandingCh.jpg";
import cpLaundryPassEn from "../../../../assets/images/news/cpLaundryPassLandingEn.jpg";
import cpLaundryPassBm from "../../../../assets/images/news/cpLaundryPassLandingBm.jpg";
import cpExpired from "../../../../assets/images/logo/cpExpired.png";

// washup icon
import washupWasher from "../../../../assets/images/machines/washup-washer.png";
import washupDryer from "../../../../assets/images/machines/washup-dryer.png";

// laundro icon
import laundroWasher from "../../../../assets/images/machines/paymentWasher.png";
import laundroDryer from "../../../../assets/images/machines/paymentDryer.png";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const LaundryPass = ({ language, user, changeNav }) => {

    const history = useHistory();
    const { t } = useTranslation();

    const [expired, setExpired] = useState(false)
    // const [finished, setFinished] = useState(false)
    const [lastSubscription, setLastSubscription] = useState(null)

    useEffect(() => {
        if (user.subscriptionHistory.length > 0) {
            const lastSuccessSubscription = user.subscriptionHistory.filter(subHistory => subHistory.status !== "Failed").sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
            if (lastSuccessSubscription) {
                setLastSubscription(lastSuccessSubscription)
                const expiredLaundryPass = lastSuccessSubscription?.subscriptions.filter(sub => sub.status === "Expired")[0]
                if (expiredLaundryPass) {
                    setExpired(true)
                }
                // if (user.subscriptions.length <= 0) {
                //     setFinished(true)
                // }
            }
        }
    }, [user])

    return (
        <div>
            {
                user.subscriptions.length > 0 ? (
                    <div className={STORETYPE === "cleanpro" ? "raya-component-cp" : STORETYPE === "washup" || STORETYPE === "cleanpro" ? "stamp-component-cp" : "shadow px-5 py-4 mt-4 mb-3"} onClick={() => changeNav("laundrypass")}>
                        <div className="d-flex">
                            <div>
                                <p style={{ fontSize: "18px", fontWeight: 500 }}>{t("Laundry Pass")}</p>
                                {lastSubscription ? <p>{lastSubscription.name}</p> : null}
                                {user.subscriptions[0].expired_date ? <p style={{ color: STORETYPE === "cleanpro" ? "#016146" : "black" }}><b>{t("Expired on")} {new Date(user.subscriptions[0].expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</b></p> : null}
                            </div>
                        </div>

                        <div>
                            {
                                user.currentSubscription[0].wash > 0 ? (
                                    <div className="py-3">
                                        <div className="d-flex">
                                            <img src={STORETYPE === "cleanpro" ? washerIcon : STORETYPE === "washup" ? washupWasher : laundroWasher} style={{ width: "40px", padding: "3px" }} alt="" />
                                            <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")} <span style={{ fontSize: "14px" }}>{STORETYPE === "cleanpro" ? t("(Only applicable to machines below 25kg)") : ""}</span></p>
                                            <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.currentSubscription[0].available_wash}/{user.currentSubscription[0].wash}</p>
                                        </div>
                                        {
                                            STORETYPE === "washup" || STORETYPE === "cleanpro"
                                                ? <progress id="progress-bar" value={user.currentSubscription[0].available_wash} max={user.currentSubscription[0].wash}></progress>
                                                : <ProgressBar now={(parseInt(user.currentSubscription[0].available_wash) / parseInt(user.currentSubscription[0].wash)) * 100} />
                                        }
                                    </div>
                                ) : null
                            }
                            {
                                user.currentSubscription[0].dry > 0 ? (
                                    <div className="py-3">
                                        <div className="d-flex">
                                            <img src={STORETYPE === "cleanpro" ? dryerIcon : STORETYPE === "washup" ? washupDryer : laundroDryer} style={{ width: "40px" }} alt="" />
                                            <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Dry")} <span style={{ fontSize: "14px" }}>{STORETYPE === "cleanpro" ? t("(Only applicable to machines below 25kg)") : ""}</span></p>
                                            <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.currentSubscription[0].available_dry}/{user.currentSubscription[0].dry}</p>
                                        </div>
                                        {
                                            STORETYPE === "washup" || STORETYPE === "cleanpro"
                                                ? <progress id="progress-bar" value={user.currentSubscription[0].available_dry} max={user.currentSubscription[0].dry}></progress>
                                                : <ProgressBar now={(parseInt(user.currentSubscription[0].available_dry) / parseInt(user.currentSubscription[0].dry)) * 100} />
                                        }
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                ) : expired ? (
                    <div className={STORETYPE === "cleanpro" ? "cleanpro-laundrypass-expired" : STORETYPE === "washup" ? "stamp-component-cp" : "shadow px-5 py-4 mt-4 mb-3"}>
                        <div className={STORETYPE !== "cleanpro" ? "d-flex" : ""}>
                            <div onClick={() => changeNav("laundrypass")}>
                                {STORETYPE === "cleanpro" ? <div className="text-center mb-2"><img src={cpExpired} alt="" style={{ maxWidth: "35%" }} /></div> : null}
                                {STORETYPE !== "cleanpro" && lastSubscription ? <p style={{ fontSize: "18px", fontWeight: 500 }}>{lastSubscription.name}</p> : null}
                                <p className={STORETYPE === "cleanpro" ? "text-center" : ""}><b>{t("Your Laundry Pass expired on")} {new Date(lastSubscription.subscriptions[0].expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</b></p>
                            </div>
                            <div className="d-flex align-items-center justify-content-center mt-2">
                                <button
                                    onClick={(e) => history.push("/user/subscription")}
                                    className="m-0 font-weight-bold btn big-button"
                                >
                                    {t("Purchase Again")}
                                </button>
                            </div>
                        </div>
                    </div>
                ) : STORETYPE === "cleanpro" && user.country === "Malaysia" ? (
                    <div style={{ padding: "5vw" }} onClick={() => history.push("/user/news/cleanproLaundryPass")}>
                        <img src={language === "zh" || language === "zhtw" ? cpLaundryPassCh : language === "ms" ? cpLaundryPassBm : cpLaundryPassEn} alt="laundrypass" />
                    </div>
                ) : null
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        language: state.user.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeNav: (nav) => dispatch(changeNav(nav)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaundryPass);