import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import { Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import io from 'socket.io-client';

import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Loading from "../../admin/components/Loading";
import { currency } from '../../../dynamicController';

import { useTranslation } from "react-i18next";

const mySwal = withReactContent(Swal);

const VoucherCodeGenerator = ({ token, distributor }) => {

    const history = useHistory();

    const { t } = useTranslation();

    const [toggled, setToggled] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [description, setDescription] = useState("");
    const [expired_date, setExpiredDate] = useState("");
    const [new_user, setNewUser] = useState(false);
    const [redeem_per_user, setRedeemPerUser] = useState(1);
    const [number_of_code, setNumberOfCode] = useState(1);
    const [pay_and_use, setPayAndUse] = useState(false);
    const [pay_and_use_amount, setPayAndUseAmount] = useState(1);
    const [voucher_setting, setVoucherSetting] = useState([
        {
            type: "Voucher Code",
            voucher_eligible: true,
            voucher_amount: 3,
            voucher_type: "Flat",
            voucher_expiry: true,
            voucher_duration: 30,
            voucher_title: "VOUCHER CODE REWARDS",
            voucher_description: "Thank you for redeem the voucher code!",
            voucher_washer_use: true,
            voucher_dryer_use: true,
            voucher_package_use: false,
            recurring: true,
            recurring_number: 1,
            recurring_period: 30
        }
    ]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!distributor || !distributor.voucher_code) {
            history.push('/distributor')
        } else {
            axios
                .post("/api/admin/setting/getCodeGenerationHistory", { id: distributor.distributorId, role: "distributor", type: "Voucher Code" }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data);
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    mySwal.fire(t("Error"), t(err.response.data.error), "error");
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (data.length > 0) {
            const pendingCode = data.filter((d) => d.status === "Pending")
            if (pendingCode.length > 0) {
                const socket = io({ auth: { transactionId: pendingCode[0].id } })
                socket.on("generatedVoucherCode", (voucherCodeHistory) => {
                    const newData = data.map(d => {
                        if (d.id === pendingCode[0].id) {
                            d.status = voucherCodeHistory.status
                            return d
                        } else {
                            return d
                        }
                    })
                    setData(newData)
                })

                return () => {
                    socket.disconnect();
                };
            }
        }
    }, [data])

    const handleToggle = () => {
        setToggled(!toggled);
    }

    const openModal = () => {
        setDescription("");
        setExpiredDate("");
        setNumberOfCode(1);
        setNewUser(false);
        setRedeemPerUser(1);
        setPayAndUse(false);
        setPayAndUseAmount(1);
        setVoucherSetting([
            {
                type: "Voucher Code",
                voucher_eligible: true,
                voucher_amount: 3,
                voucher_type: "Flat",
                voucher_expiry: true,
                voucher_duration: 30,
                voucher_title: "VOUCHER CODE REWARDS",
                voucher_description: "Thank you for redeem the voucher code!",
                voucher_washer_use: true,
                voucher_dryer_use: true,
                voucher_package_use: false,
                recurring: true,
                recurring_number: 1,
                recurring_period: 30
            }
        ]);
        setShowModal(true);
    }

    const hideModal = () => {
        setDescription("");
        setExpiredDate("");
        setNumberOfCode(1);
        setNewUser(false);
        setRedeemPerUser(1);
        setPayAndUse(false);
        setPayAndUseAmount(1);
        setVoucherSetting([
            {
                type: "Voucher Code",
                voucher_eligible: true,
                voucher_amount: 3,
                voucher_type: "Flat",
                voucher_expiry: true,
                voucher_duration: 30,
                voucher_title: "VOUCHER CODE REWARDS",
                voucher_description: "Thank you for redeem the voucher code!",
                voucher_washer_use: true,
                voucher_dryer_use: true,
                voucher_package_use: false,
                recurring: true,
                recurring_number: 1,
                recurring_period: 30
            }
        ]);
        setShowModal(false);
    }

    const removeFields = (index) => {
        let list = [...voucher_setting]
        list.splice(index, 1)
        setVoucherSetting(list)
    }

    const addFields = () => {
        setVoucherSetting(
            [
                ...voucher_setting,
                {
                    type: "Voucher Code",
                    voucher_eligible: true,
                    voucher_amount: 3,
                    voucher_type: "Flat",
                    voucher_expiry: true,
                    voucher_duration: 30,
                    voucher_title: "VOUCHER CODE REWARDS",
                    voucher_description: "Thank you for redeem the voucher code!",
                    voucher_washer_use: true,
                    voucher_dryer_use: true,
                    voucher_package_use: false,
                    recurring: true,
                    recurring_number: 1,
                    recurring_period: 30
                }
            ]
        )
    }

    const generateVoucherCode = (e) => {
        e.preventDefault()

        const sendThis = {
            id: distributor.distributorId,
            role: "distributor",
            expired_date,
            number_of_code,
            pay_and_use,
            pay_and_use_amount,
            voucher_setting,
            new_user,
            redeem_per_user,
            description
        }

        axios
            .post("/api/admin/setting/generateVoucherCode", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
            .then(res => {

                axios
                    .post("/api/admin/setting/getCodeGenerationHistory", { id: distributor.distributorId, role: "distributor", type: "Voucher Code" }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                    .then(res => {
                        setData(res.data);
                        setLoading(false);
                        setShowModal(false);
                        mySwal.fire(t("Success"), t("Your voucher code is generating"), "success")
                    })
                    .catch(err => {
                        setLoading(false);
                        mySwal.fire(t("Error"), t(err.response.data.error), "error");
                    });

            })
            .catch(err => {
                setLoading(false);
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    }

    const downloadVoucherCode = (id) => {
        axios
            .post("/api/admin/setting/downloadUniqueVoucherCode", { id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
            .then(res => {
                window.location = res.data.url;
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
            });
    }

    let tableData = {
        columns: [
            {
                label: "ID",
                field: "id",
                sort: "asc"
            },
            {
                label: t("Created Date"),
                field: "createdAt",
                sort: "asc"
            },
            {
                label: t("Name"),
                field: "description",
                sort: "asc"
            },
            {
                label: t("Number Of Voucher Code"),
                field: "number_of_code",
                sort: "asc"
            },
            {
                label: t("Expired Date"),
                field: "expired_date",
                sort: "asc"
            },
            {
                label: t("New User Only"),
                field: "new_user",
                sort: "asc"
            },
            {
                label: t("Redeem Per User"),
                field: "redeem_per_user",
                sort: "asc"
            },
            {
                label: t("Pay And Get Voucher"),
                field: "pay_and_use",
                sort: "asc"
            },
            {
                label: `${t("Minimum Transaction Amount To Use Voucher")} (${currency(distributor?.country)})`,
                field: "pay_and_use_amount",
                sort: "asc"
            },
            {
                label: t("Status"),
                field: "status",
                sort: "asc"
            },
            {
                label: t("Action"),
                field: "action"
            }
        ],
        rows: []
    };

    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            const dataObject = {
                id: data[i].id,
                createdAt: data[i].createdAt,
                description: data[i].description,
                number_of_code: data[i].number_of_code,
                expired_date: data[i].expired_date,
                new_user: data[i].new_user,
                redeem_per_user: data[i].redeem_per_user,
                pay_and_use: data[i].pay_and_use,
                pay_and_use_amount: data[i].pay_and_use_amount,
                status: t(data[i].status),
                action:
                    data[i].status === "Pending"
                        ? <div className="text-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                        : data[i].status === "Completed"
                            ? <button type="button" className="btn btn-primary" onClick={() => downloadVoucherCode(data[i].id)}>
                                {t("Download")}
                            </button> : null

            }
            tableData.rows.push(dataObject);
        }
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />

            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">
                    <div className="card">
                        <div className="card-header d-flex justify-content-between">
                            <h5 className="card-title">{t("Voucher Code")}</h5>

                            <button className="btn btn-primary" type="button" onClick={() => openModal()}>
                                {t("Generate Voucher Code")}
                            </button>
                        </div>

                        <div className="card-body">
                            <div>
                                {
                                    loading
                                        ? <Loading />
                                        : <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Modal
                show={showModal}
                onHide={e => hideModal()}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Generate Voucher Code")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={generateVoucherCode}>
                            <p><b>{t("Voucher Code Settings")}</b></p>
                            <div style={{ margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                <div className="form-group">
                                    <label htmlFor="new_user">
                                        <input type="checkbox" id="new_user" name="new_user" checked={new_user} onChange={e => setNewUser(!new_user)} />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    {t("If ticked, the voucher code only can redeem in register page")}
                                                </Tooltip>
                                            }
                                        >
                                            <span>{t("New User Only")}</span>
                                        </OverlayTrigger>
                                    </label>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="pay_and_use">
                                        <input type="checkbox" id="pay_and_use" name="pay_and_use" checked={pay_and_use} onChange={e => setPayAndUse(!pay_and_use)} />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    {t("If ticked, the user only can use the voucher after make a transaction")}
                                                </Tooltip>
                                            }
                                        >
                                            <span>{t("Pay And Get Voucher")}</span>
                                        </OverlayTrigger>
                                    </label>
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="description">{t("Name")}</label>
                                <input type="text" id="description" name="description" value={description} onChange={e => setDescription(e.target.value)} className="form-control browser-default" required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="number_of_code">{t("Number Of Voucher Code")}</label>
                                <input type="number" id="number_of_code" name="number_of_code" value={number_of_code} onChange={e => setNumberOfCode(e.target.value)} className="form-control browser-default" required step="1" min="1" max="50000" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="expired_date">{t("Expired Date Of Voucher Code")}</label>
                                <input type="date" id="expired_date" name="expired_date" value={expired_date} onChange={e => setExpiredDate(e.target.value)} className="form-control browser-default" required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="redeem_per_user">{t("Max redemption per user in this batch voucher code")}</label>
                                <input type="number" id="redeem_per_user" name="redeem_per_user" value={redeem_per_user} onChange={e => setRedeemPerUser(e.target.value)} className="form-control browser-default" required step="1" />
                            </div>

                            {
                                pay_and_use ? (
                                    <div className="form-group">
                                        <label htmlFor="pay_and_use_amount">{t("Minimum Transaction Amount To Use Voucher")}:({currency(distributor?.country)})</label>
                                        <input type="number" id="pay_and_use_amount" name="pay_and_use_amount" value={pay_and_use_amount} onChange={e => setPayAndUseAmount(e.target.value)} className="form-control browser-default" required step="0.01" />
                                    </div>
                                ) : null
                            }

                            <p className="pt-3"><b>{t("Voucher Settings")}</b></p>

                            {
                                voucher_setting.map((setting, i) => {
                                    return (
                                        <div key={i}>
                                            <p>{t("Voucher")} {i + 1}</p>
                                            <div style={{ margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                <div className="form-group">
                                                    <label>
                                                        <input type="checkbox" id="voucher_expiry" name="voucher_expiry" checked={setting.voucher_expiry} onChange={e => {
                                                            let list = [...voucher_setting]
                                                            list[i].voucher_expiry = !list[i].voucher_expiry
                                                            setVoucherSetting(list)
                                                        }} />
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    {t("If ticked, the voucher will have expired date")}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span>{t("Expiry")}</span>
                                                        </OverlayTrigger>
                                                    </label>
                                                </div>

                                                <div className="form-group">
                                                    <label>
                                                        <input type="checkbox" id="voucher_package_use" name="voucher_package_use" checked={setting.voucher_package_use} onChange={e => {
                                                            let list = [...voucher_setting]
                                                            list[i].voucher_package_use = !list[i].voucher_package_use
                                                            setVoucherSetting(list)
                                                        }} />
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    {t("If ticked, the voucher can apply to Drop n Go transaction")}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span>{t("Package Use")}</span>
                                                        </OverlayTrigger>
                                                    </label>
                                                </div>
                                            </div>

                                            <div style={{ margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                                <div className="form-group">
                                                    <label>
                                                        <input type="checkbox" id="voucher_washer_use" name="voucher_washer_use" checked={setting.voucher_washer_use} onChange={e => {
                                                            let list = [...voucher_setting]
                                                            list[i].voucher_washer_use = !list[i].voucher_washer_use
                                                            setVoucherSetting(list)
                                                        }} />
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    {t("If ticked, the voucher can apply to washer machine transaction")}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span>{t("Washer Use")}</span>
                                                        </OverlayTrigger>
                                                    </label>
                                                </div>

                                                <div className="form-group">
                                                    <label>
                                                        <input type="checkbox" id="voucher_dryer_use" name="voucher_dryer_use" checked={setting.voucher_dryer_use} onChange={e => {
                                                            let list = [...voucher_setting]
                                                            list[i].voucher_dryer_use = !list[i].voucher_dryer_use
                                                            setVoucherSetting(list)
                                                        }} />
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip>
                                                                    {t("If ticked, the voucher can apply to dryer machine transaction")}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span>{t("Dryer Use")}</span>
                                                        </OverlayTrigger>
                                                    </label>
                                                </div>
                                            </div>

                                            {
                                                setting.voucher_expiry ? (
                                                    <div className="form-group">
                                                        <label htmlFor="voucher_duration">{t("Voucher Lifespan")} : <small> ({t("days")})</small></label>
                                                        <input type="number" id="voucher_duration" name="voucher_duration" value={setting.voucher_duration} required min="1" step="1" onChange={e => {
                                                            let list = [...voucher_setting]
                                                            list[i].voucher_duration = e.target.value
                                                            setVoucherSetting(list)
                                                        }} />
                                                    </div>
                                                ) : null
                                            }

                                            <div className="form-group">
                                                <label htmlFor="voucher_title">{t("Voucher Title")} : </label>
                                                <input type="text" id="voucher_title" name="voucher_title" placeholder={t("Voucher Title")} value={setting.voucher_title} required onChange={e => {
                                                    let list = [...voucher_setting]
                                                    list[i].voucher_title = e.target.value
                                                    setVoucherSetting(list)
                                                }} />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="voucher_description">{t("Voucher Description")} : </label>
                                                <input type="text" id="voucher_description" name="voucher_description" placeholder={t("Voucher Description")} value={setting.voucher_description} required onChange={e => {
                                                    let list = [...voucher_setting]
                                                    list[i].voucher_description = e.target.value
                                                    setVoucherSetting(list)
                                                }} />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="voucher_type">{t("Discount Type")} : </label>
                                                <select className="browser-default form-select" id="voucher_type" name="voucher_type" value={setting.voucher_type} required onChange={e => {
                                                    let list = [...voucher_setting]
                                                    list[i].voucher_type = e.target.value
                                                    setVoucherSetting(list)
                                                }}>
                                                    <option value="" disabled>{t("Select a discount type")}</option>
                                                    <option value="Flat">{t("Flat")} (- {currency(distributor?.country)})</option>
                                                    <option value="Rate">{t("Rate")} (- %)</option>
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="voucher_amount">{t("Voucher Amount")} : <small>{setting.voucher_type === "Flat" ? `(${currency(distributor?.country)})` : "(%)"}</small></label>
                                                <input type="number" id="voucher_amount" name="voucher_amount" value={setting.voucher_amount} required min="1" step="0.01" onChange={e => {
                                                    let list = [...voucher_setting]
                                                    list[i].voucher_amount = e.target.value
                                                    setVoucherSetting(list)
                                                }} />
                                            </div>

                                            {
                                                i < voucher_setting.length - 1 ? (
                                                    <div className="form-group">
                                                        <label htmlFor="recurring_period">{t("Period for giving next voucher")} : <small> ({t("days")})</small></label>
                                                        <input type="number" id="recurring_period" name="recurring_period" value={setting.recurring_period} min="1" step="1" required onChange={e => {
                                                            let list = [...voucher_setting]
                                                            list[i].recurring_period = e.target.value
                                                            setVoucherSetting(list)
                                                        }} />
                                                    </div>
                                                ) : null
                                            }

                                            <div className="form-group text-center">
                                                <button type="button" className="btn btn-secondary" onClick={(e) => removeFields(i)}>
                                                    {t("Remove")}
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="form-group pt-3">
                                <button type="button" className="btn btn-dark" onClick={(e) => addFields()}>
                                    {t("Add Voucher")}
                                </button>
                            </div>

                            <div className="text-center pt-3">
                                {loading ? (
                                    <div className="text-center">
                                        <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : <input type="submit" value={t("Generate Voucher Code")} className="btn btn-primary mx-2" />}
                                <button type="button" className="btn btn-secondary" onClick={e => hideModal()}>
                                    {t("Close")}
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(VoucherCodeGenerator);