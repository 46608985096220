import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import UserUsageReportCard from "../../../admin/components/report/UserUsageReportCard";

const UserUsageReport = ({ token, client }) => {

    const history = useHistory();

    const [toggled, setToggled] = useState(false);

    useEffect(() => {
        if (!client || !client.user_usage_report) {
            history.push('/client')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = () => {
        setToggled(!toggled);
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />
            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />
                <div className="admin-content">

                    <UserUsageReportCard
                        role="client"
                        token={token}
                        operators={client.admin}
                        distributors={client.distributor}
                        outlets={client.outlet}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(UserUsageReport);
