import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import YesButton from '../../../../assets/images/logo/YesButton.png';
import NoButton from '../../../../assets/images/logo/NoButton.png';
import axios from 'axios';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from 'react-i18next';
import { logo1Controller } from '../../../../dynamicController';

const mySwal = withReactContent(Swal);

const Register = ({ setPage, data, outletId, token, setUser, themeLogo }) => {
    const { t } = useTranslation();
    const [mobileNumber, setMobileNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    useEffect(() => {
        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 55000) {
                setPage('Start');
            } else {
                timeoutId = setTimeout(checkInactivity, 55000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 55000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [setPage]);

    const handleSubmit = (event) => {
        event.preventDefault();

        const numberRegex = /^\d+$/;
        const emailRegex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/

        if (!mobileNumber) {
            mySwal.fire(t("Error"), t("Please enter phone number / email address"), 'error');
        } else if (numberRegex.test(mobileNumber) && data.country === "Taiwan" && (!numberRegex.test(mobileNumber) || mobileNumber.length !== 10 || mobileNumber.substring(0, 2) !== "09")) {
            mySwal.fire({
                title: t("Error"),
                icon: "error",
                html: t("Please enter the correct phone number format. Example: 09xxxxxxxx"),
            });
        } else if (numberRegex.test(mobileNumber) && data.country === "Malaysia" && (!numberRegex.test(mobileNumber) || mobileNumber.length <= 9 || mobileNumber.length >= 12 || mobileNumber.substring(0, 2) !== "01")) {
            mySwal.fire({
                title: t("Error"),
                icon: "error",
                html: t("Please enter the correct phone number format. Example: 01xxxxxxxx"),
            });
        } else if (mobileNumber.includes("@") && !emailRegex.test(mobileNumber)) {
            mySwal.fire(t("Error"), t("Invalid Email Address"), 'error');
        } else if (!mobileNumber.includes("@") && !numberRegex.test(mobileNumber)) {
            mySwal.fire(t("Error"), t("Invalid phone number / email address"), 'error');
        } else {
            setShowOverlay(true);
        }
    };

    const handleYes = () => {
        setLoading(true);

        axios.post('/api/kiosk/userRegister', {
            token,
            outletId,
            number: mobileNumber,
        })
            .then(response => {
                setLoading(false);
                setUser(response.data.data.user);
                setPage('SelectService');
                if (response.data.status === "UNVERIFIED") {
                    mySwal.fire(t('Notice'), t('User exists but not verified. Please verify your account by scanning the QR Code at Thank You Page.'), 'warning');
                } else if (response.data.status === "REGISTERED") {
                    mySwal.fire(t('Success'), t('User registered successfully. Please complete verification by scanning the QR Code at Thank You Page.'), 'success');
                }
            })
            .catch(err => {
                setLoading(false);
                setShowOverlay(false);
                mySwal.fire(t('Error'), t(err.response.data.error), 'error');
            });
    };

    return (
        <div id="member-login-screens" className="d-flex flex-column justify-content-between align-items-center secondscreen text-color vh-100">
            <header className="d-flex flex-column w-100 p-3">
                <div className="d-flex justify-content-between align-items-start w-100">
                    <div className="d-flex flex-column align-items-start">
                        <div className="d-flex align-items-center">
                            <img
                                src={logo1Controller(themeLogo)}
                                alt="main logo"
                                className="img-fluid"
                                style={{ maxWidth: '200px', maxHeight: '70px' }}
                            />
                        </div>
                        {data && (
                            <h2 className="fs-4 fw-bold text-color mt-2 w-100">
                                {data.outlet_fullname}
                            </h2>
                        )}
                    </div>
                    <div className="d-flex" style={{ marginTop: '0' }}>
                        <i
                            className="bi bi-house-fill me-3 text-color"
                            style={{ height: '50px', fontSize: '50px', cursor: 'pointer' }}
                            onClick={() => setPage('Start')}
                        ></i>
                    </div>
                </div>
            </header>

            <div className="content text-center d-flex flex-column justify-content-center align-items-center vh-100">
                <img
                    src={logo1Controller(themeLogo)}
                    alt="main logo"
                    className="img-fluid"
                    style={{ height: '70px' }}
                />
                <h4 className="mb-5 text-color">{t('Please enter your mobile phone number or e-mail')}</h4>
                <p className="text-danger mt-2" style={{ fontSize: "18px", fontWeight: "500" }}>
                    {t("We’ll send you an SMS when your laundry’s ready")}
                </p>
                <Form onSubmit={handleSubmit} className="d-flex flex-column align-items-center w-100">
                    <Form.Group controlId="mobileNumber" className="mb-4 w-100">
                        <Form.Control
                            type="text"
                            inputMode="text"
                            required
                            autoComplete="off"
                            placeholder={t('Enter Your Phone Number / Email Address')}
                            className="p-3 text-center rounded-pill border-0 bg-white text-dark"
                            style={{ width: "700px" }}
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                        />
                    </Form.Group>
                    <div className="buttons d-flex justify-content-center gap-8 w-100">
                        <div className="buttons d-flex justify-content-center gap-4 w-100">
                            <button
                                type="submit"
                                className="btn btn-primary kiosk-button rounded-pill px-4 py-2"
                                style={{ fontSize: '26px', fontWeight: '600', width: '180px', height: '60px' }}
                            >
                                {t('Confirm')}
                            </button>

                            <button
                                type="button"
                                className="btn btn-secondary kiosk-button rounded-pill px-4 py-2"
                                onClick={() => setPage('Start')}
                                style={{ fontSize: '26px', fontWeight: '600', width: '180px', height: '60px' }}
                            >
                                {t('Back')}
                            </button>
                            {data?.non_member_payment && (
                                <button type="button" className="btn btn-danger rounded-pill px-4 py-2" onClick={() => {
                                    setUser(null);
                                    setPage('SelectService');
                                }} style={{ fontSize: '26px', fontWeight: '600', width: '180px', height: '60px' }}>
                                    {t('Skip')}
                                </button>
                            )}
                        </div>
                    </div>
                </Form>
            </div>

            {showOverlay && (
                <div className="d-flex flex-column justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100 bg-white bg-opacity-75" style={{ zIndex: 1050 }}>
                    <h2 className="text-dark mb-4" style={{ fontSize: '2rem', fontWeight: 'bold' }}>
                        {mobileNumber.includes("@") ? t('Please verify your e-mail') : t('Please verify your number')}
                    </h2>
                    <h1 className="text-dark mb-5" style={{ fontSize: '2rem', fontWeight: '500' }}>{mobileNumber}</h1>

                    <div className="d-flex justify-content-center gap-4">
                        <div className="position-relative" style={{ width: '150px', height: '50px', }}>
                            {loading ? (
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">{t('Loading')}</span>
                                </div>
                            ) : (
                                <>
                                    <img
                                        src={YesButton}
                                        alt="Yes Button"
                                        className="img-fluid"
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleYes}
                                    />
                                    <span
                                        className="position-absolute top-50 start-50 translate-middle text-color"
                                        style={{ fontSize: '1.5rem', fontWeight: '600', pointerEvents: 'none' }}
                                    >
                                        {t('Yes')}
                                    </span>
                                </>
                            )}
                        </div>

                        <div className="position-relative" style={{ width: '150px', height: '50px' }}>
                            <img
                                src={NoButton}
                                alt="No Button"
                                className="img-fluid"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowOverlay(false)}
                            />
                            <span
                                className="position-absolute top-50 start-50 translate-middle text-color"
                                style={{ fontSize: '1.5rem', fontWeight: '600', pointerEvents: 'none' }}
                            >
                                {t('Cancel')}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
        outletId: state.kiosk.outletId,
        token: state.kiosk.token,
        themeLogo: state.theme.logo
    };
};

export default connect(mapStateToProps, null)(Register);