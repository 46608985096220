import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from 'mdbreact';
import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import { DatePicker, Select } from "antd";
import { dateFormat, timeFormat } from "../../../../dynamicController";
import Loading from "../Loading";

const { RangePicker } = DatePicker;

const mySwal = withReactContent(Swal);

const LaundryPassUsageReportCard = ({ role, token, operators, distributors }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [summaryData, setSummaryData] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [operatorId, setOperatorId] = useState(null)
    const [distributorId, setDistributorId] = useState(null)
    const [operatorOption, setOperatorOpion] = useState([])
    const [distributorOption, setDistributorOption] = useState([])

    useEffect(() => {
        if (role === "client") {
            if (operators) {
                let operatorList = []
                for (let i = 0; i < operators.length; i++) {
                    let operatorObject = {
                        value: operators[i].id,
                        label: operators[i].username
                    }
                    operatorList.push(operatorObject)
                }
                setOperatorOpion(operatorList)
            }
            if (distributors) {
                let distributorList = []
                for (let i = 0; i < distributors.length; i++) {
                    let distributorObject = {
                        value: distributors[i].id,
                        label: distributors[i].name
                    }
                    distributorList.push(distributorObject)
                }
                setDistributorOption(distributorList)
            }
        } else {
            setLoading(true)
            axios
                .post("/api/admin/report/laundryPassUsageReport", { startDate, endDate }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setSummaryData(res.data.summary)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDate = (dates, dateStrings) => {
        if (dates) {
            setStartDate(dateStrings[0])
            setEndDate(dateStrings[1])
        } else {
            setStartDate("")
            setEndDate("")
        }
    };

    const handleReset = () => {
        setStartDate("")
        setEndDate("")
        setOperatorId(null)
        setDistributorId(null)
        setData([])
        setSummaryData([])
        if (role !== "client") {
            setLoading(true)
            axios
                .post("/api/admin/report/laundryPassUsageReport", {}, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setSummaryData(res.data.summary)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleFilter = () => {
        if (role === "client" && !distributorId && !operatorId) {
            mySwal.fire(t("Error"), t("Please select distributor or operator"), "error")
        } else {
            setLoading(true)
            axios
                .post("/api/admin/report/laundryPassUsageReport", { startDate, endDate, operatorId, distributorId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setSummaryData(res.data.summary)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const handleChangeDistributor = (value) => {
        setDistributorId(value)
        setOperatorId(null)
    }

    const handleChangeOperator = (value) => {
        setOperatorId(value)
        setDistributorId(null)
    }

    let summaryTableData = {
        columns: [
            {
                label: t("Name"),
                field: "name",
                sort: "asc"
            },
            {
                label: t("Description"),
                field: "description",
                sort: "asc"
            },
            {
                label: t("Available"),
                field: "available",
                sort: "asc"
            },
            {
                label: t("Used"),
                field: "used",
                sort: "asc"
            },
            {
                label: t("Expired"),
                field: "expired",
                sort: "asc"
            },
            {
                label: t("Cancel"),
                field: "cancel",
                sort: "asc"
            },
        ],
        rows: summaryData
    }

    let tableData = {
        columns: [
            {
                label: t("User Name"),
                field: "username",
                sort: "asc"
            },
            {
                label: t("Phone Number"),
                field: "number",
                sort: "asc"
            },
            {
                label: t("Email Address"),
                field: "email",
                sort: "asc"
            },
            {
                label: t("Member ID"),
                field: "member_id",
                sort: "asc"
            },
            {
                label: t("Name"),
                field: "name",
                sort: "asc"
            },
            {
                label: t("Description"),
                field: "description",
                sort: "asc"
            },
            {
                label: t("Purchase Date"),
                field: "purchase_date",
                sort: "asc"
            },
            {
                label: t("Transaction Date"),
                field: "transaction_date",
                sort: "asc"
            },
            {
                label: t("Machine"),
                field: "machine",
                sort: "asc"
            },
            {
                label: t("Outlet"),
                field: "outlet",
                sort: "asc"
            },
        ],
        rows: data
    }

    const csvData = {
        headers: [
            { label: "User Name", key: "username" },
            { label: "Phone Number", key: "number" },
            { label: "Email Address", key: "email" },
            { label: "Member ID", key: "member_id" },
            { label: "Name", key: "name" },
            { label: "Description", key: "description" },
            { label: "Purchase Date", key: "purchase_date" },
            { label: "Transaction Date", key: "transaction_date" },
            { label: "Machine", key: "machine" },
            { label: "Outlet", key: "outlet" },
        ],
        data
    }

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title">{t("Laundry Pass Usage Report")}</h5>
            </div>

            <div className="card-body">
                <div className="mb-5">
                    <h6>{t("Filter")}</h6>
                    {
                        role === "client" ? (
                            <div className="mb-3">
                                <label htmlFor="distributorId">{t("Select Distributor")}: </label>
                                <Select
                                    id="distributorId"
                                    name="distributorId"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Distributor")}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleChangeDistributor(value)}
                                    options={distributorOption}
                                    value={distributorId}
                                />
                            </div>
                        ) : null
                    }

                    {
                        role === "client" ? (
                            <div className="mb-3">
                                <label htmlFor="operatorId">{t("Select Operator")}: </label>
                                <Select
                                    id="operatorId"
                                    name="operatorId"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder={t("Select Operator")}
                                    showSearch
                                    optionFilterProp="label"
                                    onChange={(value) => handleChangeOperator(value)}
                                    options={operatorOption}
                                    value={operatorId}
                                />
                            </div>
                        ) : null
                    }

                    <div className="mb-3">
                        <label htmlFor="date">{t("Select Purchase Date")}: </label>
                        <RangePicker
                            id="date"
                            name="date"
                            placeholder={[t("Start date"), t("End date")]}
                            onChange={handleDate}
                            style={{ width: '100%' }}
                            value={startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : []}
                            presets={[
                                { label: t("Today"), value: [dayjs(), dayjs()] },
                                { label: t("Yesterday"), value: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')] },
                                { label: t("Last 7 Days"), value: [dayjs().subtract(6, 'day'), dayjs()] },
                                { label: t("Last 30 Days"), value: [dayjs().subtract(29, 'day'), dayjs()] },
                                { label: t("This Month"), value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                                { label: t("Last Month"), value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] }
                            ]}
                        />
                    </div>

                    <div className="d-flex">
                        <button onClick={() => handleFilter()} className="btn btn-primary btn-inline form-control me-3">
                            {t("Filter")}
                        </button>
                        <button onClick={() => handleReset()} className="btn btn-secondary btn-inline form-control ms-3">
                            {t("Reset")}
                        </button>
                    </div>
                </div>

                <div className="text-center mt-5">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <MDBDataTable data={summaryTableData} noBottomColumns striped hover responsive bordered />

                                <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                    <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename={`laundrypass_purchase_report_${dateFormat(new Date())}_${timeFormat(new Date())}.csv`} target="_blank">
                                        {t("Download Report")}
                                    </CSVLink>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default LaundryPassUsageReportCard;