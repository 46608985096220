import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { useTranslation, Trans } from "react-i18next";
import { currency } from "../../../../dynamicController";
import { Modal } from "antd";

import insufficientToken from "../../../../assets/images/icons/icn-creditInsufficient.png";
import CleanproVending from "./Vending/CleanproVending";
import LaundroVending from "./Vending/LaundroVending";

const mySwal = withReactContent(Swal);
const STORETYPE = process.env.REACT_APP_STORETYPE;

const VendingMachine = ({ machine, user, history, outlet, theme, token, saveTransactionId, saveTransactionType }) => {
	const [amount, setAmount] = useState(machine.minPrice ? machine.minPrice : STORETYPE === "cuci" ? 2 : machine.country === "Thailand" ? 10 : 1);
	const [razerBody, setRazerBody] = useState(null);
	const [ghlBody, setGhlBody] = useState(null);
	const [loading, setLoading] = useState(false)
	const [paymentMethodModal, setPaymentMethodModal] = useState(false)
	const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false)
	const [paymentConfirmationText, setPaymentConfirmationText] = useState("")
	const [insufficientTokenModal, setInsufficientTokenModal] = useState(false)
	const [paymentMethod, setPaymentMethod] = useState("")

	const razerButtonClick = useRef();
	const ghlButtonClick = useRef();

	const { t } = useTranslation();

	useEffect(() => {
		if (razerBody) {
			razerButtonClick.current.click();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [razerBody]);

	useEffect(() => {
		if (ghlBody) {
			ghlButtonClick.current.click();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ghlBody]);

	const minusAmount = () => {
		const step = parseFloat(machine.step || 1);
		const newAmount = amount - step;
		if (newAmount >= (machine.minPrice || step)) setAmount(newAmount);
	};

	const addAmount = () => {
		const step = parseFloat(machine.step || 1);
		const newAmount = amount + step;
		if (newAmount <= (machine.maxPrice || 100)) setAmount(newAmount);
	};

	const handleSubmit = () => {
		if (amount && amount > 0) {
			if (machine.online && machine.outletStatus === "online") {
				setPaymentMethodModal(true)
			} else {
				mySwal.fire(t("Error"), t("This machine is currently offline, please try again later."), "error")
			}
		} else {
			mySwal.fire(t("Error"), t("Payment amount should be greater than 0"), "error")
		}
	};

	const handlePaymentMethod = (method) => {
		if (method === "eToken" && (parseFloat(user.token) < parseFloat(amount))) {
			setInsufficientTokenModal(true)
			setPaymentMethodModal(false)
		} else {
			setPaymentMethod(method)
			if (method === "eToken") {
				setPaymentConfirmationText(<p className="text-center">{t("You have")} <strong>{user.token}</strong> {STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />{t("Pay")} <strong>{parseFloat(amount).toFixed(2)}</strong> {STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} {("to")} <strong>{machine.name}</strong>?</p>)
			} else if (method === "duitnow") {
				setPaymentConfirmationText(<p className="text-center"><Trans i18nKey="The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency }}{{ totalPaymentAmount }} to {{ machineName }}?">The Duitnow QR code will be downloaded and you need to open your banking app to make the payment. Pay {{ currency: currency(machine.country) }}{{ totalPaymentAmount: parseFloat(amount).toFixed(2) }} to {{ machineName: machine.name }}?</Trans></p>)
			} else {
				setPaymentConfirmationText(<p className="text-center">{t("Pay")} {currency(machine.country)} {parseFloat(amount).toFixed(2)} {t("to")} {machine.name}?</p>)
			}
			setPaymentMethodModal(false)
			setPaymentConfirmationModal(true)
		}
	}

	const confirmPayment = () => {
		let sendThis = {
			amount: parseFloat(amount).toFixed(2),
			paymentAmount: parseFloat(amount).toFixed(2),
			machine,
			outlet,
		};

		let endPoint = "/api/nonuser/payment";

		let headers = {
			"Content-Type": "application/json"
		}

		if (user) {
			sendThis.memberId = user.memberId;
			endPoint = "/api/user/payment";
			headers = {
				"Content-Type": "application/json",
				"auth-token": token
			}
		}

		if (paymentMethod === "eToken") {
			sendThis.token = true
		} else if (paymentMethod === "duitnow") {
			if (machine.duitnow) {
				sendThis.duitnow = true
			} else if (machine.rhbduitnow) {
				sendThis.rhbduitnow = true
			}
		} else if (paymentMethod === "paywave") {
			sendThis.terminal = true
		} else if (paymentMethod === "promptpay") {
			sendThis.bangkokbank = true
		}

		if (paymentMethod === "eWallet" && machine.outletType === "pagbank" && parseFloat(amount) > 0) {
			let data = {
				country: machine.country,
				description: machine.name,
				paymentAmount: parseFloat(amount).toFixed(2),
				user,
				endPoint,
				sendThis,
			}
			history.push("/payment/pagbank", data)
		} else {
			setLoading(true)
			axios
				.post(endPoint, sendThis, { headers })
				.then(res => {
					saveTransactionId(res.data.transactionId)
					saveTransactionType("Transaction")
					setPaymentConfirmationModal(false)
					setLoading(false)
					if (res.data.status === "rm") {
						window.location.href = res.data.data;
					} else if (res.data.status === "laundro") {
						history.push("/thankyou");
					} else if (res.data.status === "razer") {
						setRazerBody(res.data.data)
					} else if (res.data.status === "duitnow") {
						history.push("/guide/duitnow", { url: res.data.data, transactionId: res.data.transactionId })
					} else if (res.data.status === "ghl") {
						setGhlBody(res.data.data)
					} else if (res.data.status === "terminal") {
						history.push("/guide/terminal", { transactionId: res.data.transactionId })
					} else if (res.data.status === "rhbduitnow") {
						history.push("/guide/duitnow", { image: `data:image/png;base64,${res.data.data.data}`, transactionId: res.data.transactionId })
					} else if (res.data.status === "bangkokbank") {
						history.push("/guide/promptpay", { image: res.data.data, transactionId: res.data.transactionId })
					}
				})
				.catch(err => {
					setLoading(false)
					mySwal.fire(t("Error"), t(err.response.data.error), "error")
				})
		}
	}

	return (
		<div id="vending-machine" className="mt-4">
			{
				STORETYPE === "cleanpro"
					? <CleanproVending
						amount={amount}
						setAmount={setAmount}
						machine={machine}
						minusAmount={minusAmount}
						addAmount={addAmount}
						handleSubmit={handleSubmit}
						user={user}
					/>
					: <LaundroVending
						amount={amount}
						setAmount={setAmount}
						machine={machine}
						minusAmount={minusAmount}
						addAmount={addAmount}
						handleSubmit={handleSubmit}
					/>
			}

			{
				razerBody ? (
					<div>
						<form action={razerBody.paymentDomain} method="post">
							<input type="hidden" name="amount" value={razerBody.amount} />
							<input type="hidden" name="merchant_id" value={razerBody.merchant_id} />
							<input type="hidden" name="orderid" value={razerBody.orderid} />
							{razerBody.bill_name ? <input type="hidden" name="bill_name" value={razerBody.bill_name} /> : null}
							{razerBody.bill_email ? <input type="hidden" name="bill_email" value={razerBody.bill_email} /> : null}
							{razerBody.bill_mobile ? <input type="hidden" name="bill_mobile" value={razerBody.bill_mobile} /> : null}
							<input type="hidden" name="bill_desc" value={razerBody.bill_desc} />
							<input type="hidden" name="country" value={razerBody.country} />
							<input type="hidden" name="vcode" value={razerBody.vcode} />
							<input type="hidden" name="currency" value={razerBody.currency} />
							<input type="hidden" name="returnurl" value={razerBody.returnurl} />
							<input type="hidden" name="callbackurl" value={razerBody.callbackurl} />
							<input type="hidden" name="cancelurl" value={razerBody.cancelurl} />

							<input type="submit" name="submit" value="Pay with razer" hidden ref={razerButtonClick} />
						</form>
					</div>
				) : null
			}

			{
				ghlBody ? (
					<div>
						<form name="frmPayment" method="post" action={ghlBody.frmPayment}>
							<input type="hidden" name="TransactionType" value={ghlBody.TransactionType} />
							<input type="hidden" name="PymtMethod" value={ghlBody.PymtMethod} />
							<input type="hidden" name="ServiceID" value={ghlBody.ServiceID} />
							<input type="hidden" name="PaymentID" value={ghlBody.PaymentID} />
							<input type="hidden" name="OrderNumber" value={ghlBody.OrderNumber} />
							<input type="hidden" name="PaymentDesc" value={ghlBody.PaymentDesc} />
							<input type="hidden" name="MerchantReturnURL" value={ghlBody.MerchantReturnURL} />
							{ghlBody.MerchantCallBackURL ? <input type="hidden" name="MerchantCallBackURL" value={ghlBody.MerchantCallBackURL} /> : null}
							<input type="hidden" name="Amount" value={ghlBody.Amount} />
							<input type="hidden" name="CurrencyCode" value={ghlBody.CurrencyCode} />
							<input type="hidden" name="CustIP" value={ghlBody.CustIP} />
							<input type="hidden" name="CustName" value={ghlBody.CustName} />
							<input type="hidden" name="CustEmail" value={ghlBody.CustEmail} />
							<input type="hidden" name="CustPhone" value={ghlBody.CustPhone} />
							<input type="hidden" name="HashValue" value={ghlBody.HashValue} />
							<input type="hidden" name="PageTimeout" value={ghlBody.PageTimeout} />

							<input type="submit" name="submit" value="Pay with GHL" hidden ref={ghlButtonClick} />
						</form>

					</div>
				) : null
			}
			<Modal
				open={insufficientTokenModal}
				onCancel={(e) => setInsufficientTokenModal(false)}
				footer={null}
				closable={false}
				centered
			>
				<div className={theme}>
					{
						STORETYPE === "cleanpro" ? (
							<img
								className="d-block pb-3"
								src={insufficientToken}
								style={{ width: "64px", margin: "0 auto" }}
								alt=""
							/>
						) : null
					}
					<h2 className="text-center pb-2">{STORETYPE === "cleanpro" ? t("Insufficient balance") : t("Insufficient e-Tokens")}</h2>
					{STORETYPE === "cleanpro" ? <p className="text-center" style={{ fontSize: "16px", fontWeight: 300 }}>{t("Oops. You don't have enough balance to make the payment")}</p> : <p className="text-center">{t("Reload your e-token now?")}</p>}
					<div className={`d-flex pt-3 justify-content-center ${STORETYPE === "cleanpro" ? "flex-row-reverse" : ""}`}>
						<button id="cleanpro-topup-now" onClick={() => history.push("/user/reload")} className="btn btn-primary cleanpro-swal-btn washup-swal-btn mx-1 reload-token" style={{ backgroundColor: STORETYPE === "cleanpro" ? "#004FC1" : STORETYPE === "washup" ? "#004c64" : "#2778c4" }}>
							{STORETYPE === "cleanpro" ? t("Topup now") : t("Reload now!")}
						</button>
						<button id="cleanpro-cancel-topup" onClick={() => setInsufficientTokenModal(false)} className="btn btn-secondary cleanpro-swal-secondary-btn washup-swal-btn mx-1 cancel-reload-token">
							{STORETYPE === "cleanpro" ? t("Back") : t("Cancel")}
						</button>
					</div>
				</div>
			</Modal>
			<Modal
				open={paymentMethodModal}
				onCancel={(e) => setPaymentMethodModal(false)}
				footer={null}
				closable={false}
				centered
			>
				<div className={theme}>
					<h2 className="text-center pb-2">{STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method")}</h2>
					<p className="text-center">{STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?")}</p>
					<div className="pt-3 d-flex flex-wrap justify-content-center">
						{
							user && user.tokenSetting ? (
								<button
									type="button"
									className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn e-wallet mx-1 my-1"
									style={{ backgroundColor: STORETYPE === "washup" ? "#CFA82C" : "#2778c4" }}
									onClick={(e) => handlePaymentMethod("eToken")}
								>
									{STORETYPE === "cleanpro" ? t("Cleanpro Plus credits") : t("e-Token")}
								</button>
							) : null
						}
						{
							machine.epayment ? (
								<button
									type="button"
									className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn e-token mx-1 my-1"
									style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#67b668" }}
									onClick={(e) => handlePaymentMethod("eWallet")}
								>
									{STORETYPE === "cleanpro" ? `${t("E-wallet or credit/debit cards")} >` : t("E-Wallet")}
								</button>
							) : null
						}
						{
							machine.duitnow || machine.rhbduitnow ? (
								<button
									type="button"
									className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn duitnow mx-1 my-1"
									style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#67b668" }}
									onClick={(e) => handlePaymentMethod("duitnow")}
								>
									{t("Duitnow QR code")}
								</button>
							) : null
						}
						{
							machine.terminal ? (
								<button
									type="button"
									className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn terminal mx-1 my-1"
									style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#67b668" }}
									onClick={(e) => handlePaymentMethod("paywave")}
								>
									{t("Paywave")}
								</button>
							) : null
						}
						{
							machine.bangkokbank ? (
								<button
									type="button"
									className="btn btn-primary cleanpro-swal-big-primary-btn washup-swal-btn promptpay mx-1 my-1"
									style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#2778c4" }}
									onClick={(e) => handlePaymentMethod("promptpay")}
								>
									{t("PromptPay QR Code")}
								</button>
							) : null
						}
						<button
							type="button"
							className="btn btn-secondary cleanpro-swal-big-secondary-btn washup-swal-btn cancel-payment mx-1 my-1"
							style={{ backgroundColor: STORETYPE === "cleanpro" ? "#004FC1" : "#6C757D" }}
							onClick={(e) => setPaymentMethodModal(false)}
						>
							{t("Cancel")}
						</button>
					</div>
				</div>
			</Modal>
			<Modal
				open={paymentConfirmationModal}
				onCancel={(e) => setPaymentConfirmationModal(false)}
				footer={null}
				closable={false}
				centered
				maskClosable={false}
			>
				<div className={theme}>
					<div className="text-center">
						<i className="bi bi-question-circle" style={{ color: "#87adbd", fontSize: "70px" }}></i>
					</div>
					<h2 className="text-center pb-2">{t("Confirmation")}</h2>
					{paymentConfirmationText ? <div>{paymentConfirmationText}</div> : null}
					<div className={`d-flex pt-3 justify-content-center ${STORETYPE === "cleanpro" ? "flex-row-reverse" : ""}`}>
						{
							loading ? (
								<div className="text-center">
									<div
										className={`spinner-border text-${STORETYPE === "cuci" ? "warning" : "primary"}`}
										role="status"
									>
										<span className="sr-only">Loading...</span>
									</div>
								</div>
							) : (
								<>
									<button
										type="button"
										className="btn btn-primary cleanpro-swal-btn washup-swal-btn mx-1"
										style={{ backgroundColor: STORETYPE === "washup" ? "#004c64" : "#2778c4" }}
										onClick={(e) => confirmPayment()}
									>
										{STORETYPE === "cleanpro" ? t("Pay now") : t("OK")}
									</button>

									<button
										type="button"
										className="btn btn-secondary cleanpro-swal-secondary-btn washup-swal-btn mx-1"
										onClick={(e) => setPaymentConfirmationModal(false)}
									>
										{STORETYPE === "cleanpro" ? t("Cancel") : t("No")}
									</button>
								</>
							)
						}
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default VendingMachine;
