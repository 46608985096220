import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { carelineNumber } from '../../../../dynamicController';

import FaqCleanpro from '../../../../assets/images/logo/FaqCleanpro.png';
import HomeCleanpro from '../../../../assets/images/logo/HomeCleanpro.png';
import HeaderLogoCleanpro from '../../../../assets/images/logo/HeaderLogoCleanpro.png';
import BackLogo from '../../../../assets/images/logo/BackLogo.png';
import FaqLogo from '../../../../assets/images/logo/FaqCleanpro.png';
// import './Responsive.css';

const Faq = ({ setPage, data, setShowFaq }) => {

    const { t } = useTranslation();

    useEffect(() => {
        let timeoutId;
        let lastInteractionTime = Date.now();

        const checkInactivity = () => {
            const currentTime = Date.now();
            if (currentTime - lastInteractionTime > 25000) {
                setPage('Start');
            } else {
                timeoutId = setTimeout(checkInactivity, 25000 - (currentTime - lastInteractionTime));
            }
        };

        const handleUserInteraction = () => {
            lastInteractionTime = Date.now();
        };

        timeoutId = setTimeout(checkInactivity, 25000);

        window.addEventListener('mousemove', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);
        window.addEventListener('touchstart', handleUserInteraction);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('mousemove', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
            window.removeEventListener('touchstart', handleUserInteraction);
        };
    }, [setPage]);

    return (
        <div
            id="faq-page"
            className="d-flex flex-column justify-content-between align-items-center"
            style={{ height: "1920px", width: "1080px", backgroundColor: "#FFFFFF", padding: "40px" }}
        >
            <div className="header d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                <img src={HeaderLogoCleanpro} alt="Cleanpro Plus Header Logo" style={{ height: "120px" }} />
                <div className="d-flex justify-content-between align-items-center" style={{ gap: "20px" }}>
                    <img
                        src={HomeCleanpro}
                        alt='Home Icon'
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setPage('Start')}
                    />
                    <img
                        src={FaqCleanpro}
                        alt='FAQ Icon'
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setShowFaq(false)}
                    />
                    <img
                        src={BackLogo}
                        alt='Back Icon'
                        style={{ height: '90px', cursor: 'pointer' }}
                        onClick={() => setShowFaq(false)}
                    />
                </div>
            </div>

            <div style={{ width: '100%', textAlign: 'left', marginTop: '10px' }}>
                {data && (
                    <h2 style={{ fontSize: '40px', fontWeight: '800', color: '#004FC1' }}>
                        {data.outlet_fullname}
                    </h2>
                )}
            </div>

            <div
                className="content d-flex flex-column justify-content-center align-items-center"
                style={{ flex: 1, textAlign: 'center' }}
            >
                <img
                    src={FaqLogo}
                    alt='FAQ Logo'
                    style={{ height: '200px', marginBottom: '20px' }}
                />

                <h1 className="text-primary" style={{ fontSize: '90px', fontWeight: '600', marginTop: '20px' }}>
                    {t('Need Help?')}
                </h1>
                <h4 className="text-dark" style={{ fontSize: '50px', fontWeight: '500', marginTop: '40px' }}>
                    {t('24-Hour Careline Number')}
                </h4>
                <h4 className="text-dark" style={{ fontSize: '50px', fontWeight: '500', marginTop: '10px' }}>
                    {carelineNumber(data.country)}
                </h4>
            </div>

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.kiosk.data,
    };
};

export default connect(mapStateToProps, null)(Faq);
