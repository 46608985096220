import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MDBDataTable } from "mdbreact";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Select, Modal, DatePicker, Switch, Tag, Input } from "antd";
import dayjs from 'dayjs';

import { currency, dateFormat } from "../../../../dynamicController";
import Loading from "../Loading";

const { RangePicker } = DatePicker;

const mySwal = withReactContent(Swal);

const LaundryPassCard = ({ id, role, token, country, outlets }) => {

    const { t } = useTranslation();

    const weightInputRef = useRef()
    const modeInputRef = useRef()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [packageId, setPackageId] = useState("")
    const [price, setPrice] = useState(0)
    const [wash, setWash] = useState(0)
    const [dry, setDry] = useState(0)
    const [purchase_limit, setPurchaseLimit] = useState(false)
    const [max_purchase, setMaxPurchase] = useState(1)
    const [start_date, setStartDate] = useState("")
    const [end_date, setEndDate] = useState("")
    const [expiry, setExpiry] = useState(true)
    const [duration, setDuration] = useState(30)
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [use_per_day, setUsePerDay] = useState(1)
    const [outletOption, setOutletOption] = useState([])
    const [outletId, setOutletId] = useState([])
    const [outletApplicable, setOutletApplicable] = useState(false)
    const [mode, setMode] = useState([])
    const [modeApplicable, setModeApplicable] = useState(false)
    const [showModeInput, setShowModeInput] = useState(false)
    const [modeInput, setModeInput] = useState("")
    const [weight, setWeight] = useState([])
    const [weightApplicable, setWeightApplicable] = useState(false)
    const [showWeightInput, setShowWeightInput] = useState(false)
    const [weightInput, setWeightInput] = useState("")
    const [discounted_price, setDiscountedPrice] = useState(0)
    const [logo, setLogo] = useState("")
    const [logoUrl, setLogoUrl] = useState("")

    useEffect(() => {
        if (showWeightInput) {
            weightInputRef.current?.focus();
        }
    }, [showWeightInput]);

    useEffect(() => {
        if (showModeInput) {
            modeInputRef.current?.focus();
        }
    }, [showModeInput]);

    useEffect(() => {
        if (outlets) {
            let outletList = []
            for (let i = 0; i < outlets.length; i++) {
                let outletObject = {
                    value: outlets[i].id,
                    label: outlets[i].outlet_fullname
                }
                outletList.push(outletObject)
            }
            setOutletOption(outletList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outlets])

    useEffect(() => {
        if (id && role) {
            setLoading(true)
            axios
                .post("/api/admin/setting/getSubscription", { role, id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                .then(res => {
                    setData(res.data.data)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, role])

    const handleDate = (dates, dateStrings) => {
        if (dates) {
            setStartDate(dateStrings[0])
            setEndDate(dateStrings[1])
        } else {
            setStartDate("")
            setEndDate("")
        }
    };

    const handleWeightInput = () => {
        if (weightInput && !weight.includes(weightInput)) {
            setWeight([...weight, weightInput]);
        }
        setShowWeightInput(false);
        setWeightInput("");
    };

    const handleRemoveWeight = (removeWeight) => {
        const newWeight = weight.filter((w) => w !== removeWeight);
        setWeight(newWeight);
    };

    const handleModeInput = () => {
        if (modeInput && !mode.includes(modeInput)) {
            setMode([...mode, modeInput]);
        }
        setShowModeInput(false);
        setModeInput("");
    };

    const handleRemoveMode = (removeMode) => {
        const newMode = mode.filter((m) => m !== removeMode);
        setMode(newMode);
    };

    const createPackageModal = () => {
        setPackageId("")
        setPrice(0)
        setWash(0)
        setDry(0)
        setPurchaseLimit(false)
        setMaxPurchase(1)
        setStartDate("")
        setEndDate("")
        setExpiry(true)
        setDuration(30)
        setName("")
        setDescription("")
        setUsePerDay(1)
        setOutletId([])
        setOutletApplicable(false)
        setMode([])
        setModeApplicable(false)
        setWeight([])
        setWeightApplicable(false)
        setShowModal(true)
        setDiscountedPrice(0)
        setLogo("")
        setLogoUrl("")
    }

    const editPackageModal = (subscriptionId) => {
        const selectedPackage = data.filter(data => data.id === subscriptionId)[0]
        setPackageId(selectedPackage.id)
        setPrice(selectedPackage.price)
        setWash(selectedPackage.wash)
        setDry(selectedPackage.dry)
        setPurchaseLimit(selectedPackage.purchase_limit)
        setMaxPurchase(selectedPackage.max_purchase)
        if (selectedPackage.start_date && selectedPackage.end_date) {
            setStartDate(new Date(selectedPackage.start_date))
            setEndDate(new Date(selectedPackage.end_date))
        } else {
            setStartDate("")
            setEndDate("")
        }
        setExpiry(selectedPackage.expiry)
        setDuration(selectedPackage.duration)
        setName(selectedPackage.name)
        setDescription(selectedPackage.description)
        setUsePerDay(selectedPackage.use_per_day)
        setDiscountedPrice(selectedPackage.discounted_price)
        setLogo("")
        if (selectedPackage.logo) {
            setLogoUrl(selectedPackage.logo)
        } else {
            setLogoUrl("")
        }

        let outletRules = selectedPackage.rules.filter(rule => rule.type === "Outlet")
        if (outletRules.length > 0) {
            setOutletId(outletRules.map(rule => rule.outletId))
            setOutletApplicable(outletRules[0].applicable)
        } else {
            setOutletId([])
            setOutletApplicable(false)
        }

        let modeRules = selectedPackage.rules.filter(rule => rule.type === "Mode")
        if (modeRules.length > 0) {
            setMode(modeRules.map(rule => rule.value))
            setModeApplicable(modeRules[0].applicable)
        } else {
            setMode([])
            setModeApplicable(false)
        }

        let weightRules = selectedPackage.rules.filter(rule => rule.type === "Weight")
        if (weightRules.length > 0) {
            setWeight(weightRules.map(rule => rule.value))
            setWeightApplicable(weightRules[0].applicable)
        } else {
            setWeight([])
            setWeightApplicable(false)
        }

        setShowModal(true)
    }

    const handleImage = (e) => {
        const file = e.target.files[0];

        const imageRegex = /image\//g;
        const jpgRegex = /.jpg/g;
        const pngRegex = /.png/g;
        const jpegRegex = /.jpeg/g;

        if (file) {
            if (imageRegex.test(file.type)) {
                if (jpgRegex.test(file.name) || pngRegex.test(file.name) || jpegRegex.test(file.name)) {
                    if (file.size > 2097152) {
                        mySwal.fire(t("Info"), t("The image size is over 2MB"), "info");
                        e.target.value = "";
                    } else {
                        setLogo(file)
                        setLogoUrl(URL.createObjectURL(file))
                    }
                } else {
                    mySwal.fire(t("Info"), t("Please only use png, jpg or jpeg file extension type"), "info");
                    e.target.value = "";
                }
            } else {
                mySwal.fire(t("Info"), t("Please only use image type file"), "info");
                e.target.value = "";
            }
        } else {
            setLogo("")
            setLogoUrl("")
        }
    }

    const createPackage = (e) => {
        e.preventDefault();

        if (parseFloat(price) <= 0) {
            mySwal.fire(t("Invalid input"), t("Price should be more than 0"), "info");
        } else if (parseInt(wash) <= 0 && parseInt(dry) <= 0) {
            mySwal.fire(t("Invalid input"), t("Number of wash or dry should be more than 0"), "info");
        } else if (parseInt(wash) < 0) {
            mySwal.fire(t("Invalid input"), t("Number of wash should be more than 0"), "info");
        } else if (parseInt(dry) < 0) {
            mySwal.fire(t("Invalid input"), t("Number of dry should be more than 0"), "info");
        } else if (purchase_limit && parseInt(max_purchase) <= 0) {
            mySwal.fire(t("Invalid input"), t("Maximum number of purchase must be more than 0"), "info");
        } else if (expiry && parseInt(duration) <= 0) {
            mySwal.fire(t("Invalid input"), t("Duration must be more than 0"), "info");
        } else if (!name) {
            mySwal.fire(t("Missing Field"), t("Please fill in the name"), "info");
        } else if (!description) {
            mySwal.fire(t("Missing Field"), t("Please fill in the description"), "info");
        } else if (parseInt(use_per_day) <= 0) {
            mySwal.fire(t("Invalid input"), t("Maximum use per day must be more than 0"), "info");
        } else if (discounted_price && parseFloat(discounted_price) < 0) {
            mySwal.fire(t("Invalid input"), t("Discounted price should be more than 0"), "info");
        } else {
            setLoading(true)

            let rule = []

            if (outletId.length > 0) {
                for (let i = 0; i < outletId.length; i++) {
                    rule.push({
                        type: "Outlet",
                        applicable: outletApplicable,
                        outletId: outletId[i]
                    })
                }
            }

            if (mode.length > 0) {
                for (let i = 0; i < mode.length; i++) {
                    rule.push({
                        type: "Mode",
                        applicable: modeApplicable,
                        value: mode[i]
                    })
                }
            }

            if (weight.length > 0) {
                for (let i = 0; i < weight.length; i++) {
                    rule.push({
                        type: "Weight",
                        applicable: weightApplicable,
                        value: weight[i]
                    })
                }
            }

            const formData = new FormData();
            formData.append("id", id);
            formData.append("role", role);
            formData.append("price", price);
            formData.append("wash", wash);
            formData.append("dry", dry);
            formData.append("purchase_limit", purchase_limit);
            formData.append("max_purchase", max_purchase);
            if (start_date) {
                formData.append("start_date", dateFormat(start_date));
            }
            if (end_date) {
                formData.append("end_date", dateFormat(end_date));
            }
            formData.append("expiry", expiry);
            formData.append("duration", duration);
            formData.append("name", name);
            formData.append("description", description);
            formData.append("use_per_day", use_per_day);
            formData.append("discounted_price", discounted_price);
            if (logo) {
                formData.append("logo", logo);
            }
            formData.append("rule", JSON.stringify(rule));

            axios
                .post("/api/admin/setting/addSubscription", formData, { headers: { "auth-token": token } })
                .then(res => {

                    axios
                        .post("/api/admin/setting/getSubscription", { role, id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {
                            setData(res.data.data)
                            setLoading(false)
                            setShowModal(false)
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const editPackage = (e) => {
        e.preventDefault();

        if (parseFloat(price) <= 0) {
            mySwal.fire(t("Invalid input"), t("Price should be more than 0"), "info");
        } else if (parseInt(wash) <= 0 && parseInt(dry) <= 0) {
            mySwal.fire(t("Invalid input"), t("Number of wash or dry should be more than 0"), "info");
        } else if (parseInt(wash) < 0) {
            mySwal.fire(t("Invalid input"), t("Number of wash should be more than 0"), "info");
        } else if (parseInt(dry) < 0) {
            mySwal.fire(t("Invalid input"), t("Number of dry should be more than 0"), "info");
        } else if (purchase_limit && parseInt(max_purchase) <= 0) {
            mySwal.fire(t("Invalid input"), t("Maximum number of purchase must be more than 0"), "info");
        } else if (expiry && parseInt(duration) <= 0) {
            mySwal.fire(t("Invalid input"), t("Duration must be more than 0"), "info");
        } else if (!name) {
            mySwal.fire(t("Missing Field"), t("Please fill in the name"), "info");
        } else if (!description) {
            mySwal.fire(t("Missing Field"), t("Please fill in the description"), "info");
        } else if (parseInt(use_per_day) <= 0) {
            mySwal.fire(t("Invalid input"), t("Maximum use per day must be more than 0"), "info");
        } else if (discounted_price && parseFloat(discounted_price) < 0) {
            mySwal.fire(t("Invalid input"), t("Discounted price should be more than 0"), "info");
        } else {
            setLoading(true)

            let rule = []

            if (outletId.length > 0) {
                for (let i = 0; i < outletId.length; i++) {
                    rule.push({
                        type: "Outlet",
                        applicable: outletApplicable,
                        outletId: outletId[i]
                    })
                }
            }

            if (mode.length > 0) {
                for (let i = 0; i < mode.length; i++) {
                    rule.push({
                        type: "Mode",
                        applicable: modeApplicable,
                        value: mode[i]
                    })
                }
            }

            if (weight.length > 0) {
                for (let i = 0; i < weight.length; i++) {
                    rule.push({
                        type: "Weight",
                        applicable: weightApplicable,
                        value: weight[i]
                    })
                }
            }

            const formData = new FormData();
            formData.append("id", packageId);
            formData.append("price", price);
            formData.append("wash", wash);
            formData.append("dry", dry);
            formData.append("purchase_limit", purchase_limit);
            formData.append("max_purchase", max_purchase);
            if (start_date) {
                formData.append("start_date", dateFormat(start_date));
            }
            if (end_date) {
                formData.append("end_date", dateFormat(end_date));
            }
            formData.append("expiry", expiry);
            formData.append("duration", duration);
            formData.append("name", name);
            formData.append("description", description);
            formData.append("use_per_day", use_per_day);
            formData.append("discounted_price", discounted_price);
            if (logo) {
                formData.append("logo", logo);
            }
            formData.append("rule", JSON.stringify(rule));

            axios
                .post("/api/admin/setting/editSubscription", formData, { headers: { "auth-token": token } })
                .then(res => {

                    axios
                        .post("/api/admin/setting/getSubscription", { role, id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {
                            setData(res.data.data)
                            setLoading(false)
                            setShowModal(false)
                        })
                        .catch(err => {
                            setLoading(false)
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                })
                .catch(err => {
                    setLoading(false)
                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                });
        }
    }

    const deletePackage = (subscriptionId) => {
        mySwal
            .fire({
                title: t("Confirmation"),
                text: t("Delete this entry?"),
                icon: "question",
                showCancelButton: true,
                cancelButtonText: t("No"),
                confirmButtonText: t("Yes")
            })
            .then(click => {
                if (click.isConfirmed && click.value) {
                    setLoading(true);

                    axios
                        .post("/api/admin/setting/deleteSubscription", { id: subscriptionId }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                        .then(res => {

                            axios
                                .post("/api/admin/setting/getSubscription", { id, role }, { headers: { "Content-Type": "application/json", "auth-token": token } })
                                .then(res => {
                                    setData(res.data.data);
                                    setLoading(false);
                                    mySwal.fire(t("Success"), t("Your Laundry Pass have deleted successfully"), "success")
                                })
                                .catch(err => {
                                    setLoading(false);
                                    mySwal.fire(t("Error"), t(err.response.data.error), "error")
                                });
                        })
                        .catch(err => {
                            setLoading(false);
                            mySwal.fire(t("Error"), t(err.response.data.error), "error")
                        });
                }
            });
    }

    let tableData = {
        columns: [
            {
                label: t("ID"),
                field: "id",
                sort: "asc"
            },
            {
                label: t("Created Date"),
                field: "createdAt",
                sort: "asc"
            },
            {
                label: t("Name"),
                field: "name",
                sort: "asc"
            },
            {
                label: t("Description"),
                field: "description",
                sort: "asc"
            },
            {
                label: `${t("Price")} (${currency(country)})`,
                field: "price",
                sort: "asc"
            },
            {
                label: t("Number of Wash"),
                field: "wash",
                sort: "asc"
            },
            {
                label: t("Number of Dry"),
                field: "dry",
                sort: "asc"
            },
            {
                label: `${t("Discounted Price")} (${currency(country)})`,
                field: "discounted_price",
                sort: "asc"
            },
            {
                label: t("Purchase Start Date"),
                field: "start_date",
                sort: "asc"
            },
            {
                label: t("Purchase End Date"),
                field: "end_date",
                sort: "asc"
            },
            {
                label: t("Expiry"),
                field: "expiry",
                sort: "asc"
            },
            {
                label: `${t("Duration")} (${t("days")})`,
                field: "duration",
                sort: "asc"
            },
            {
                label: t("Limit Number of Purchase"),
                field: "purchase_limit",
                sort: "asc"
            },
            {
                label: t("Number of Maximum Purchase"),
                field: "max_purchase",
                sort: "asc"
            },
            {
                label: t("Use Per Day"),
                field: "use_per_day",
                sort: "asc"
            },
            {
                label: t("Action"),
                field: "action",
                sort: "asc"
            },
        ],
        rows: []
    }

    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            const dataObject = {
                id: data[i].id,
                createdAt: data[i].createdAt,
                name: data[i].name,
                description: data[i].description,
                price: data[i].price,
                wash: data[i].wash,
                dry: data[i].dry,
                discounted_price: data[i].discounted_price,
                purchase_limit: data[i].purchase_limit ? "Yes" : "No",
                max_purchase: data[i].max_purchase,
                start_date: data[i].start_date ? data[i].start_date : '-',
                end_date: data[i].end_date ? data[i].end_date : '-',
                expiry: data[i].expiry ? "Yes" : "No",
                duration: data[i].duration,
                use_per_day: data[i].use_per_day,
                action: (
                    <div>
                        <i className="bi bi-pencil-square" onClick={() => editPackageModal(data[i].id)}></i>
                        <i className="bi bi-trash3-fill ms-3" onClick={() => deletePackage(data[i].id)}></i>
                    </div>
                )
            }
            tableData.rows.push(dataObject)
        }
    }

    return (
        <div>
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title">{t("Laundry Pass")}</h5>

                    <button className="btn btn-primary" type="button" onClick={() => createPackageModal()}>
                        {t("Create Laundry Pass")}
                    </button>
                </div>

                <div className="card-body">
                    {
                        loading ? <Loading /> : (
                            <div>
                                <MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
                            </div>
                        )
                    }
                </div>
            </div>

            <Modal
                title={packageId ? t("Edit Laundry Pass") : t("Create Laundry Pass")}
                open={showModal}
                footer={null}
                onCancel={() => setShowModal(!showModal)}
            >
                <form onSubmit={packageId ? editPackage : createPackage}>
                    <div className="form-group text-left">
                        <label htmlFor="name">{t("Name")}</label>
                        <input type="text" id="name" name="name" value={name} className="form-control browser-default" onChange={e => setName(e.target.value)} required />
                    </div>

                    <div className="form-group text-left">
                        <label htmlFor="description">{t("Description")}</label>
                        <input type="text" id="description" name="description" value={description} className="form-control browser-default" onChange={e => setDescription(e.target.value)} required />
                    </div>

                    <div className="form-group text-left">
                        <label htmlFor="price">{t("Price")} : <small> ({currency(country)})</small></label>
                        <input type="number" id="price" name="price" value={price} className="form-control browser-default" onChange={e => setPrice(e.target.value)} required step="0.01" min="0" />
                    </div>

                    <div className="form-group text-left">
                        <label htmlFor="discounted_price">{t("Discounted Price")} : <small> ({currency(country)})</small></label>
                        <input type="number" id="discounted_price" name="discounted_price" value={discounted_price} className="form-control browser-default" onChange={e => setDiscountedPrice(e.target.value)} required step="0.01" min="0" />
                    </div>

                    <div className="form-group text-left">
                        <label htmlFor="wash">{t("Number of Wash")}</label>
                        <input type="number" id="wash" name="wash" value={wash} className="form-control browser-default" onChange={e => setWash(e.target.value)} required step="1" min="0" />
                    </div>

                    <div className="form-group text-left">
                        <label htmlFor="dry">{t("Number of Dry")}</label>
                        <input type="number" id="dry" name="dry" value={dry} className="form-control browser-default" onChange={e => setDry(e.target.value)} required step="1" min="0" />
                    </div>

                    {
                        logoUrl ? (
                            <div style={{ maxWidth: "50%", display: "block", margin: "auto", textAlign: "center" }}>
                                <img src={logoUrl} alt="" className="img-fluid" />
                            </div>
                        ) : null
                    }

                    <div className="form-group text-left">
                        <label htmlFor="logo">{t("Logo (Optional)")} ({t("max")}: 2MB)</label><br />
                        <input type="file" id="logo" name="logo" accept="image/*" onChange={handleImage} />
                    </div>

                    <div className="form-group text-left">
                        <label htmlFor="use_per_day">{t("Use Per Day")}</label>
                        <input type="number" id="use_per_day" name="use_per_day" value={use_per_day} className="form-control browser-default" onChange={e => setUsePerDay(e.target.value)} required step="1" min="1" />
                    </div>

                    <div className="form-group text-left">
                        <label htmlFor="date">{t("Select Purchase Period")}: </label>
                        <RangePicker
                            id="date"
                            name="date"
                            placeholder={[t("Start date"), t("End date")]}
                            onChange={handleDate}
                            style={{ width: '100%' }}
                            value={start_date && end_date ? [dayjs(start_date), dayjs(end_date)] : []}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="expiry">
                            <input type="checkbox" id="expiry" name="expiry" checked={expiry} onChange={e => setExpiry(!expiry)} />
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        {t("If ticked, the Laundry Pass will have expired date")}
                                    </Tooltip>
                                }
                            >
                                <span>{t("Expiry")}</span>
                            </OverlayTrigger>
                        </label>
                    </div>

                    {
                        expiry ? (
                            <div className="form-group text-left">
                                <label htmlFor="duration">{t("Laundry Pass Lifespan")} : <small> ({t("days")})</small></label>
                                <input type="number" id="duration" name="duration" value={duration} className="form-control browser-default" min="1" step="1" onChange={e => setDuration(e.target.value)} required />
                            </div>
                        ) : null
                    }

                    <div className="form-group">
                        <label htmlFor="purchase_limit">
                            <input type="checkbox" id="purchase_limit" name="purchase_limit" checked={purchase_limit} onChange={e => setPurchaseLimit(!purchase_limit)} />
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip>
                                        {t("If ticked, the system will limit the number of purchase")}
                                    </Tooltip>
                                }
                            >
                                <span>{t("Limit Number of Purchase")}</span>
                            </OverlayTrigger>
                        </label>
                    </div>

                    {
                        purchase_limit ? (
                            <div className="form-group text-left">
                                <label htmlFor="max_purchase">{t("Number of maximum purchase")}</label>
                                <input type="number" id="max_purchase" name="max_purchase" value={max_purchase} className="form-control browser-default" min="1" step="1" onChange={e => setMaxPurchase(e.target.value)} required />
                            </div>
                        ) : null
                    }

                    <div className="form-group mt-5">
                        <div className="d-flex">
                            <Switch value={outletApplicable} onChange={() => setOutletApplicable(!outletApplicable)} />
                            <p className="ps-2">{outletApplicable ? t("Only applicable to selected outlet") : t("Applicable to all the outlets except selected outlet")}</p>
                        </div>

                        <label htmlFor="outletId">{t("Outlet")} <small>({t("If applicable to all the outlet, leave it blank")})</small></label>
                        <Select
                            id="outletId"
                            name="outletId"
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder={t("Select Outlet")}
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) => setOutletId(value)}
                            options={outletOption}
                            value={outletId}
                        />
                    </div>

                    <div className="form-group mt-5">
                        <div className="d-flex">
                            <Switch value={weightApplicable} onChange={() => setWeightApplicable(!weightApplicable)} />
                            <p className="ps-2">{weightApplicable ? t("Only applicable to selected weight") : t("Applicable to all the weight except selected weight")}</p>
                        </div>

                        <label htmlFor="weight">{t("Weight")} <small>({t("If applicable to all the weight, leave it blank")})</small></label><br />
                        <div className="d-flex flex-wrap">
                            {weight.map((w) => (
                                <Tag
                                    key={w}
                                    closable={true}
                                    style={{ userSelect: 'none' }}
                                    onClose={() => handleRemoveWeight(w)}
                                >
                                    <span>{w}</span>
                                </Tag>
                            ))}
                            {showWeightInput ? (
                                <Input
                                    ref={weightInputRef}
                                    type="text"
                                    size="small"
                                    style={{
                                        width: 64,
                                        height: 22,
                                        marginInlineEnd: 8,
                                        verticalAlign: 'top',
                                    }}
                                    className="form-control browser-default"
                                    value={weightInput}
                                    onChange={(e) => setWeightInput(e.target.value)}
                                    onBlur={handleWeightInput}
                                    onPressEnter={handleWeightInput}
                                />
                            ) : (
                                <Tag style={{ height: 22, borderStyle: 'dashed' }} icon={<i className="bi bi-plus" />} onClick={() => setShowWeightInput(true)}>
                                    {t("Add Weight")}
                                </Tag>
                            )}
                        </div>
                    </div>

                    <div className="form-group mt-5">
                        <div className="d-flex">
                            <Switch value={modeApplicable} onChange={() => setModeApplicable(!modeApplicable)} />
                            <p className="ps-2">{modeApplicable ? t("Only applicable to selected mode") : t("Applicable to all the modes except selected mode")}</p>
                        </div>

                        <label htmlFor="mode">{t("Mode")} <small>({t("If applicable to all the mode, leave it blank")})</small></label>
                        <div className="d-flex flex-wrap">
                            {mode.map((m) => (
                                <Tag
                                    key={m}
                                    closable={true}
                                    style={{ userSelect: 'none' }}
                                    onClose={() => handleRemoveMode(m)}
                                >
                                    <span>{m}</span>
                                </Tag>
                            ))}
                            {showModeInput ? (
                                <Input
                                    ref={modeInputRef}
                                    type="text"
                                    size="small"
                                    style={{
                                        width: 64,
                                        height: 22,
                                        marginInlineEnd: 8,
                                        verticalAlign: 'top',
                                    }}
                                    className="form-control browser-default"
                                    value={modeInput}
                                    onChange={(e) => setModeInput(e.target.value)}
                                    onBlur={handleModeInput}
                                    onPressEnter={handleModeInput}
                                />
                            ) : (
                                <Tag style={{ height: 22, borderStyle: 'dashed' }} icon={<i className="bi bi-plus" />} onClick={() => setShowModeInput(true)}>
                                    {t("Add Mode")}
                                </Tag>
                            )}
                        </div>
                    </div>

                    <div className="text-center pt-3">
                        {
                            loading
                                ? (
                                    <div className="text-center">
                                        <div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )
                                : <input type="submit" value={packageId ? t("Edit Laundry Pass") : t("Create Laundry Pass")} className="btn btn-primary mx-2" />
                        }
                        <button type="button" className="btn btn-secondary" onClick={e => setShowModal(!showModal)}>
                            {t("Close")}
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    )
}

export default LaundryPassCard;